<script lang="ts">
interface Props {
    existingCollis?: FormShipmentColli[]
    modelValue: boolean
    fetchColliEndpoint?: string
    createColliEndpoint?: string
    updateColliEndpoint?: string
}
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import axios, { AxiosRequestConfig } from 'axios'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { debouncedWatch } from '@vueuse/core'

import { PaginatedResponse, PaginationData } from '@/types/general'
import { Colli, FormShipmentColli } from '@/types/delivery-management'
import { useAuthStore } from '@/stores/auth-store'

import PaginationFooter from '@/components/PaginationFooter.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import ManageColliModal from '@/components/delivery-management/ManageColliModal.vue'
import ColliCard from '@/components/delivery-management/ColliCard.vue'

const props = defineProps<Props>()
const authStore = useAuthStore()
const { t } = useI18n()
const route = useRoute()

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saveCollis', value: FormShipmentColli[]): void
}>()

const paginationData = ref<PaginationData>({
    from: 1,
    to: 1,
    perPage: 6,
    lastPage: 1,
    currentPage: Number(route.query.page ?? 1),
    total: 0,
})
const loading = ref(false)
const manageColliModalOpen = ref(false)
const searchQuery = ref('')
const newCollis = ref<FormShipmentColli[]>([])
const selectedCollis = ref<FormShipmentColli[]>([])
const collis = ref<Colli[]>([])

const selectedCollisIds = computed(() => {
    if (newCollis.value.length === 0) {
        return selectedCollis.value.map((formColli) => formColli.colli.id)
    }
    const combinedCollis = [...selectedCollis.value, ...newCollis.value]
    return combinedCollis.map((formColli) => formColli.colli.id)
})

// Use provided API endpoint or fallback to default
const fetchColliEndpoint = computed(() => props.fetchColliEndpoint ?? 'dm.company.collis.index')

const createColliEndpoint = computed(() => props.createColliEndpoint ?? 'dm.company.collis.store')

const updateColliEndpoint = computed(() => props.updateColliEndpoint ?? 'dm.company.collis.update')

async function fetchCollis(page?: number) {
    loading.value = true

    const params: AxiosRequestConfig['params'] = {
        query: searchQuery.value,
        page: page ?? paginationData.value.currentPage,
        'per-page': paginationData.value.perPage,
    }
    const response = await axios.get<PaginatedResponse<Colli>>(
        window.route(fetchColliEndpoint.value, {
            company: authStore.companyId,
        }),
        { params: params },
    )

    collis.value = response.data.data

    paginationData.value = {
        from: response.data.meta.from,
        to: response.data.meta.to,
        perPage: response.data.meta.per_page,
        lastPage: response.data.meta.last_page,
        currentPage: response.data.meta.current_page,
        total: response.data.meta.total,
    }

    loading.value = false
}

function close() {
    newCollis.value = []
    emit('close')
}

function addColliToShipment(colli: Colli) {
    newCollis.value.push({ colli, amount: 1 })
}

function saveSelectedCollis() {
    selectedCollis.value = [...selectedCollis.value, ...newCollis.value]
    emit('saveCollis', selectedCollis.value)
    close()
}

debouncedWatch(
    () => searchQuery.value,
    () => {
        fetchCollis(1)
    },
    { debounce: 300 },
)

watch(
    () => props.modelValue,
    (value) => {
        if (value) {
            selectedCollis.value = props.existingCollis ?? []
            searchQuery.value = ''
            fetchCollis(1)
        }
    },
)

watch(
    () => manageColliModalOpen.value,
    (value) => {
        if (!value) {
            fetchCollis(1)
        }
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="1000" @close="close()">
        <div class="my-4 flex space-x-4">
            <MyInput
                v-model="searchQuery"
                :background="'bg-primary-50 focus:bg-white dark:bg-dark-500 dark:focus:bg-dark-700'"
                :placeholder="t('search')"
                class="h-10 w-full pt-1.5 pb-1.5"
                type="search"
            >
                <template #icon>
                    <mdi:magnify />
                </template>
            </MyInput>

            <MyButton
                plain
                class="h-10 w-3/12"
                type="button"
                scheme="primary"
                @click="manageColliModalOpen = true"
            >
                <mdi:plus-thick class="mr-1" />
                {{ t('createColli') }}
            </MyButton>
        </div>

        <div class="mt-2 space-y-3">
            <ColliCard
                v-for="colli in collis"
                :key="colli.id"
                :colli="colli"
                class="flex w-full items-center rounded-xl bg-white shadow-md dark:bg-dark-400"
                :class="{ 'opacity-50': selectedCollisIds.includes(colli.id) }"
            >
                <template #suffix>
                    <MyButton
                        v-if="!selectedCollisIds.includes(colli.id)"
                        class="ml-auto"
                        scheme="primary"
                        type="button"
                        size="small"
                        @click="addColliToShipment(colli)"
                    >
                        {{ t('add') }}
                    </MyButton>

                    <MyButton
                        v-else
                        class="ml-auto"
                        scheme="primary"
                        type="button"
                        size="small"
                        disabled
                    >
                        {{ t('added') }}
                    </MyButton>
                </template>
            </ColliCard>

            <div
                v-if="collis.length === 0"
                class="mt-2 flex w-full items-center justify-center rounded-xl p-5"
            >
                <mdi:magnify class="mr-2" />
                {{ t('noCollisFound') }}
            </div>
        </div>
        <PaginationFooter
            class="mt-3 border-t border-primary-200 dark:border-dark-400"
            hide-per-page
            :pagination-data="paginationData"
            :refetch="(parameters) => fetchCollis(parameters?.page ?? 1)"
            :row-count="collis.length ?? 0"
        />

        <div class="flex justify-end space-x-3">
            <MyButton type="button" scheme="action" @click="close()">
                {{ t('cancelSelection') }}
            </MyButton>
            <MyButton type="button" scheme="primary" @click="saveSelectedCollis()">
                {{ t('addCollisToShipment') }}
            </MyButton>
        </div>
    </MyModal>

    <ManageColliModal
        v-model="manageColliModalOpen"
        :create-colli-endpoint="createColliEndpoint"
        :update-colli-endpoint="updateColliEndpoint"
        @close="manageColliModalOpen = false"
    />
</template>
