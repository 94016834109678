import type { CompanyProduct } from '@/types/packaging'

import axios from 'axios'
import dayjs from 'dayjs'
import { defineStore } from 'pinia'

import {
    ConsignmentNote,
    ManageTransaction,
    MinimalTransaction,
    ShipmentStatus,
    Transaction,
    TransactionImage,
    TransactionState,
    TransactionStep,
    TransactionType,
} from '@/types/transaction'
import { PermissionType, type ResourceResponse } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'

interface ManageTransactionData extends Transaction {
    additionalTransactions: number
}

interface TransactionStoreState {
    transaction: ManageTransaction | Transaction | null
    currentStep: TransactionStep
    loading: boolean
    managerOpen: boolean
    isCreatingNew: boolean
}
export const useTransactionStore = defineStore('TransactionStore', {
    state: (): TransactionStoreState => ({
        managerOpen: false,
        transaction: null,
        currentStep: TransactionStep.Company,
        loading: false,
        isCreatingNew: false,
    }),
    actions: {
        close() {
            this.managerOpen = false
            this.transaction = null
            this.currentStep = TransactionStep.Company
            this.isCreatingNew = false
        },
        updateNote(imagePath: string, note: string) {
            if (this.transaction) {
                this.transaction.images.map((image) => {
                    if (image.path === imagePath) {
                        image.note = note
                    }
                    return image
                })
            }
        },
        appendConsignmentNote(consignmentNotes: ConsignmentNote[]) {
            if (this.transaction) {
                this.transaction.consignmentNotes = [
                    ...this.transaction.consignmentNotes,
                    ...consignmentNotes,
                ]
            }
        },
        removeConsignmentNote(path: string) {
            if (this.transaction) {
                this.transaction.consignmentNotes = this.transaction.consignmentNotes.filter(
                    (note) => note.path !== path,
                )
            }
        },
        updateConsignmentNote(consignmentNote: ConsignmentNote, path: string, isImage = true) {
            if (this.transaction) {
                this.transaction.consignmentNotes = this.transaction.consignmentNotes.map(
                    (note) => {
                        if (note === consignmentNote) {
                            note.name = note.file?.name
                            note.path = path
                            note.isImage = isImage
                            delete note.file
                        }

                        return note
                    },
                )
            }
        },
        appendImages(images: TransactionImage[]) {
            if (this.transaction) {
                this.transaction.images = [...this.transaction.images, ...images]
            }
        },
        removeImage(path: string) {
            if (this.transaction) {
                this.transaction.images = this.transaction.images.filter(
                    (image) => image.path !== path,
                )
            }
        },
        updateImage(image: TransactionImage, cloudflareId: string) {
            if (this.transaction) {
                this.transaction.images = this.transaction.images.map((img) => {
                    if (img === image) {
                        delete img.file
                        img.path = cloudflareId
                    }

                    return img
                })
            }
        },

        canEditTransaction(transaction: Transaction | MinimalTransaction): boolean {
            const authStore = useAuthStore()
            if (authStore.isReadonly.packaging) {
                return false
            } else if (!authStore.hasLicense(LicenseType.BackendTransactions)) {
                return false
            } else if (!authStore.hasPermission(PermissionType.ManageTransactions)) {
                return false
            }

            const isOutgoingDraft = transaction.state === TransactionState.Draft
            if (transaction.type === TransactionType.Transit && !isOutgoingDraft) return false
            if (transaction.state === TransactionState.Declined) return false
            if (transaction.state === TransactionState.IncomingDraft) {
                return transaction.receivingCompany.id === authStore.companyId
            }

            return (
                transaction.haulierCompany?.id === authStore.companyId ||
                transaction.sendingCompany.id === authStore.companyId
            )
        },
        async findTransaction(transaction: string) {
            this.loading = true

            const authStore = useAuthStore()
            let url = window.route('packaging.transactions.show', { transaction })
            if (authStore.hasCompany) {
                url = window.route('packaging.company.transactions.show', {
                    company: authStore.companyId,
                    transaction,
                })
            }

            const { data: response } = await axios.get<ResourceResponse<Transaction>>(url)

            this.loading = false
            return response.data
        },
        async saveTransaction(additionalTransactions = 0) {
            this.loading = true
            const authStore = useAuthStore()

            if (!this.transaction) {
                this.loading = false
                this.close()
                return
            }

            const data: ManageTransaction = {
                ...(this.transaction as ManageTransaction),
                transpiredAt: dayjs(this.transaction.transpiredAt).toISOString(),
                additionalTransactions,
                // We'll only send products that has actually been filled
                products: this.transaction.products.filter(
                    (product) => product.delivered > 0 || product.received > 0,
                ),
            }

            // For transit transactions we'll set the receiver to the haulier, so it'll be affected immediately by the balance
            // The endpoint will create a destination transaction where receiver is receiver again
            if (data.type === TransactionType.Transit) {
                data.destinationLocationId = data.receivingLocationId
                data.receivingLocationId = data.haulierLocationId
                data.haulierLocationId = null
            }

            if (data.location === null) {
                delete data.location
            }

            if (this.transaction.id) {
                const draftStates = [TransactionState.IncomingDraft, TransactionState.Draft]
                if (draftStates.includes(this.transaction.state)) {
                    data.state = TransactionState.Confirmed
                    data.confirmedAt = dayjs(this.transaction.confirmedAt).toISOString()
                }

                if (this.transaction.state === TransactionState.IncomingDraft) {
                    data.type = TransactionType.Backend
                }
                let actingCompanyId = this.transaction.sendingCompanyId
                // When we're saving a destination transaction we're acting as the receiving company
                if (this.transaction.receivingCompanyId === authStore.companyId) {
                    actingCompanyId = this.transaction.receivingCompanyId
                }

                await axios.put<
                    ManageTransactionData,
                    { data: ResourceResponse<CompanyProduct[]> }
                >(
                    window.route('packaging.company.transactions.update', {
                        company: actingCompanyId,
                        transaction: this.transaction.id,
                    }),
                    { ...data, id: null },
                )
            } else {
                await axios.post<
                    ManageTransactionData,
                    { data: ResourceResponse<CompanyProduct[]> }
                >(
                    window.route('packaging.company.transactions.store', {
                        company: authStore.companyId,
                    }),
                    data,
                )
            }

            this.loading = false
            this.close()
        },
        async generateTransaction(id?: string, duplicate = false) {
            this.loading = true

            if (id) {
                const transaction = await this.findTransaction(id)

                this.transaction = {
                    ...transaction,
                    id: duplicate ? null : transaction.id,
                    type: transaction.type,
                    state: transaction.state,
                    shipmentStatus: transaction.shipmentStatus,
                    sendingLocationId: transaction.sendingLocation.id,
                    sendingCompanyId: transaction.sendingCompany.id,
                    sendingCompanyName: transaction.sendingCompany.name,
                    receivingLocationId: transaction.receivingLocation.id,
                    receivingCompanyId: transaction.receivingLocation.companyId,
                    receivingUserId: transaction.receivingUser?.id ?? null,
                    shipperLocationId: transaction.shipperLocation?.id ?? null,
                    haulierCompanyId: transaction.haulierCompany?.id ?? null,
                    haulierLocationId: transaction.haulierLocation?.id ?? null,
                    destinationLocationId: transaction.destinationLocation?.id ?? null,
                    transpiredAt: dayjs(transaction.transpiredAt).format('YYYY-MM-DDTHH:mm:ss'),
                    confirmedAt: undefined,
                    products: transaction.products,
                    details: transaction.details,
                    images: transaction.images || [],
                    consignmentNotes: transaction.consignmentNotes || [],
                } as ManageTransaction

                if (
                    transaction.state === TransactionState.Draft &&
                    transaction.type === TransactionType.Transit
                ) {
                    // When it's a transit transaction the receiver on the transaction is the haulier
                    // And the destination is the actual receiver. So we'll swap those around
                    this.transaction.haulierCompanyId = transaction.receivingCompany?.id ?? null
                    this.transaction.haulierLocationId = transaction.receivingLocation?.id ?? null
                    this.transaction.receivingCompanyId = transaction.destinationCompany?.id ?? null
                    this.transaction.receivingLocationId =
                        transaction.destinationLocation?.id ?? null
                    this.transaction.destinationLocationId = null
                }

                // The company page will be disabled for incoming drafts, so we'll just to the products page immediately
                if (transaction.state === TransactionState.IncomingDraft) {
                    this.currentStep = TransactionStep.Products
                }
            } else {
                const authStore = useAuthStore()
                this.transaction = {
                    type: this.transaction?.type ?? TransactionType.Backend,
                    state: TransactionState.Confirmed,
                    shipmentStatus: ShipmentStatus.Completed,
                    sendingLocationId: null,
                    sendingCompanyId: authStore.companyId,
                    sendingCompanyName: authStore.company.name,
                    receivingLocationId: null,
                    receivingCompanyId: null,
                    receivingUserId: null,
                    shipperLocationId: null,
                    haulierCompanyId: null,
                    haulierLocationId: null,
                    destinationLocationId: null,
                    transpiredAt: undefined,
                    products: [],
                    consignmentNotes: [],
                    images: [],
                    companyDetails: { economy: null },
                    details: {
                        id: null,
                        note: null,
                        trailerNumber: null,
                        truckNumber: null,
                        palletReceiptNumber: null,
                        shippingCode: null,
                        receiverSignature: null,
                        receiverName: null,
                    },
                } as ManageTransaction
            }

            this.managerOpen = true
            this.loading = false
        },

        async create() {
            this.isCreatingNew = true
            this.generateTransaction()
        },

        async delete(id: string) {
            const authStore = useAuthStore()

            return axios.delete<ManageTransactionData>(
                window.route('packaging.company.transactions.destroy', {
                    company: authStore.companyId,
                    transaction: id,
                }),
            )
        },

        async duplicate(id: string) {
            await this.generateTransaction(id, true)
        },

        async edit(id: string) {
            await this.generateTransaction(id)
        },
    },
})
