<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useDebounceFn, useLocalStorage, useResizeObserver } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

import '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp'

import { getMarkerIcon, markerColors, toGeoPoint } from '@/utils/maps'
import { Marker } from '@/types/general'
import { LocationPoint } from '@/types/company'

import MyButton from '@/components/my-components/MyButton.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'

interface Props {
    markers: Marker[]
    connectMarkers?: boolean
    panTo?: LocationPoint
    animated?: boolean
    forceSatellite?: boolean
}

interface Layers {
    vector: {
        normal: {
            map: H.map.layer.Layer
        }
    }
    raster: {
        satellite: {
            map: H.map.layer.Layer
        }
    }
}

const props = withDefaults(defineProps<Props>(), {
    connectMarkers: false,
    animated: false,
    forceSatellite: false,
})

const emit = defineEmits<{
    (e: 'markerClicked', markerId: string): void
    (e: 'markerMouseEnter', markerId: string): void
    (e: 'markerMouseLeave', markerId: string): void
}>()

const { t } = useI18n()

// Initialize map-related objects.
let map: H.Map | null
let ui: H.ui.UI | null
const mapMarkerGroup = new H.map.Group()
let mapMarkers: Record<string, H.map.Marker> = {}
const platform = new H.service.Platform({
    apikey: import.meta.env.VITE_HERE_API_KEY,
})
const layers = platform.createDefaultLayers({ engineType: H.Map.EngineType.HARP }) as Layers
const zoom = 15
let markersInitialized = false
const mapType = useLocalStorage('hereMapType', 'normal')
const mapContainer = ref<HTMLDivElement>()

const computedPanTo = computed(() => {
    if (!props.panTo) return
    return { lat: props.panTo.latitude, lng: props.panTo.longitude }
})

/**
 * It's not possible to zoom with proper animation, so it has to be done like this
 */
function setZoom(zoomIncrease: number) {
    let currentZoom = map?.getZoom() ?? 0
    const endZoom = currentZoom + zoomIncrease
    function animationStep() {
        currentZoom += zoomIncrease * 0.1
        map?.setZoom(currentZoom)
        if (zoomIncrease > 0 && currentZoom < endZoom) {
            requestAnimationFrame(animationStep)
        } else if (zoomIncrease < 0 && currentZoom > endZoom) {
            requestAnimationFrame(animationStep)
        }
    }
    animationStep()
}

function removeInfoBubbles() {
    ui?.getBubbles().forEach((bubble) => ui?.removeBubble(bubble))
}

function onMarkerHover(marker: Marker) {
    if (marker.markerId) emit('markerMouseEnter', marker.markerId)

    if (!marker.content) return

    const location = toGeoPoint(marker.location)
    if (!ui) return
    map!.getViewPort().element.style.cursor = 'pointer'
    const bubbles = ui.getBubbles()
    if (bubbles[0]?.getContentElement()?.innerHTML === marker.content) {
        bubbles[0].setPosition(location)
        if (bubbles[0].getState() === H.ui.InfoBubble.State.CLOSED) bubbles[0].open()
        return
    }

    removeInfoBubbles()
    const bubble = new H.ui.InfoBubble(location, { content: marker.content })
    ui.addBubble(bubble)
}

function onMarkerLeave(marker: Marker) {
    if (marker.markerId) emit('markerMouseLeave', marker.markerId)

    if (!marker.content) return

    removeInfoBubbles()
    map!.getViewPort().element.style.cursor = 'auto'
}

function onMarkerTap(marker: Marker) {
    if (marker.href) {
        window.open(marker.href, '_blank')
    }

    emit('markerClicked', marker.markerId ?? '')
}

function initializeMap() {
    let baseLayer = layers.raster.satellite.map
    if (mapType.value == 'normal' && !props.forceSatellite) {
        baseLayer = layers.vector.normal.map
    }
    map = new H.Map(mapContainer.value!, baseLayer, {
        padding: { top: 50, right: 50, bottom: 50, left: 50 },
        pixelRatio: window.devicePixelRatio || 1,
        center: computedPanTo.value ?? undefined,
        zoom: computedPanTo.value ? zoom : undefined,
        engineType: H.Map.EngineType.HARP,
    })
    map?.addObject(mapMarkerGroup)
    new H.mapevents.Behavior(new H.mapevents.MapEvents(map))
    ui = H.ui.UI.createDefault(map, layers)
    ui.removeControl('zoom')
    ui.removeControl('mapsettings')

    nextTick(() => drawMarkers())
}

function drawMarkers() {
    if (props.markers.length === 0) return

    let lineString: H.geo.LineString | undefined
    if (props.markers.length > 1 && props.connectMarkers) {
        lineString = new H.geo.LineString()
    }

    const oldMapMarkers = { ...mapMarkers }
    mapMarkers = {}
    for (const mark of props.markers) {
        const location: H.geo.IPoint = toGeoPoint(mark.location)
        let marker: H.map.Marker
        if (mark.markerId && mark.markerId in oldMapMarkers) {
            marker = oldMapMarkers[mark.markerId]
            marker.setGeometry(location)
            delete oldMapMarkers[mark.markerId]
        } else {
            marker = new H.map.Marker(location)
            mapMarkerGroup.addObject(marker)

            marker.addEventListener('pointerenter', () => onMarkerHover(mark))
            marker.addEventListener('pointerleave', () => onMarkerLeave(mark))
            marker.addEventListener('tap', () => onMarkerTap(mark))
        }

        if (mark.markerId) {
            mapMarkers[mark.markerId] = marker
        }

        marker.setIcon(getMarkerIcon(mark))

        if (lineString) {
            lineString.pushPoint(location)
        }
    }

    // We'll remove all the markers that doesn't exist anymore
    mapMarkerGroup.removeObjects(Object.values(oldMapMarkers))

    if (lineString) {
        mapMarkerGroup?.addObject(
            new H.map.Polyline(lineString, {
                style: { lineWidth: 4, strokeColor: markerColors.lightRed },
                data: '',
            }),
        )
    }

    const boundingBox = mapMarkerGroup.getBoundingBox()
    if (boundingBox && !props.panTo) {
        const shouldAnimate = markersInitialized && props.animated
        if (props.markers.length === 1) {
            map?.getViewModel().setLookAtData(
                {
                    position: toGeoPoint(props.markers[0].location),
                    zoom,
                },
                shouldAnimate,
            )
        } else {
            map?.getViewModel().setLookAtData({ bounds: boundingBox }, shouldAnimate)
        }
    }
    markersInitialized = true
}

function setMapType(type: string) {
    if (type !== 'normal') {
        map?.setBaseLayer(layers.raster.satellite.map)
        return
    }
    map?.setBaseLayer(layers.vector.normal.map)
}

const debouncedResize = useDebounceFn(() => {
    if (map) map.getViewPort().resize()
}, 100)

watch(
    () => mapType.value,
    (value) => setMapType(value),
)

watch(
    () => computedPanTo.value,
    (panTo) => {
        // If we want the smooth animation we can toggle this to true, but the animation stacks and can be kinda funky
        if (panTo) map?.setCenter(panTo, false)
    },
)

watch(
    () => props.markers,
    () => drawMarkers(),
    { deep: true },
)

useResizeObserver(mapContainer, debouncedResize)

onMounted(() => initializeMap())
</script>

<template>
    <div class="relative">
        <div ref="mapContainer" class="w-full h-full" />
        <div
            class="absolute top-0 right-0 w-full flex justify-between flex-col h-full p-2 select-none pointer-events-none z-10"
        >
            <MyRadioButtonGroup
                v-model="mapType"
                class="w-fit pointer-events-auto bg-white scale-75 rounded-lg -translate-x-5 shadow-md"
            >
                <MyRadioButton :label="t('satellite')" value="sattelite" />
                <MyRadioButton :label="t('normal')" value="normal" />
            </MyRadioButtonGroup>
            <div class="flex flex-col self-end mb-8 space-y-1 pointer-events-auto">
                <MyButton
                    icon
                    size="small"
                    shadow
                    class="bg-white text-black"
                    type="button"
                    @click="setZoom(1)"
                >
                    <mdi:plus-thick />
                </MyButton>
                <MyButton
                    icon
                    size="small"
                    class="bg-white text-black"
                    shadow
                    type="button"
                    @click="setZoom(-1)"
                >
                    <mdi:minus-thick />
                </MyButton>
            </div>
        </div>
    </div>
</template>

<style>
.H_ib_top {
    margin-top: -35px;
}

.H_ib_body {
    width: 150px;
    z-index: 2;
    transform: translateX(45px);
    @apply rounded-lg;
}

.H_ib_tail {
    z-index: 3;
}
</style>
