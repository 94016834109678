<script lang="ts" setup>
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { onBeforeMount, ref, watch, computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { datetime } from '@/utils/dates'
import { Marker, ResourceResponse } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { printElement } from '@/utils/print-element'
import { markerColors } from '@/utils/maps'
import { ShipmentType, DeviationState, Ticket, DeviationType } from '@/types/delivery-management'
import { image as imageHelper } from '@/utils/assets'
import { imageViewerOpenKey } from '@/types/global-injection-keys'
import { emitter } from '@/utils/mitt'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'

import MapComponent from '@/components/MapComponent.vue'
import UpdateTagsModal from '@/components/delivery-management/UpdateTagsModal.vue'
import UpdateStateModal from '@/components/delivery-management/UpdateStateModal.vue'
import AssignUserModal from '@/components/delivery-management/AssignUserModal.vue'
import MyMenu from '@/components/my-components/MyMenu.vue'
import MyMenuItem from '@/components/my-components/MyMenuItem.vue'
import DeviationLogs from '@/components/delivery-management/DeviationLogs.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'

export interface Props {
    id?: string
    open?: boolean
}

const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const props = withDefaults(defineProps<Props>(), { open: true })

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const authStore = useAuthStore()
const confirm = useConfirm()

const imageViewerOpen = inject(imageViewerOpenKey)!

const modalContent = ref<HTMLDivElement>()
const ticket = ref<Ticket | null>(null)
const loading = ref(false)
const assignModalOpen = ref(false)
const updateStateModalOpen = ref(false)
const updateTagsModalOpen = ref(false)

const ticketStates = computed<Record<DeviationState, string>>(() => {
    return {
        [DeviationState.Open]: t('open'),
        [DeviationState.Closed]: t('closed'),
        [DeviationState.Solved]: t('solved'),
    }
})

const currentAgent = computed(() => {
    if (!ticket.value) return null
    if (authStore.companyId === ticket.value.company_id) {
        return ticket.value.companyUser
    } else {
        return ticket.value.clientUser
    }
})

const isClientUser = computed(() => {
    return (
        authStore.companyId === ticket.value?.client.id &&
        authStore.user?.id === ticket.value?.clientUser?.id
    )
})

const isCompanyUser = computed(() => {
    return (
        authStore.companyId === ticket.value?.company.id &&
        authStore.user?.id === ticket.value?.companyUser?.id
    )
})

const canFlipResponsibleCompany = computed(() => {
    if (ticket.value && !ticket.value.responsibleCompanyId) return true

    return ticket.value?.responsibleCompanyId === authStore.companyId
})

const collaboratingCompany = computed(() => {
    if (!ticket.value) return

    return ticket.value.company.id === authStore.companyId
        ? ticket.value.client
        : ticket.value.company
})

const markers = computed<Marker[]>(() => {
    const markerList: Marker[] = []

    if (!ticket.value) return markerList

    if (ticket.value.shipment) {
        const receivingLocation =
            ticket.value.shipment.addressLocation ??
            ticket.value.shipment.receivingLocation.location

        markerList.push({
            location: receivingLocation,
            content: t('receivingLocation'),
        })

        if (ticket.value.location) {
            markerList.push({
                location: ticket.value.location,
                content:
                    ticket.value.shipment?.type === ShipmentType.Delivery
                        ? t('deliveryLocation')
                        : t('pickupLocation'),
                fillColor: markerColors.green,
            })
        }
    } else if (ticket.value.type === DeviationType.Load && ticket.value.location) {
        markerList.push({ location: ticket.value.location })
    } else if (ticket.value.type === DeviationType.Unload && ticket.value.location) {
        markerList.push({ location: ticket.value.location })
    }
    return markerList
})

async function fetchTicket() {
    loading.value = true
    try {
        const response = await axios.get<ResourceResponse<Ticket>>(
            window.route('dm.company.tickets.show', {
                company: authStore.companyId,
                ticket: route.params.ticketId ?? props.id!,
            }),
        )
        ticket.value = response.data.data
    } finally {
        if (isClientUser.value || isCompanyUser.value) {
            emitter.emit('fetchAlarms')
        }
        loading.value = false
    }
}

async function toggleSubscription() {
    try {
        let url = 'dm.company.tickets.subscribe'
        let method = 'PUT'
        if (ticket.value?.isSubscribed) {
            await confirm(t('confirmUnsubscription', { entity: t('ticket') }), undefined, {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            })
            url = 'dm.company.tickets.unsubscribe'
            method = 'DELETE'
        }

        loading.value = true
        await axios(
            window.route(url, {
                company: authStore.companyId,
                ticket: ticket.value!.id,
            }),
            { method },
        )

        if (ticket.value?.isSubscribed) {
            notify({ type: 'success', text: t('unsubscribedFromEntity', { entity: t('ticket') }) })
        } else {
            notify({ type: 'success', text: t('subscribedToEntity', { entity: t('ticket') }) })
        }

        fetchTicket()
    } catch (error) {
        // We don't send an error back when confirm is cancelled
        if (error) {
            notify({ title: t('unknownError'), type: 'error' })
        }
    } finally {
        loading.value = false
    }
}

function refetchTicket() {
    emitter.emit('fetchTickets')
    if (ticket.value) fetchTicket()
}

function print() {
    printElement(modalContent.value!)
}

function onClose() {
    if (imageViewerOpen.value) return
    if (props.id) {
        emit('update:open', false)
    } else if (route.name?.toString().includes('customer')) {
        router.replace({
            name: 'dm.customers.show',
            params: { ...route.params },
            query: route.query,
        })
    } else {
        router.replace({ name: 'dm.tickets', query: route.query })
    }
}

onBeforeMount(fetchTicket)
watch(() => props.id, fetchTicket)
watch(
    () => route.params.ticketId,
    () => {
        if (route.params.ticketId) fetchTicket()
    },
)
</script>

<template>
    <MyModal :value="props.open" :max-width="1000" @close="onClose">
        <LoaderWrapper :visible="loading" />
        <div v-if="ticket" ref="modalContent">
            <div class="absolute right-0 space-x-2 px-6">
                <div v-if="authStore.companyId" class="flex space-x-2 print:hidden">
                    <MyButton icon plain scheme="light" size="small" @click="print()">
                        <mdi:printer />
                    </MyButton>

                    <MyMenu hide-arrow class="relative z-20" width="w-60">
                        <template #trigger>
                            <MyButton icon plain scheme="light" size="small">
                                <mdi:dots-vertical />
                            </MyButton>
                        </template>

                        <MyMenuItem @click="assignModalOpen = true">
                            <mdi:account-alert class="mr-2" />
                            {{ t('assignUser') }}
                        </MyMenuItem>

                        <MyMenuItem
                            v-if="
                                ticket.company.id === authStore.companyId ||
                                ticket.client.id === authStore.companyId
                            "
                            @click="updateStateModalOpen = true"
                        >
                            <mdi:sign-direction class="mr-2" />
                            {{ t('changeStatus') }}
                        </MyMenuItem>

                        <MyMenuItem @click="toggleSubscription()">
                            <mdi:bell-alert v-if="!ticket.isSubscribed" class="mr-2" />
                            <mdi:bell-cancel v-else class="mr-2" />
                            {{
                                ticket.isSubscribed
                                    ? t('unsubscribeFromEntity', { entity: t('ticket') })
                                    : t('subscribeToEntity', { entity: t('ticket') })
                            }}
                        </MyMenuItem>
                    </MyMenu>
                </div>
            </div>

            <section class="mb-6 grid grid-cols-2 gap-6 w-full">
                <div class="space-y-6">
                    <div class="flex items-center">
                        <span
                            class="text-xl font-semibold uppercase text-primary-400"
                            v-text="t('ticket')"
                        />
                        <div v-if="ticket.number">
                            <span
                                class="text-xl font-semibold uppercase mx-1.5 text-primary-400"
                                v-text="'-'"
                            />
                            <span
                                class="text-xl font-semibold uppercase text-primary-400"
                                v-text="ticket.number"
                            />
                        </div>
                    </div>

                    <div class="flex flex-col">
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('tags')"
                        />
                        <div class="group/item flex">
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="ticket.tags.map((tag) => t(tag.name)).join(', ')"
                            />

                            <span
                                class="cursor-pointer pl-3 text-gray-600 opacity-0 transition-opacity group-hover/item:opacity-100 dark:text-primary-50"
                            >
                                <mdi:pencil @click="updateTagsModalOpen = true" />
                            </span>
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('date')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="datetime(ticket.createdAt)"
                            />
                        </div>
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('status')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="ticketStates[ticket.state]"
                            />
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('hub')"
                            />

                            <span
                                class="text-md font-semibold text-primary-500 hover:text-primary-400 dark:text-primary-100"
                                v-text="ticket.tour?.hub?.name ?? '-'"
                            />
                        </div>
                        <div
                            v-if="ticket.client.id === authStore.companyId"
                            class="flex w-1/2 flex-col"
                        >
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('deviation')"
                            />

                            <RouterLink
                                v-if="ticket.deviation"
                                :to="{
                                    name: 'dm.deviations.show',
                                    params: { deviationId: ticket?.deviation.id },
                                }"
                                class="text-md uppercase font-semibold text-primary-500 hover:text-primary-400 dark:text-primary-100"
                                v-text="t('view')"
                            />

                            <span
                                v-else
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                            >
                                -
                            </span>
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('shipment')"
                            />
                            <RouterLink
                                v-if="ticket.shipment"
                                :to="{
                                    name: 'dm.shipments.show',
                                    params: { shipmentId: ticket.shipment.id },
                                }"
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                            >
                                {{ ticket.shipment?.shipmentNumber ?? t('view') }}
                                <template v-if="ticket.shipment?.name">
                                    ({{ ticket.shipment.name }})
                                </template>
                            </RouterLink>
                            <span v-else class="font-semibold">-</span>
                        </div>
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('references')"
                            />

                            <span
                                class="text-sm font-semibold text-primary-500 dark:text-primary-50"
                                v-text="ticket?.shipment?.references ?? '-'"
                            />
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div v-if="ticket.deviation?.colliNumbers" class="flex w-full flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('colli')"
                            />

                            <div
                                v-for="colli in ticket.deviation.colliNumbers"
                                :key="colli"
                                v-text="colli"
                            />
                        </div>
                    </div>

                    <div
                        v-if="ticket.shipment"
                        class="mx-auto flex w-full break-inside-avoid flex-col justify-center"
                    >
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('receiver')"
                        />
                        <div
                            class="flex flex-col h-14 mt-1 text-primary-500 rounded-t-xl bg-primary-50 px-3 py-2 dark:bg-dark-500 print:px-0"
                        >
                            <div class="flex">
                                <span
                                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                    v-text="ticket.shipment.receivingCompany.name"
                                />
                                <div
                                    v-if="
                                        ticket.shipment.receivingLocation.name !==
                                        ticket.shipment.receivingCompany.name
                                    "
                                    class="flex items-center"
                                >
                                    <span class="mx-1 dark:text-primary-100">-</span>
                                    <span
                                        class="text-sm dark:text-primary-100"
                                        v-text="ticket.shipment.receivingLocation.name"
                                    />
                                </div>
                            </div>

                            <span
                                v-if="ticket.shipment.address"
                                class="text-xs dark:text-primary-100"
                                v-text="ticket.shipment.address"
                            />
                            <span v-else class="text-xs dark:text-primary-100">
                                {{
                                    ticket.shipment.receivingLocation.address +
                                    ', ' +
                                    ticket.shipment.receivingLocation.city +
                                    ' ' +
                                    ticket.shipment.receivingLocation.zipcode +
                                    ', ' +
                                    ticket.shipment.receivingLocation.country
                                }}
                            </span>
                        </div>

                        <MapComponent
                            class="relative h-[200px] rounded-b-xl shadow-xl print:shadow-none overflow-hidden"
                            :markers="markers"
                        />
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('tour')"
                            />

                            <RouterLink
                                v-if="ticket.tour"
                                :to="{
                                    name: 'dm.tours.show',
                                    params: { tourId: ticket?.tour.id },
                                }"
                                class="text-md font-semibold text-primary-500 hover:text-primary-400 dark:text-primary-100"
                                v-text="ticket.tour.name"
                            />
                        </div>
                    </div>
                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <div class="flex space-x-2 items-center">
                                <span
                                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                    v-text="t('company')"
                                />
                                <mdi:account-clock
                                    v-if="ticket.responsibleCompanyId === ticket.company.id"
                                    v-tooltip="
                                        t('responsibleCompanyExplanation', {
                                            company: ticket.company.name,
                                        })
                                    "
                                    class="text-primary-500 dark:text-primary-300"
                                />
                            </div>

                            <div class="flex items-center space-x-2 mt-2">
                                <img
                                    v-if="ticket.company.logo"
                                    :alt="ticket.company.name"
                                    class="w-8 rounded-full object-cover"
                                    :src="imageHelper(ticket.company.logo)"
                                />

                                <span
                                    class="text-sm font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="ticket.company.name ?? '-'"
                                />
                            </div>
                        </div>
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('agent')"
                            />

                            <div class="flex items-center space-x-2 mt-2">
                                <img
                                    v-if="ticket.companyUser?.profileImage"
                                    :alt="ticket.companyUser?.name"
                                    class="w-8 rounded-full object-cover"
                                    :src="imageHelper(ticket.companyUser?.profileImage)"
                                />

                                <span
                                    class="text-sm font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="ticket.companyUser?.name ?? '-'"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <div class="flex space-x-2 items-center">
                                <span
                                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                    v-text="t('shipper')"
                                />
                                <mdi:account-clock
                                    v-if="ticket.responsibleCompanyId === ticket.client.id"
                                    v-tooltip="
                                        t('responsibleCompanyExplanation', {
                                            company: ticket.client.name,
                                        })
                                    "
                                    class="text-primary-500 dark:text-primary-300"
                                />
                            </div>

                            <div class="flex items-center space-x-2 mt-2">
                                <img
                                    v-if="ticket.client.logo"
                                    :alt="ticket.client.name"
                                    class="w-8 rounded-full object-cover"
                                    :src="imageHelper(ticket.client.logo)"
                                />

                                <span
                                    class="text-sm font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="ticket.client.name ?? '-'"
                                />
                            </div>
                        </div>
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('shipperAgent')"
                            />

                            <div class="flex items-center space-x-2 mt-2">
                                <img
                                    v-if="ticket.clientUser?.profileImage"
                                    :alt="ticket.clientUser?.name"
                                    class="w-8 rounded-full object-cover"
                                    :src="imageHelper(ticket.clientUser?.profileImage)"
                                />

                                <span
                                    class="text-sm font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="ticket.clientUser?.name ?? '-'"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <ContentHeading class="text-xl font-bold" v-text="t('logs')" />
                    <DeviationLogs
                        class="mt-4"
                        :model-id="ticket.id"
                        :logs="ticket.logs"
                        is-ticket
                        :collaborating-company="collaboratingCompany"
                        :can-flip-responsible-company="canFlipResponsibleCompany"
                        :hide-add-comment="authStore.isReadonly.deliveryManagement"
                        @log-added="fetchTicket"
                    />
                </div>
            </section>
        </div>

        <h1
            v-if="!ticket && !loading"
            class="my-auto text-center text-4xl font-bold"
            v-text="t('ticketNotFound')"
        />

        <AssignUserModal
            v-if="ticket && assignModalOpen"
            v-model="assignModalOpen"
            :ticket-id="ticket?.id"
            :current-agent-id="currentAgent?.id"
            @saved="refetchTicket"
            @close="assignModalOpen = false"
        />
        <UpdateStateModal
            v-if="ticket && updateStateModalOpen"
            v-model="updateStateModalOpen"
            :ticket="ticket"
            :collaborating-company="collaboratingCompany"
            :can-flip-responsible-company="canFlipResponsibleCompany"
            @saved="refetchTicket"
            @close="updateStateModalOpen = false"
        />

        <UpdateTagsModal
            v-if="ticket && updateTagsModalOpen"
            v-model="updateTagsModalOpen"
            :ticket="ticket"
            @saved="refetchTicket"
            @close="updateTagsModalOpen = false"
        />
    </MyModal>
</template>
