<script setup lang="ts">
import type { DropdownOption } from '@/types/inputs'

import axios from 'axios'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import {
    DashboardWidget,
    EntityRecordCountWidgetRef,
    WidgetType,
    EntityCountRecord,
} from '@/types/dashboard'
import { PaginatedResponse } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { UserType } from '@/types/user'

import EntityRecordCountWidget from '@/components/dashboard/widgets/EntityRecordCountWidget.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import CompanyLocationOption from '@/components/dashboard/widget-settings/CompanyLocationOption.vue'
import TimePeriodOption from '@/components/dashboard/widget-settings/TimePeriodOption.vue'

interface Props {
    widget: DashboardWidget<WidgetType.PackagingEmployeeTransactions>
    preview?: boolean
}

interface EmployeeRecord extends Omit<EntityCountRecord, 'subtitle'> {
    subtitle: UserType
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const dashboardStore = useDashboardStore()

const recordWidget = ref<EntityRecordCountWidgetRef>()

const selectedLocationId = computed({
    get: () => props.widget.options.selectedLocationId ?? null,
    set: (id) => {
        dashboardStore.updateWidgetOptions(props.widget, { selectedLocationId: id ?? undefined })
    },
})
const sortBy = computed({
    get: () => props.widget.options.sortBy ?? '-count',
    set: (sortBy) => dashboardStore.updateWidgetOptions(props.widget, { sortBy }),
})
const timePeriod = computed({
    get: () => props.widget.options.timePeriod ?? 7,
    set: (timePeriod) => dashboardStore.updateWidgetOptions(props.widget, { timePeriod }),
})

const sortOptions = computed<DropdownOption[]>(() => [
    { label: t('entityDescending', { entity: t('transactions') }), value: '-count' },
    { label: t('entityAscending', { entity: t('transactions') }), value: 'count' },
    { label: t('name'), value: 'name' },
])

function getUserType(type: UserType): string {
    if (type === UserType.Driver) return t('driver')
    if (type === UserType.Warehouse) return t('warehouse')

    return '-'
}

async function fetchEmployees(
    rowsPerPage: number,
    currentPage: number,
): Promise<PaginatedResponse<EntityCountRecord>> {
    const { data: response } = await axios.get<PaginatedResponse<EmployeeRecord>>(
        window.route('dashboards.employee-packaging-transactions', {
            dashboard: dashboardStore.currentDashboard!.id,
            shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
            page: currentPage,
            'per-page': rowsPerPage,
            sort: sortBy.value,
            'start-date': dayjs().subtract(timePeriod.value, 'days').toISOString(),
            'location-id': selectedLocationId.value,
        }),
    )

    return {
        data: response.data.map((employee) => {
            return {
                id: employee.id,
                title: employee.title,
                subtitle: getUserType(employee.subtitle),
                count: employee.count,
                image: employee.image,
            }
        }),
        meta: response.meta,
    }
}
watch(
    () => props.widget.options,
    () => recordWidget.value?.fetchData(),
)

useMittListener('fetchWidgetData', () => recordWidget.value?.fetchData())
</script>

<template>
    <MyWidget :widget="props.widget" :preview="props.preview">
        <EntityRecordCountWidget
            ref="recordWidget"
            :title="t('employeeTransactions')"
            :fetch-data="fetchEmployees"
            :entity-link="{ route: 'settings.employees', title: t('employees') }"
        />

        <template #settings>
            <CompanyLocationOption v-model="selectedLocationId" />

            <MySelect
                v-model="sortBy"
                :options="sortOptions"
                group-class="mt-4  mx-auto"
                :label="t('sortBy')"
            />

            <TimePeriodOption v-model="timePeriod" />
        </template>
    </MyWidget>
</template>
