<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useFilterQuery } from '@/hooks/use-filter-query'
import { DropdownOption } from '@/types/inputs'

import MyButton from '@/components/my-components/MyButton.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

export interface Props {
    label: string
    filterName: string
    default?: string
    loading?: boolean
    options: DropdownOption[]
    dropdownWidth?: number
    multiple?: boolean
    translationKey?: string
}

const emit = defineEmits<{
    (e: 'change', value: string | null, filterName: string): void
}>()
const props = defineProps<Props>()

const { filters } = useFilterQuery()
const { t } = useI18n()

const item = ref(parseItemValue() || (props.default ?? null))

const label = computed(() => {
    if (!props.multiple) {
        if (!item.value) {
            return props.label
        }
        return props.options.find((option) => option.value == item.value)?.label
    }

    if (item.value?.length === 1) {
        return props.options.find((option) => option.value == item.value)?.label
    }

    if (item.value && item.value.length > 1) {
        if (!props.translationKey) {
            return t('itemsSelected', { count: item.value.length })
        }
        return t(props.translationKey, { count: item.value.length })
    }

    return props.label
})

/**
 * Because vue-router fucks up for nested objects with array values we send the array as a comma separated string when props.multiple is true
 */
function parseItemValue(): string | string[] | null {
    const itemValue = filters.value[props.filterName] ?? null

    if (props.multiple && !Array.isArray(itemValue)) {
        return (
            itemValue
                ?.split(',')
                .map((value) => value.trim())
                .filter((value) => !!value) ?? []
        )
    }

    return itemValue
}

watch(
    item,
    () => {
        let itemValue = item.value
        if (props.multiple && Array.isArray(itemValue)) {
            if (itemValue.length !== 0) {
                itemValue = itemValue?.join(',')
            } else {
                itemValue = null
            }
        }
        emit('change', itemValue as string | null, props.filterName)
    },
    { deep: true },
)
watch(
    () => filters.value[props.filterName],
    () => {
        item.value = parseItemValue()
    },
)
</script>
<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <MySelect
        v-model="item"
        :options="props.options"
        custom-class="uppercase font-semibold text-gray-500 text-xs"
        :loading="loading"
        hide-chevron
        inline-search
        v-bind="$attrs"
        :dropdown-width="props.dropdownWidth"
        :multiple="props.multiple"
    >
        <MyButton :active="!!item?.length" plain scheme="light" size="small" v-text="label" />

        <template #option="slotProps">
            <slot name="option" v-bind="slotProps" />
        </template>
    </MySelect>
</template>
