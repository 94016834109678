<script lang="ts" setup>
import { DashboardWidget, WidgetType } from '@/types/dashboard'
import { isWidgetOfType } from '@/utils/dashboard'

import TransportObjectIncidentsWidget from '@/components/dashboard/widgets/damage-reports/TransportObjectIncidentsWidget.vue'
import PodEmployeeReceiptsWidget from '@/components/dashboard/widgets/pod/PodEmployeeReceiptsWidget.vue'
import EmployeeDriverReportWidget from '@/components/dashboard/widgets/driver-reports/EmployeeDriverReportWidget.vue'
import OngoingEmployeeReportsWidget from '@/components/dashboard/widgets/driver-reports/OngoingEmployeeReportsWidget.vue'
import EmployeeDamageReportsWidget from '@/components/dashboard/widgets/damage-reports/EmployeeDamageReportsWidget.vue'
import EmployeeIncidentsWidget from '@/components/dashboard/widgets/damage-reports/EmployeeIncidentsWidget.vue'
import StockWidget from '@/components/dashboard/widgets/packaging/StockWidget.vue'
import BalanceWidget from '@/components/dashboard/widgets/packaging/BalanceWidget.vue'
import EmployeeTransactionsWidget from '@/components/dashboard/widgets/packaging/EmployeeTransactionsWidget.vue'
import WidgetExample from '@/components/dashboard/widgets/WidgetExample.vue'
import TransportObjectServiceWidget from '@/components/dashboard/widgets/damage-reports/TransportObjectServiceWidget.vue'
import IncidentCountWidget from '@/components/dashboard/widgets/damage-reports/IncidentCountWidget.vue'
import OngoingTourMapWidget from '@/components/dashboard/widgets/delivery-management/OngoingTourMapWidget.vue'
import OngoingTourListWidget from '@/components/dashboard/widgets/delivery-management/OngoingTourListWidget.vue'
import EmployeeDriverReportsStopTimeWidget from '@/components/dashboard/widgets/driver-reports/EmployeeDriverReportsStopTimeWidget.vue'

export interface Props {
    widget: DashboardWidget<WidgetType>
    preview?: boolean
}

const props = defineProps<Props>()
</script>

<template>
    <WidgetExample
        v-if="isWidgetOfType(props.widget, WidgetType.Example)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <StockWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.PackagingStock)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <BalanceWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.PackagingBalance)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <EmployeeTransactionsWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.PackagingEmployeeTransactions)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <OngoingEmployeeReportsWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.DriverReportsOngoingEmployeeReports)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <EmployeeDamageReportsWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.DamageReportsEmployeeReports)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <TransportObjectServiceWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.DamageReportsTransportObjectService)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <IncidentCountWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.DamageReportsIncidentCount)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <EmployeeIncidentsWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.DamageReportsEmployeeIncidents)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <TransportObjectIncidentsWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.DamageReportsTransportObjectIncidents)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <OngoingTourMapWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.DeliveryManagementOngoingTourMap)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <PodEmployeeReceiptsWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.PodEmployeeReceipts)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <EmployeeDriverReportWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.DriverReportsEmployeeReports)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <OngoingTourListWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.DeliveryManagementOngoingTourList)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <EmployeeDriverReportsStopTimeWidget
        v-else-if="isWidgetOfType(props.widget, WidgetType.EmployeeDriverReportsStopTime)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <div
        v-else
        class="text-red-500 flex justify-center items-center text-center h-full text-5xl font-bold uppercase"
    >
        Unresolved widget
    </div>
</template>
