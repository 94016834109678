<script lang="ts" setup>
import axios from 'axios'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { ResourceResponse, uuid, Checklist } from '@/types/general'
import { useMittListener } from '@/hooks/use-mitt-listener'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import ChecklistCard from '@/components/ChecklistCard.vue'
import ManageChecklistModal from '@/components/ManageChecklistModal.vue'

const { t } = useI18n()
const searchQuery = ref('')
const authStore = useAuthStore()
const loading = ref(false)
const allChecklists = ref<Checklist[]>([])
const selectedChecklistId = ref<uuid>()
const manageChecklistModalOpen = ref(false)

const selectedChecklist = computed(() => {
    if (!selectedChecklistId.value) return undefined
    return allChecklists.value!.find(({ id }) => id === selectedChecklistId.value)
})

const checklists = computed<Checklist[]>(() => {
    if (!searchQuery.value) return allChecklists.value

    const query = searchQuery.value.toLowerCase()
    return allChecklists.value.filter((checklist) => {
        if (checklist.name.toLowerCase().includes(query)) return true
        return false
    })
})

async function fetchChecklists() {
    loading.value = true
    const response = await axios.get<ResourceResponse<Checklist[]>>(
        window.route('company.checklists.index', { company: authStore.companyId }),
    )
    loading.value = false

    allChecklists.value = response.data.data
}

function updateChecklist(checklist: Checklist) {
    selectedChecklistId.value = checklist.id
    manageChecklistModalOpen.value = true
}

async function manageChecklistModalClosed() {
    manageChecklistModalOpen.value = false
    selectedChecklistId.value = undefined
    fetchChecklists()
}

useMittListener('fetchChecklists', fetchChecklists)
onBeforeMount(fetchChecklists)
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'pod' }" v-text="t('pod')" />
        <Breadcrumb current v-text="t('checklists')" />

        <template #actions>
            <div class="flex items-center space-x-3">
                <MyInput
                    v-model="searchQuery"
                    :background="'bg-primary-50 focus:bg-white dark:bg-dark-500 dark:focus:bg-dark-700'"
                    :placeholder="t('search')"
                    class="!w-56 pt-1.5 pb-1.5"
                    type="search"
                >
                    <template #icon>
                        <mdi:magnify />
                    </template>
                </MyInput>
                <MyButton
                    class="w-full"
                    plain
                    scheme="primary"
                    size="small"
                    @click="manageChecklistModalOpen = true"
                >
                    <mdi:plus-thick class="mr-1" />
                    {{ t('addChecklist') }}
                </MyButton>
            </div>
        </template>
    </CrumbsAndActions>

    <LoaderWrapper :visible="loading" class="rounded-xl" />
    <div v-if="checklists.length > 0" class="flex w-full flex-wrap gap-7">
        <div v-for="checklist in checklists" :key="checklist.name">
            <ChecklistCard :checklist="checklist" class="group" @click="updateChecklist(checklist)">
            </ChecklistCard>
        </div>
    </div>

    <div
        v-if="checklists.length === 0 && !loading"
        class="flex h-full w-full items-center justify-center"
        v-text="t('noChecklistsFound')"
    />

    <ManageChecklistModal
        v-model="manageChecklistModalOpen"
        :checklist="selectedChecklist"
        @close="manageChecklistModalClosed"
    />
</template>
