<script setup lang="ts">
import { computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import Draggable from 'vuedraggable'

import { ChecklistField } from '@/types/general'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'

import AddFieldModal from '@/components/damage-reports/AddFieldModal.vue'
import ChecklistFieldCard from '@/components/ChecklistFieldCard.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyButton from '@/components/my-components/MyButton.vue'

const fields = defineModel<ChecklistField[]>({ required: true })

const confirm = useConfirm()
const { t } = useI18n()

const addFieldModalOpen = ref(false)
const searchFieldQuery = ref('')

const filteredFields = computed<ChecklistField[]>(() => {
    if (!searchFieldQuery.value) return fields.value

    const query = searchFieldQuery.value.toLowerCase()
    return fields.value.filter((field) => field.name.toLowerCase().includes(query))
})

function addField(field: ChecklistField) {
    fields.value.push({ ...field, order: fields.value.length })
    searchFieldQuery.value = ''
}

async function removeField(field: ChecklistField) {
    try {
        await confirm(t('deleteEntityTitle', { entity: t('field') }), t('deleteFieldDescription'), {
            confirmText: t('yes'),
            cancelText: t('no'),
            confirmButtonScheme: MyButtonScheme.Warning,
        })
    } catch {
        return
    }
    fields.value = fields.value.filter((f) => f.id !== field.id)
}

function sortFields() {
    fields.value.sort((a, b) => a.order - b.order)
}

function saveDefaultOrder() {
    fields.value = fields.value.map((field, index) => ({ ...field, order: index }))
}

onMounted(() => sortFields())
</script>

<template>
    <div class="mb-8 mt-4 space-y-6">
        <div class="flex space-x-4">
            <MyInput
                v-model="searchFieldQuery"
                :background="'bg-primary-50 w-full focus:bg-white dark:bg-dark-500 dark:focus:bg-dark-700'"
                :placeholder="t('search')"
                group-class="sticky top-0 z-10"
                type="search"
            >
                <template #icon>
                    <mdi:magnify />
                </template>
            </MyInput>
            <MyButton
                scheme="success"
                type="button"
                class="w-3/5"
                @click="addFieldModalOpen = true"
            >
                {{ t('addField') }}
            </MyButton>
        </div>
    </div>

    <div v-if="searchFieldQuery" class="flex flex-wrap justify-center gap-6">
        <ChecklistFieldCard
            v-for="(field, index) in filteredFields"
            :key="field.id"
            v-model="filteredFields[index]"
            :draggable="false"
            @remove="removeField(field)"
        />
    </div>

    <Draggable
        v-else
        v-model="fields"
        class="flex flex-wrap justify-center gap-6"
        item-key="id"
        ghost-class="drag-ghost-card"
        handle=".handle"
        @end="saveDefaultOrder"
    >
        <template #item="{ index }">
            <ChecklistFieldCard
                v-model="fields[index]"
                draggable
                @remove="removeField(fields[index])"
            />
        </template>
    </Draggable>

    <div
        v-if="fields.length === 0"
        class="flex h-full w-full items-center mt-6 mb-4 justify-center"
        v-text="t('noFieldsAdded')"
    />

    <AddFieldModal v-model="addFieldModalOpen" @save="addField" />
</template>
