import { DashboardWidget, WidgetType } from '@/types/dashboard'
import { LicenseType } from '@/types/company'
import { licenseObject } from '@/stores/auth-store'

export function isWidgetOfType<Type extends WidgetType>(
    widget: DashboardWidget,
    type: Type,
): widget is DashboardWidget<Type> {
    return widget.type === type
}

const entityCountSizeTemplate = {
    height: 3,
    width: 3,
}

export interface WidgetConfig {
    name: string
    description: string
    moduleLicense?: LicenseType
    type: WidgetType
    defaults: {
        height: number
        width: number
    }
    shouldShow?: (licenses: licenseObject) => boolean
}

export const LicenseTypes: Partial<Record<LicenseType, string>> = {
    [LicenseType.PackagingModule]: 'packaging',
    [LicenseType.DriverReportModule]: 'driverReports',
    [LicenseType.DamageReportModule]: 'damageReports',
    [LicenseType.PODModule]: 'podReceipts',
    [LicenseType.DeliveryManagementModule]: 'deliveryManagement',
}

export const widgetConfig: Record<WidgetType, WidgetConfig> = {
    [WidgetType.PackagingStock]: {
        name: 'packagingStockWidget',
        description: 'packagingStockWidgetDescription',
        moduleLicense: LicenseType.PackagingModule,
        type: WidgetType.PackagingStock,
        defaults: {
            height: 4,
            width: 3,
        },
        shouldShow(licenses) {
            return licenses[LicenseType.Stock] !== undefined
        },
    },
    [WidgetType.PackagingBalance]: {
        name: 'packagingBalanceWidget',
        description: 'packagingBalanceWidgetDescription',
        moduleLicense: LicenseType.PackagingModule,
        type: WidgetType.PackagingBalance,
        defaults: {
            height: 3,
            width: 7,
        },
    },
    [WidgetType.PackagingEmployeeTransactions]: {
        name: 'packagingEmployeeTransactionWidget',
        description: 'packagingEmployeeTransactionWidgetDescription',
        moduleLicense: LicenseType.PackagingModule,
        type: WidgetType.PackagingEmployeeTransactions,
        defaults: entityCountSizeTemplate,
    },
    [WidgetType.DriverReportsOngoingEmployeeReports]: {
        name: 'driverReportsEmployeesOngoingReportWidget',
        description: 'driverReportsEmployeesOngoingReportWidgetDescription',
        moduleLicense: LicenseType.DriverReportModule,
        type: WidgetType.DriverReportsOngoingEmployeeReports,
        defaults: entityCountSizeTemplate,
    },
    [WidgetType.DamageReportsTransportObjectService]: {
        name: 'damageReportsTransportObjectServiceWidget',
        description: 'damageReportsTransportObjectServiceWidgetDescription',
        moduleLicense: LicenseType.DamageReportModule,
        type: WidgetType.DamageReportsTransportObjectService,
        defaults: entityCountSizeTemplate,
    },
    [WidgetType.DamageReportsIncidentCount]: {
        name: 'damageReportsIncidentCountWidget',
        description: 'damageReportsIncidentCountWidgetDescription',
        moduleLicense: LicenseType.DamageReportModule,
        type: WidgetType.DamageReportsIncidentCount,
        defaults: {
            width: 2,
            height: 2,
        },
    },
    [WidgetType.DamageReportsEmployeeIncidents]: {
        name: 'damageReportsEmployeeIncidentsWidget',
        description: 'damageReportsEmployeeIncidentsWidgetDescription',
        moduleLicense: LicenseType.DamageReportModule,
        type: WidgetType.DamageReportsEmployeeIncidents,
        defaults: entityCountSizeTemplate,
    },
    [WidgetType.DamageReportsTransportObjectIncidents]: {
        name: 'damageReportsTransportObjectIncidentsWidget',
        description: 'damageReportsTransportObjectIncidentsWidgetDescription',
        moduleLicense: LicenseType.DamageReportModule,
        type: WidgetType.DamageReportsTransportObjectIncidents,
        defaults: entityCountSizeTemplate,
    },
    [WidgetType.Example]: {
        name: 'ExampleWidget',
        description: 'Shows packaging balance details',
        type: WidgetType.Example,
        defaults: {
            height: 2,
            width: 2,
        },
    },
    [WidgetType.DamageReportsEmployeeReports]: {
        name: 'damageReportsEmployeeReportWidget',
        description: 'damageReportsEmployeeReportWidgetDescription',
        moduleLicense: LicenseType.DamageReportModule,
        type: WidgetType.DamageReportsEmployeeReports,
        defaults: entityCountSizeTemplate,
    },
    [WidgetType.PodEmployeeReceipts]: {
        name: 'podEmployeeReceiptsWidget',
        description: 'podEmployeeReceiptsWidgetDescription',
        moduleLicense: LicenseType.PODModule,
        type: WidgetType.PodEmployeeReceipts,
        defaults: entityCountSizeTemplate,
    },
    [WidgetType.DeliveryManagementOngoingTourMap]: {
        name: 'deliveryManagementOngoingTourMap',
        description: 'deliveryManagementOngoingTourMapWidgetDescription',
        moduleLicense: LicenseType.DeliveryManagementModule,
        type: WidgetType.DeliveryManagementOngoingTourMap,
        shouldShow(licenses) {
            return licenses[LicenseType.LocationTracking] !== undefined
        },
        defaults: { width: 4, height: 3 },
    },
    [WidgetType.DriverReportsEmployeeReports]: {
        name: 'driverReportsEmployeeReportsWidget',
        description: 'driverReportsEmployeeReportsWidgetDescription',
        moduleLicense: LicenseType.DriverReportModule,
        type: WidgetType.DriverReportsEmployeeReports,
        defaults: entityCountSizeTemplate,
    },
    [WidgetType.DeliveryManagementOngoingTourList]: {
        name: 'deliveryManagementOngoingTourList',
        description: 'deliveryManagementOngoingTourListWidgetDescription',
        moduleLicense: LicenseType.DeliveryManagementModule,
        type: WidgetType.DeliveryManagementOngoingTourList,
        defaults: { width: 3, height: 4 },
    },
    [WidgetType.EmployeeDriverReportsStopTime]: {
        name: 'employeeDriverReportsStopTimeWidget',
        description: 'employeeDriverReportsStopTimeWidgetDescription',
        moduleLicense: LicenseType.DriverReportModule,
        type: WidgetType.EmployeeDriverReportsStopTime,
        defaults: { width: 7, height: 3 },
    },
}
