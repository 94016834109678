<script setup lang="ts">
import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'
import { onBeforeMount, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'

import MyEmailsInput from '@/components/my-components/form/MyEmailsInput.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'

export interface Props {
    // Entity to share
    entity: string
    modelValue: boolean
    endpoint: string
    customerCompanyId?: string
}

interface Form {
    emails: string[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close', value: void): void
    (e: 'update:modelValue', value: boolean): void
}>()
const authStore = useAuthStore()
const { t } = useI18n()
const { data, submit, errors, loading } = useForm<Form>({ emails: [] })

async function share() {
    const response = await submit('post', props.endpoint)

    if (response) {
        emit('close')
        emit('update:modelValue', false)
        notify({ type: 'success', text: t('entityShared', { entity: props.entity }) })
    } else {
        for (const [field, messageData] of Object.entries(errors.value)) {
            const messages = messageData.map((message) =>
                message.replace(/{emails.\d+}/g, '{email}'),
            )
            if (field.includes('emails')) {
                errors.value['emails'] = [...(errors.value['emails'] || []), ...messages]
            }
        }
    }
}

async function fetchEmail() {
    if (!props.customerCompanyId) return

    loading.value = true

    data.emails = []

    const response = await axios.get<string[]>(
        window.route('company.customers.email', {
            company: authStore.companyId,
            receivingCompanyId: props.customerCompanyId,
        }),
    )

    if (response.data) {
        data.emails = response.data
    }

    loading.value = false
}

watch(
    () => props.modelValue,
    async (open) => {
        if (open) await fetchEmail()
    },
)
onBeforeMount(async () => {
    if (props.modelValue) await fetchEmail()
})
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>
            {{ t('shareEntity', { entity: props.entity }) }}
        </template>

        <LoaderWrapper :visible="loading" class="rounded-xl" />

        <MyForm :errors="errors" @submit.prevent="share">
            <MyEmailsInput v-model="data.emails" :label="t('receiverEmail')" required />
            <div class="mt-4 flex justify-between items-center">
                <slot name="submitPrefix" />
                <MyButton
                    :disabled="loading"
                    scheme="primary"
                    class="ml-auto"
                    v-text="t('share')"
                />
            </div>
        </MyForm>
    </MyModal>
</template>
