import { ColumnPreferences } from '@/hooks/table/use-column-preferences'
import { ReportPage, VehicleReportPreference } from '@/types/damage-report'
import { Model, uuid } from '@/types/general'
import { LicenseType } from '@/types/company'

export interface User extends Model {
    id: uuid
    profileImage?: string
    name: string
    email: string
    username: string
    phoneNumber: string
    twoFactorEnabled?: boolean
    locale: string
}

export interface MinimalUser {
    id: uuid
    profileImage?: string
    name: string
}

export interface WindowUser extends User {
    table_preferences: { options: Record<string, ColumnPreferences> | null }
    reset_password: boolean
}

export interface Employee extends User {
    webportalEnabled: boolean
    appEnabled: boolean
    userType: UserType
    appRoleId: uuid | null
    appRoleName: string | null
    webRoleId: number | null
    webRoleName: string | null
    locations: uuid[]
    settlementTemplateId: uuid | null
    employeeNumber: string | null
    salaryNumber: string | null
}

export interface ReportEmployee extends Model {
    id: uuid
    name: string
}

export enum UserType {
    Driver,
    Warehouse,
    ShippingAgent,
    Office,
    Yard,
    TugMaster,
    TugMasterSlim,
    TntKiosk,
}

export enum AuthType {
    Username,
    PhoneNumber,
}

export enum PortalType {
    Webportal = 'webportal',
    App = 'app',
}

export interface EmployeeRole extends Model {
    id: uuid
    name: string
    companyId: uuid
    dmrInstantUnitPickup: boolean
    dmrPages: ReportPage[]
    dmrOnlyPickup: boolean
    dmrVehicleReport: VehicleReportPreference
    checklists: { checklistId: uuid; licenseId: LicenseType; required: boolean }[]
}
