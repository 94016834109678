<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import { DashboardWidget, WidgetType, OngoingTour } from '@/types/dashboard'
import { uuid } from '@/types/general'
import { useOngoingTourStore } from '@/stores/ongoing-tour-store'
import { TransportObjectType } from '@/types/transport-object'
import { differenceFromMinutes, localeTime } from '@/utils/dates'
import { useOngoingTourWidgetData } from '@/hooks/delivery-management/use-ongoing-tour-widget-data'

import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import TourModal from '@/components/delivery-management/tour-modal/TourModal.vue'

interface PreparedOngoingTour extends OngoingTour {
    units: string
    vehicles: string
    delay: string
    startTime: string
    estimatedEndTime: string
    nextEventEstimatedAt: string | null
}

interface Props {
    widget: DashboardWidget<WidgetType.DeliveryManagementOngoingTourList>
    preview?: boolean
}

const props = defineProps<Props>()

const { t } = useI18n()
const ongoingTourStore = useOngoingTourStore()

const ongoingTourData = useOngoingTourWidgetData(props.widget)
const selectedTourId = ref<uuid>()

const tours = computed<PreparedOngoingTour[]>(() => {
    return ongoingTourData.tours.value.map((tour) => {
        const lastEvent = tour.events[tour.events.length - 1]
        const nextEvent = tour.events.find((event) => event.handledAt === null)
        const delay = lastEvent?.delay ?? 0
        let nextEventEstimatedAt: string | null = null
        if (nextEvent?.liveEstimatedAt ?? nextEvent?.estimatedAt) {
            nextEventEstimatedAt = dayjs(nextEvent.liveEstimatedAt ?? nextEvent.estimatedAt)
                .diff(dayjs(), 'minutes')
                .toString()
        }

        return {
            ...tour,
            startTime: localeTime(tour.startedAt ?? undefined),
            estimatedEndTime: localeTime(
                lastEvent?.liveEstimatedAt ?? lastEvent?.estimatedAt ?? undefined,
            ),
            vehicles: tour.transportObjects
                .filter((t) => t.type === TransportObjectType.Vehicle)
                .map((t) => t.registrationNumber)
                .join(', '),
            units: tour.transportObjects
                .filter((t) => t.type === TransportObjectType.Unit)
                .map((t) => t.registrationNumber)
                .join(', '),
            delay: differenceFromMinutes(delay),
            nextEventEstimatedAt: nextEventEstimatedAt,
        }
    })
})

function getTourEventTooltip(event: PreparedOngoingTour['events'][0]): string {
    let tooltip = event.shipment.shipmentNumber ?? ''
    if (event.shipment.name) {
        tooltip += `(${event.shipment.name})`
    }

    return tooltip
}

function showTour(tour: PreparedOngoingTour) {
    selectedTourId.value = tour.id
}

watch(() => props.widget.options, ongoingTourData.fetchOngoingTours)
</script>

<template>
    <MyWidget
        :widget="props.widget"
        :preview="props.preview"
        @settings-opened="ongoingTourData.hubs.fetch()"
    >
        <div class="flex flex-col">
            <div
                v-for="(tour, index) in tours"
                :key="tour.id"
                class="p-5 transition-colors group"
                :class="{ 'bg-primary-500': ongoingTourStore.highlightedTourId === tour.id }"
                @mouseenter="ongoingTourStore.setHighlightedTour(tour)"
                @mouseleave="ongoingTourStore.resetHighlightedTour()"
            >
                <div class="flex items-center">
                    <div
                        class="aspect-square flex-shrink-0 h-8 flex justify-center items-center rounded-full border-4 border-green-500 font-semibold z-10"
                        v-text="index + 1"
                    />

                    <div class="relative w-full flex ml-[-1px] bg-gray-400 h-2">
                        <span
                            v-tooltip="t('startTime')"
                            class="absolute left-0 bottom-2 text-sm text-gray-400"
                            v-text="tour.startTime"
                        />
                        <span
                            v-tooltip="t('estimatedEndTime')"
                            class="absolute right-0 bottom-2 text-sm text-gray-400 bg-transparent"
                            v-text="tour.estimatedEndTime"
                        />

                        <div
                            v-for="event in tour.events"
                            :key="event.id"
                            v-tooltip="getTourEventTooltip(event)"
                            class="w-full h-full border-r border-gray-500 last-of-type:border-none"
                            :class="{
                                'bg-green-500': event.handledAt !== null,
                                'bg-yellow-500': event.handledAt !== null && (event.delay ?? 0) > 5,
                            }"
                        />
                    </div>

                    <div class="flex-shrink-0 ml-2">
                        {{ tour.events.filter((e) => e.handledAt !== null).length }}
                        /
                        {{ tour.events.length }}
                    </div>
                </div>

                <div class="flex justify-between items-center mt-1">
                    <h3 class="flex items-center font-semibold w-full">
                        {{ tour.name }}
                        <mdi:eye
                            v-tooltip="t('showTour')"
                            class="invisible group-hover:visible ml-2 cursor-pointer hover:text-primary-400 dark:hover:text-primary-300 min-h-5 min-w-5 text-base"
                            @click="showTour(tour)"
                        />
                    </h3>

                    <div
                        v-if="tour.nextEventEstimatedAt"
                        v-tooltip="
                            t('nextEventEstimatedAt', { minutes: tour.nextEventEstimatedAt })
                        "
                        class="flex items-center space-x-1"
                    >
                        <mdi:timer-marker-outline class="ml-1" />
                        <span
                            class="flex-shrink-0 text-sm"
                            v-text="tour.nextEventEstimatedAt + t('minutesShort')"
                        />
                    </div>
                </div>

                <div class="flex justify-between items-center">
                    <div class="flex items-center space-x-1">
                        <mdi:account />
                        <span class="flex-shrink-0 text-sm" v-text="tour.userName" />
                    </div>

                    <div v-tooltip="t('delay')" class="flex items-center space-x-1">
                        <mdi:car-clock />
                        <span
                            class="flex-shrink-0 text-sm font-semibold"
                            :class="{
                                'text-yellow-500': tour.delay != '00:00',
                            }"
                            v-text="tour.delay"
                        />
                    </div>
                </div>

                <div class="flex items-center w-full">
                    <mdi:truck v-if="tour.vehicles" class="mr-1" />
                    <span v-text="tour.vehicles" />
                    <mdi:truck-trailer v-if="tour.units" class="mr-1 ml-2" />
                    <span v-text="tour.units" />
                </div>
            </div>
        </div>

        <div
            v-if="tours.length === 0 && !ongoingTourData.loading.value"
            class="absolute right-0 top-0 flex justify-center items-center text-center w-full h-full text-white bg-black/80 text-2xl uppercase"
            v-text="t('noOngoingTours')"
        />

        <template #settings>
            <MySelect
                v-model="ongoingTourData.hubId.value"
                :options="ongoingTourData.hubs.items.value"
                :loading="ongoingTourData.hubs.fetching.value"
                group-class="mt-4 mx-auto"
                clear-button
                :label="t('hub')"
                @focus="ongoingTourData.hubs.fetch()"
            />
        </template>

        <TourModal
            v-if="selectedTourId"
            :id="selectedTourId"
            @update:open="selectedTourId = undefined"
        />
    </MyWidget>
</template>
