<script lang="ts">
export interface Props {
    field: LinkedChecklistField
    value: string | number | null
    notPossibleToCheck: boolean | null
}
</script>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { ChecklistFieldType, LinkedChecklistField } from '@/types/general'

const tankLevels: Record<string, string> = { '.25': '1/4', '.50': '2/4', '.75': '3/4' }

const { t } = useI18n()

const props = defineProps<Props>()
</script>

<template>
    <td v-truncate-tooltip="props.value" class="border-r truncate border-primary-200 p-2.5 text-sm">
        <div
            v-if="props.notPossibleToCheck"
            :class="{
                'flex justify-center': props.notPossibleToCheck,
            }"
        >
            <mdi:close-octagon-outline
                v-tooltip="t('notPossibleToCheck')"
                class="text-yellow-500"
            />
        </div>

        <div v-else-if="field.type === ChecklistFieldType.Boolean">
            <mdi:check-bold v-if="props.value == '1'" class="text-green-500" />
            <mdi:close-thick v-if="props.value == '0'" class="text-red-500" />
        </div>

        <span
            v-else-if="field.type === ChecklistFieldType.TankLevel"
            v-text="tankLevels[props.value ?? ''] ?? props.value"
        />

        <span v-else v-text="props.value" />
    </td>
</template>
