<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import dayjs from 'dayjs'

import {
    DashboardWidget,
    EntityCountRecord,
    EntityRecordCountWidgetRef,
    WidgetType,
} from '@/types/dashboard'
import { useDashboardStore } from '@/stores/dashboard-store'
import { PaginatedResponse, uuid } from '@/types/general'
import { MinimalUser } from '@/types/user'
import { useAuthStore } from '@/stores/auth-store'
import { Company } from '@/types/company'
import { datetimeShort } from '@/utils/dates'

import EntityRecordCountWidget from '@/components/dashboard/widgets/EntityRecordCountWidget.vue'
import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'

interface Props {
    widget: DashboardWidget<WidgetType.DriverReportsOngoingEmployeeReports>
}

interface DriverReport {
    reportId: uuid
    company: Company
    haulier?: Company
    user: MinimalUser
    kilometerCount: number
    startedAt: string
}

interface ExtendedEntityCountRecord extends EntityCountRecord {
    startedAt: string
}

const props = defineProps<Props>()

const dashboardStore = useDashboardStore()
const { t } = useI18n()
const authStore = useAuthStore()

const recordWidget = ref<EntityRecordCountWidgetRef>()

async function fetchEmployeeReports(
    rowsPerPage: number,
    currentPage: number,
): Promise<PaginatedResponse<ExtendedEntityCountRecord>> {
    const { data: response } = await axios.get<PaginatedResponse<DriverReport>>(
        window.route('dashboards.employee-ongoing-driver-reports', {
            dashboard: dashboardStore.currentDashboard!.id,
            shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
            page: currentPage,
            'per-page': rowsPerPage,
        }),
    )
    return {
        data: response.data.map((report) => {
            return {
                id: report.reportId,
                title: report.user.name,
                count: report.kilometerCount,
                subtitle: reportCompany(report),
                image: report.user.profileImage,
                startedAt: report.startedAt,
            }
        }),
        meta: response.meta,
    }
}

function reportCompany(report: DriverReport): string | undefined {
    if (!report.haulier) return

    if (dashboardStore.currentDashboard!.companyId === report.company.id) {
        return report.haulier.name
    } else {
        return report.company.name
    }
}
</script>

<template>
    <MyWidget :widget="props.widget">
        <EntityRecordCountWidget
            ref="recordWidget"
            :fetch-data="fetchEmployeeReports"
            :title="t('ongoingDriverReports')"
            :entity-link="{ route: 'settings.employees', title: t('employees') }"
        >
            <template #count="{ entity }">
                <div class="ml-auto flex flex-col text-right">
                    <span
                        class="text-right text-primary-400 dark:text-primary-300 font-semibold ml-2 text-sm"
                        v-text="entity.count + ' ' + t('km').toLowerCase()"
                    />
                    <span
                        class="text-primary-400 dark:text-primary-300 font-semibold text-sm whitespace-nowrap pl-2"
                    >
                        {{ dayjs().from(entity.startedAt, true) }}
                        |
                        {{ datetimeShort(entity.startedAt) }}
                    </span>
                </div>
            </template>
        </EntityRecordCountWidget>
    </MyWidget>
</template>
