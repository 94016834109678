<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import dayjs from 'dayjs'

import { DashboardWidget, WidgetType } from '@/types/dashboard'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { useAuthStore } from '@/stores/auth-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { IncidentType } from '@/types/transport-object'
import { incidentTypes, incidentTypeColors } from '@/utils/damage-report'
import { ResourceResponse, uuid } from '@/types/general'

import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import TimePeriodOption from '@/components/dashboard/widget-settings/TimePeriodOption.vue'

interface Props {
    widget: DashboardWidget<WidgetType.DamageReportsIncidentCount>
    preview?: boolean
}

interface Incident {
    id: uuid
    name: string
    count: number
    type: IncidentType
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const dashboardStore = useDashboardStore()

const incidents = ref<Incident[]>([])
const loading = ref(false)

const timePeriod = computed({
    get: () => props.widget.options.timePeriod ?? 7,
    set: (timePeriod) => dashboardStore.updateWidgetOptions(props.widget, { timePeriod }),
})

async function fetchIncidentCount() {
    loading.value = true
    const response = await axios.get<ResourceResponse<Incident[]>>(
        window.route('dashboards.dmr-incident-counts', {
            dashboard: dashboardStore.currentDashboard!.id,
            shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
            'start-date': dayjs().subtract(timePeriod.value, 'days').toISOString(),
        }),
    )

    incidents.value = response.data.data
    loading.value = false
}

watch(
    () => props.widget.options,
    () => fetchIncidentCount(),
)

useMittListener('fetchWidgetData', () => fetchIncidentCount())

onMounted(() => fetchIncidentCount())
</script>

<template>
    <MyWidget :widget="props.widget" :preview="props.preview">
        <LoaderWrapper v-if="loading" :visible="loading" />
        <div class="flex flex-col p-6 h-full">
            <h3
                class="relative font-bold uppercase tracking-wide text-primary-400 dark:text-primary-300 mb-4"
                v-text="t('incidents')"
            />

            <div
                v-for="incident in incidents"
                :key="incident.id"
                class="flex justify-between h-full items-center"
            >
                <div class="flex items-center">
                    <span
                        class="rounded-full size-5 dark:ring-dark-400 dark:ring shadow dark:shadow-none"
                        :class="incidentTypeColors[incident.type]"
                    />
                    <p
                        class="font-semibold text-primary-400 uppercase text-sm ml-3"
                        v-text="t(incidentTypes[incident.type])"
                    />
                </div>
                <span
                    class="text-2xl font-bold text-primary-400 dark:text-primary-300"
                    v-text="incident.count"
                />
            </div>
        </div>
        <template #settings>
            <TimePeriodOption v-model="timePeriod" />
        </template>
    </MyWidget>
</template>
