<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { DropdownStringOption } from '@/types/inputs'
import { useCompanyStore } from '@/stores/company-store'

import MySelect from '@/components/my-components/form/MySelect.vue'

const locationId = defineModel<string | null>({ required: true })

const companyStore = useCompanyStore()
const { t } = useI18n()

const companyLocations = computed<DropdownStringOption[]>(() => {
    return companyStore.locations.map((location) => ({ value: location.id, label: location.name }))
})
</script>

<template>
    <MySelect
        v-model="locationId"
        :options="companyLocations"
        group-class="mt-4  mx-auto"
        :label="t('location')"
        clear-button
        :placeholder="t('all')"
        :clear-option="t('all')"
    />
</template>
