<script lang="ts" setup>
import type { CompanyProduct } from '@/types/packaging'

import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { useProductStore } from '@/stores/product-store'
import { image } from '@/utils/assets'
import { LicenseType } from '@/types/company'
import { PermissionType } from '@/types/general'
import { DefaultProductSort } from '@/types/packaging'

import MyButton from '@/components/my-components/MyButton.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'

export interface Props {
    product: CompanyProduct
    dragging?: boolean
    selected?: boolean
    favored?: boolean
    showCompanyForm?: boolean
    currency?: string
}

const { t } = useI18n()
const props = defineProps<Props>()
const authStore = useAuthStore()
const productStore = useProductStore()

const productPrice = ref<number>(props.product.price || 0)
const externalProductId = ref(props.product.externalProductId ?? '')

const canManageCompany = computed(() => authStore.hasPermission(PermissionType.ManageCompany))

const customSortEnabled = computed(() => {
    const defaultProductSort =
        authStore.licenses[LicenseType.PackagingModule]?.defaultProductSort ??
        DefaultProductSort.Custom

    return defaultProductSort === DefaultProductSort.Custom
})

function addProduct() {
    if (props.selected) return

    productStore.manageProduct(props.product.id)
}

function toggleFavorProduct() {
    productStore.manageProduct(props.product.id, { default: !props.favored })
}

function removeProduct() {
    productStore.removeProductFromCompany(props.product.id)
}

function savePrice() {
    productStore.manageProduct(props.product.id, {
        price: productPrice.value,
        order: props.product.order,
        externalProductId: externalProductId.value.length !== 0 ? externalProductId.value : null,
    })
}
</script>

<template>
    <div
        :class="{ 'cursor-pointer': !props.selected }"
        class="group relative flex justify-between overflow-hidden min-h-[300px] flex-col rounded-xl pb-3 hover:shadow-xl bg-primary-50 transition dark:bg-dark-500"
        @click="addProduct"
    >
        <div
            class="flex h-[112px] w-full items-center justify-center relative overflow-hidden bg-primary-300"
        >
            <img
                :alt="props.product.name"
                loading="lazy"
                class="max-h-[112px] w-auto max-w-none"
                :src="image(props.product.image, 'thumbnail')"
            />

            <span
                v-if="props.favored && canManageCompany && customSortEnabled"
                :class="props.dragging ? 'cursor-grabbing' : 'cursor-grab'"
                class="handle absolute top-3 left-3 z-10 block text-xl text-white transition"
            >
                <mdi:drag />
            </span>

            <span
                v-if="props.favored"
                class="absolute top-3 right-3 z-10 flex h-6 w-6 items-center justify-center rounded-full bg-primary-500 text-xs font-semibold text-primary-50 shadow-lg shadow-primary-400"
                v-text="props.product.order"
            />
            <div
                v-if="props.selected && !dragging"
                class="absolute right-3 bottom-3 z-20 flex flex-col items-end justify-center transition"
            >
                <MyButton
                    class="flex h-6 w-6 items-center justify-center rounded-full bg-transparent text-xs text-primary-50 shadow-primary-600 drop-shadow-lg"
                    icon
                    :disabled="!canManageCompany"
                    size="micro"
                    scheme="primary"
                    @click="toggleFavorProduct"
                >
                    <mdi:heart v-if="props.favored" />
                    <mdi:heart-outline v-else />
                </MyButton>
                <MyButton
                    class="flex h-6 w-6 items-center justify-center rounded-full bg-transparent text-xs text-red-700 shadow-primary-600 drop-shadow-lg hover:bg-primary-200 dark:hover:bg-dark-800"
                    icon
                    :disabled="!canManageCompany"
                    size="micro"
                    scheme="primary"
                    @click="removeProduct"
                >
                    <mdi:trash-can />
                </MyButton>
            </div>

            <div
                v-if="!props.selected"
                class="absolute top-0 bottom-0 left-0 right-0 flex scale-100 items-center justify-center bg-dark-600/30 text-primary-50 opacity-0 transition hover:scale-125"
            >
                <mdi:plus-thick />
            </div>
        </div>

        <div class="mt-3 flex min-h-[100px] grow flex-col justify-between space-y-1.5 px-3">
            <h4
                class="flex justify-between items-center text-sm text-primary-500 dark:text-primary-300"
            >
                <span class="font-semibold uppercase" v-text="props.product.name" />
                <span
                    v-if="props.product.dimensions"
                    class="flex-shrink-0 text-xs ml-1"
                    v-text="props.product.dimensions"
                />
            </h4>
            <div>
                <span
                    class="text-xs font-semibold uppercase text-primary-400 dark:text-gray-400"
                    v-text="t('description')"
                />
                <p
                    :class="{ 'opacity-50': props.product.description?.trim() === '' }"
                    class="text-gray-700 dark:text-gray-400"
                    v-text="
                        props.product.description?.trim() !== '' ? props.product.description : '-'
                    "
                />
            </div>

            <div v-if="props.showCompanyForm" class="w-full">
                <hr class="my-4" />
                <form class="space-y-4" @submit.prevent="savePrice">
                    <MyInput
                        v-model.number="productPrice"
                        :disabled="!canManageCompany"
                        type="number"
                        shadow="shadow-none"
                        class="w-full"
                        :class="{ 'opacity-30': !canManageCompany }"
                        :label="t('price')"
                    >
                        <template v-if="props.currency" #button>
                            <span
                                class="absolute top-0 bottom-0.5 right-2 m-auto h-5"
                                v-text="currency"
                            />
                        </template>
                    </MyInput>

                    <MyInput
                        v-model="externalProductId"
                        :disabled="!canManageCompany"
                        shadow="shadow-none"
                        class="w-full"
                        :class="{ 'opacity-30': !canManageCompany }"
                        :label="t('externalProductId')"
                    >
                        <template #label>
                            <div class="inline-flex items-center space-x-1">
                                <MyInputLabel>{{ t('externalProductId') }}</MyInputLabel>
                                <mdi:information v-tooltip="t('externalProductIdExplanation')" />
                            </div>
                        </template>
                    </MyInput>

                    <div class="flex justify-end">
                        <MyButton
                            :disabled="!canManageCompany"
                            scheme="primary"
                            plain
                            type="submit"
                            :class="{ 'opacity-30': !canManageCompany }"
                            v-text="t('save')"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
