import { Composer } from 'vue-i18n'

import { DropdownOption } from '@/types/inputs'
import { VehicleType } from '@/types/vehicle'

export function vehicleTypeOptions(t: Composer['t']): DropdownOption[] {
    return [
        { value: VehicleType.Truck, label: t('truck') },
        { value: VehicleType.Car, label: t('car') },
    ]
}
