<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import { useDropZone } from '@vueuse/core'
import axios from 'axios'
import download from 'downloadjs'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { uuid } from '@/types/general'
import { vaporUpload } from '@/utils/upload'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'

export interface Props {
    endpoint: string
    template?: string
    companyId?: uuid
}

enum LoadingState {
    NotLoading,
    UploadingFile,
    Importing,
}

interface ImportResult {
    importedRows: number
    totalRows: number
    resultFile: string
    resultFileName: string
}

const allowedExtensions = ['.xlsx', '.csv']
const extensionString = new Intl.ListFormat().format(allowedExtensions)

const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'import-finished'): void }>()

const { t } = useI18n()
const authStore = useAuthStore()

const loadingState = ref(LoadingState.NotLoading)
const fileRef = ref<HTMLInputElement>()
const dropZoneRef = ref<HTMLDivElement>()
const importResult = ref<ImportResult | null>(null)
const { isOverDropZone } = useDropZone(dropZoneRef, onFileDropped)

async function downloadTemplate() {
    window.location.href = window.route('company.import-template', {
        company: props.companyId || authStore.companyId,
        template: props.template,
    })
}

function onFileDropped(files: File[] | null) {
    importResult.value = null

    if (!files || files.length === 0) return

    uploadFile(files[0])
}

function fileInputChanged() {
    importResult.value = null

    if (!fileRef.value!.files || fileRef.value!.files.length === 0) return

    uploadFile(fileRef.value!.files[0])
}

async function uploadFile(file: File) {
    const extension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length)
    if (!allowedExtensions.includes('.' + extension)) {
        notify({ title: t('unsupportedFileType', { filetype: extension }), type: 'error' })

        return
    }

    loadingState.value = LoadingState.UploadingFile
    const fileResponse = await vaporUpload(file)

    try {
        loadingState.value = LoadingState.Importing
        const response = await axios.post(
            props.endpoint,
            {
                filepath: fileResponse.key,
                extension,
            },
            { responseType: 'blob' },
        )

        importResult.value = {
            importedRows: parseInt(response.headers['imported-rows'], 10),
            totalRows: parseInt(response.headers['total-rows'], 10),
            resultFile: response.data,
            resultFileName: response.headers['content-disposition'].replace(
                'attachment; filename=',
                '',
            ),
        }

        notify({ title: t('importFinished'), type: 'success' })

        if ((importResult.value?.importedRows || 0) > 0) {
            emit('import-finished')
        }
    } catch (_) {
        notify({ title: t('unknownError'), text: t('tryAgainLater'), type: 'error' })
    }

    loadingState.value = LoadingState.NotLoading
    fileRef.value!.value = ''
}

function downloadResult() {
    download(
        importResult.value!.resultFile,
        importResult.value!.resultFileName,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    )
}
</script>

<template>
    <div>
        <div v-if="importResult" class="mb-4 rounded-lg bg-primary-200 dark:bg-dark-500 p-3">
            <h2
                class="mb-1 text-center text-lg"
                :class="{
                    'text-red-500': importResult.importedRows !== importResult.totalRows,
                }"
                v-text="
                    t('importFinishedExplanation', {
                        imported: importResult.importedRows,
                        total: importResult.totalRows,
                    })
                "
            />

            <p
                v-if="importResult.importedRows !== importResult.totalRows"
                class="whitespace-pre-line"
                v-text="t('importErrorExplanation')"
            />

            <div class="mt-2 flex justify-center">
                <MyButton plain scheme="primary" type="button" @click="downloadResult">
                    <mdi:download class="-mb-1 mr-2" />
                    {{ t('downloadResult') }}
                </MyButton>
            </div>
        </div>

        <div
            ref="dropZoneRef"
            class="relative flex cursor-pointer flex-col items-center justify-center space-y-1 rounded-md border-2 border-dashed px-6 pt-5 pb-6"
            :class="{ 'border-gray-300': !isOverDropZone, 'border-green-500': isOverDropZone }"
            @click="fileRef?.click()"
        >
            <LoaderWrapper
                :visible="loadingState !== LoadingState.NotLoading"
                :text="
                    loadingState === LoadingState.UploadingFile
                        ? t('uploadingFile')
                        : t('importingRecords')
                "
                class="rounded-xl"
            />

            <mdi:file class="mx-auto h-12 w-12 text-gray-400" />

            <div class="flex text-sm text-gray-600">
                <label
                    for="file-upload"
                    class="relative cursor-pointer rounded-md font-medium text-green-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                    @click.stop=""
                >
                    <span v-text="t('uploadAFile')" />
                    <input
                        id="file-upload"
                        ref="fileRef"
                        name="file-upload"
                        type="file"
                        class="sr-only"
                        :accept="allowedExtensions.join(',')"
                        @change="fileInputChanged"
                    />
                </label>
                <p class="pl-1 dark:text-gray-400" v-text="t('orDragAndDrop')" />
            </div>
            <p class="text-xs text-gray-500" v-text="extensionString" />
        </div>

        <p class="pt-1 dark:text-gray-300" v-text="'* ' + t('generalImportDescription')" />

        <div class="mt-5 flex justify-center">
            <MyButton
                v-if="props.template"
                plain
                scheme="primary"
                type="button"
                @click="downloadTemplate"
            >
                <mdi:download class="-mb-1 mr-2" />
                {{ t('downloadImportTemplate') }}
            </MyButton>
        </div>
    </div>
</template>
