<script lang="ts">
interface Props {
    modelValue: boolean
    checklist?: Checklist
}

interface Form {
    fields: ChecklistField[]
    name: { en: string } & Partial<Record<LanguageCode, string>>
}
</script>

<script setup lang="ts">
import type { ResourceResponse } from '@/types/general'

import axios from 'axios'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { v4 as uuid } from 'uuid'

import { useAuthStore } from '@/stores/auth-store'
import { ChecklistField, DefaultChecklistField, Checklist } from '@/types/general'
import useForm from '@/hooks/use-form'
import { LanguageCode } from '@/utils/language-codes'

import ManageChecklistFields from '@/components/ManageChecklistFields.vue'
import TranslationModal from '@/components/TranslationModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyModal from '@/components/my-components/MyModal.vue'

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close'): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()

const defaultChecklistFields = ref<DefaultChecklistField[]>([])
const nameTranslationModalOpen = ref(false)

const { data, loading, errors, reset, submit } = useForm<Form>({
    name: { en: '' },
    fields: [],
})

function sortFields() {
    data.fields.sort((a, b) => a.order - b.order)
}

async function fetchDefaultChecklistFields() {
    if (defaultChecklistFields.value.length === 0) {
        loading.value = true
        const response = await axios.get<ResourceResponse<DefaultChecklistField[]>>(
            window.route('company.checklists.default-checklist-fields', {
                company: authStore.companyId,
            }),
        )
        loading.value = false
        defaultChecklistFields.value = response.data.data
    }

    defaultChecklistFields.value.forEach((field) => {
        data.fields.push({
            id: uuid(),
            name: field.name,
            type: field.type,
            required: false,
            imageRequired: false,
            hideNotPossibleToCheck: false,
            alarmType: null,
            alarmValue: 0,
            order: data.fields.length,
            translations: field.translations,
        })
    })
}

async function closeModal() {
    reset()
    emit('close')
}

async function onSubmit() {
    if (!authStore.companyId) return ''
    let route

    const transformedData = {
        ...data,
        fields: data.fields.map((field) => ({ ...field, name: field.translations })),
    }

    if (props.checklist) {
        route = window.route('company.checklists.update', {
            company: authStore.companyId,
            checklist: props.checklist,
        })
    } else {
        route = window.route('company.checklists.store', {
            company: authStore.companyId,
        })
    }

    const response = await submit<ResourceResponse<Checklist>>(
        props.checklist ? 'PUT' : 'POST',
        route,
        { data: transformedData },
    )

    if (response !== undefined) closeModal()
}

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return

        reset({
            name: props.checklist?.translations ?? { en: '' },
            fields: JSON.parse(JSON.stringify(props.checklist?.fields ?? [])) ?? [],
        })

        if (props.checklist) {
            sortFields()
        } else {
            fetchDefaultChecklistFields()
        }
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="550" @close="closeModal">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            <div class="mr-6 font-bold" v-text="checklist?.name ?? t('createChecklist')" />
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <ManageChecklistFields v-model="data.fields" />
            <div
                class="-mb-4 pt-4 flex items-end space-x-4 space-y-2 bg-primary-100 pb-4 dark:bg-dark-600"
            >
                <MyInput v-model="data.name.en" name="name" :label="t('checklistName')">
                    <template #button>
                        <MyButton
                            v-tooltip="t('translate')"
                            plain
                            size="micro"
                            type="button"
                            class="!h-4 !p-0 -m-8 mt-1.5 hover:bg-primary-200"
                            @click="nameTranslationModalOpen = true"
                        >
                            <mdi:translate class="inline-block h-4 text-blue-400" />
                        </MyButton>
                    </template>
                </MyInput>

                <MyButton
                    :disabled="loading"
                    scheme="primary"
                    type="button"
                    class="mt-6 w-2/5 flex-shrink-0"
                    @click="onSubmit"
                >
                    {{ !checklist ? t('createChecklist') : t('updateChecklist') }}
                </MyButton>
            </div>
        </MyForm>

        <TranslationModal v-model="data.name" v-model:open="nameTranslationModalOpen" />
    </MyModal>
</template>
