<script lang="ts">
interface Props {
    modelValue: boolean
    template?: Template
}

interface Form {
    name: string
    masterTemplateId: string | undefined
    tireThreadCheck: boolean
    tireThreadThreshold?: number
    fields: ChecklistField[]
}
</script>

<script setup lang="ts">
import type { ChecklistField, DefaultChecklistField, ResourceResponse } from '@/types/general'

import axios from 'axios'
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { v4 as uuid } from 'uuid'

import { useAuthStore } from '@/stores/auth-store'
import { MasterTemplate, Template } from '@/types/damage-report'
import { TransportObjectType } from '@/types/transport-object'
import useForm from '@/hooks/use-form'
import { transportObjectTypes } from '@/utils/type-translations'

import ManageChecklistFields from '@/components/ManageChecklistFields.vue'
import AddFieldModal from '@/components/damage-reports/AddFieldModal.vue'
import MasterTemplateCard from '@/components/damage-reports/MasterTemplateCard.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyModal from '@/components/my-components/MyModal.vue'

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close'): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()

const allMasterTemplates = ref<MasterTemplate[]>([])
const defaultChecklistFields = ref<DefaultChecklistField[]>([])
const addFieldModalOpen = ref(false)
const searchTemplateQuery = ref('')
const searchFieldQuery = ref('')

const templateType = ref<TransportObjectType | undefined>()

const { data, loading, errors, reset, submit } = useForm<Form>({
    name: '',
    masterTemplateId: undefined,
    tireThreadCheck: false,
    tireThreadThreshold: undefined,
    fields: [],
})

const masterTemplates = computed<MasterTemplate[]>(() => {
    const query = searchTemplateQuery.value.toLowerCase()
    return allMasterTemplates.value.filter((template) => {
        if (templateType.value !== undefined && template.type !== templateType.value) {
            return false
        }

        if (!query) return true

        if (template.name.toLowerCase().includes(query)) return true
        if (t(transportObjectTypes[template.type]).toLowerCase().includes(query)) return true

        return false
    })
})

const selectedMasterTemplate = computed<MasterTemplate | undefined>(() => {
    if (!data.masterTemplateId) return undefined

    return allMasterTemplates.value.find((template) => template.id === data.masterTemplateId)
})

function sortFields() {
    data.fields.sort((a, b) => a.order - b.order)
}

function addField(field: ChecklistField) {
    data.fields.push({ ...field, order: data.fields.length })
    searchFieldQuery.value = ''
}

async function fetchMasterTemplates() {
    loading.value = true
    const response = await axios.get<ResourceResponse<MasterTemplate[]>>(
        window.route('dmr.company.master-templates.index', authStore.companyId),
    )
    loading.value = false

    allMasterTemplates.value = response.data.data
}

async function fetchDefaultChecklistFields() {
    if (defaultChecklistFields.value.length === 0) {
        loading.value = true
        const response = await axios.get<ResourceResponse<DefaultChecklistField[]>>(
            window.route('dmr.company.checklists.default-checklists-fields', {
                company: authStore.companyId,
            }),
        )
        loading.value = false
        defaultChecklistFields.value = response.data.data
    }

    defaultChecklistFields.value.forEach((field) => {
        data.fields.push({
            id: uuid(),
            name: field.name,
            type: field.type,
            required: false,
            imageRequired: false,
            hideNotPossibleToCheck: false,
            alarmType: null,
            alarmValue: 0,
            order: data.fields.length,
            translations: field.translations,
        })
    })
}

function selectTemplate(template: MasterTemplate) {
    data.masterTemplateId = template.id
}

async function closeModal() {
    data.masterTemplateId = undefined
    data.tireThreadThreshold = undefined
    reset()
    emit('close')
}

async function onSubmit() {
    if (!authStore.companyId) return ''
    let route

    const transformedData = {
        ...data,
        fields: data.fields.map((field) => ({ ...field, name: field.translations })),
    }

    if (props.template) {
        route = window.route('dmr.company.templates.update', {
            company: authStore.companyId,
            template: props.template || '',
        })
    } else {
        route = window.route('dmr.company.templates.store', {
            company: authStore.companyId,
        })
    }

    const response = await submit<ResourceResponse<Template>>(
        props.template ? 'PUT' : 'POST',
        route,
        { data: transformedData },
    )

    if (response !== undefined) closeModal()
}

watch(
    () => data.tireThreadCheck,
    (value) => {
        data.tireThreadThreshold = value ? props.template?.tireThreadThreshold ?? 13 : undefined
    },
)
watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) {
            reset()
            return
        }
        if (props.template) {
            data.name = props.template.name
            data.masterTemplateId = props.template.masterTemplate.id ?? ''
            data.tireThreadCheck = props.template.tireThreadCheck ?? ''
            data.tireThreadThreshold = props.template.tireThreadThreshold ?? undefined
            data.fields = JSON.parse(JSON.stringify(props.template.fields)) ?? []
            sortFields()
        }
    },
)

watch(
    () => props.modelValue,
    (value) => {
        if (value && !props.template) fetchMasterTemplates(), fetchDefaultChecklistFields()
    },
)
</script>

<template>
    <MyModal
        :value="props.modelValue"
        :back-button="template === undefined && selectedMasterTemplate !== undefined"
        :max-width="550"
        @back-button-clicked="data.masterTemplateId = undefined"
        @close="closeModal"
    >
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            <div
                v-if="selectedMasterTemplate && !template"
                class="mr-6 font-bold"
                v-text="t('template') + ': ' + selectedMasterTemplate.name"
            />

            <div
                v-if="template"
                class="mr-6 font-bold"
                v-text="t('template') + ': ' + template.masterTemplate.name"
            />
            <div
                v-if="!template && !selectedMasterTemplate"
                class="mr-6 font-bold"
                v-text="t('templateType')"
            />
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div v-if="!selectedMasterTemplate && !template">
                <div class="mb-8 mt-4 space-y-6">
                    <div class="flex justify-between space-x-4">
                        <MyButton
                            plain
                            scheme="primary"
                            type="button"
                            class="h-8 w-full rounded"
                            :active="templateType === undefined"
                            @click="templateType = undefined"
                        >
                            {{ t('all') }}
                        </MyButton>
                        <MyButton
                            plain
                            scheme="primary"
                            type="button"
                            class="h-8 w-full rounded"
                            :active="templateType === TransportObjectType.Unit"
                            @click="templateType = TransportObjectType.Unit"
                        >
                            {{ t('unit') }}
                        </MyButton>
                        <MyButton
                            plain
                            scheme="primary"
                            type="button"
                            class="h-8 w-full rounded"
                            :active="templateType === TransportObjectType.Vehicle"
                            @click="templateType = TransportObjectType.Vehicle"
                        >
                            {{ t('vehicle') }}
                        </MyButton>
                    </div>
                    <div>
                        <MyInput
                            v-model="searchTemplateQuery"
                            :background="'bg-primary-50 focus:bg-white dark:bg-dark-500 dark:focus:bg-dark-700'"
                            :placeholder="t('search')"
                            group-class="sticky top-0 z-10"
                            type="search"
                        >
                            <template #icon>
                                <mdi:magnify />
                            </template>
                        </MyInput>
                    </div>
                </div>

                <div class="flex flex-wrap justify-center gap-6 py-2">
                    <div v-for="masterTemplate in masterTemplates" :key="masterTemplate.name">
                        <MasterTemplateCard
                            :master-template="masterTemplate"
                            @click="selectTemplate(masterTemplate)"
                        />
                    </div>
                </div>
            </div>

            <div v-if="template || selectedMasterTemplate">
                <ManageChecklistFields v-model="data.fields" />

                <div
                    class="sticky -mb-4 flex flex-col space-y-2 bg-primary-100 pb-4 pt-1 dark:bg-dark-600"
                >
                    <div class="flex w-full flex-row justify-between">
                        <MyCheckbox
                            v-model="data.tireThreadCheck"
                            name="tireThread"
                            class="mt-6"
                            :label="t('enableTireThreadCheck')"
                        />
                        <div class="flex w-2/5 items-center">
                            <MyInput
                                v-if="data.tireThreadCheck"
                                v-model="data.tireThreadThreshold"
                                name="tireThreadThreshold"
                                :label="t('tireThreadThreshold')"
                                type="number"
                            >
                                <template #button>
                                    <span
                                        class="absolute top-0 bottom-1.5 right-2 m-auto h-5"
                                        v-text="'mm'"
                                    />
                                </template>
                            </MyInput>
                        </div>
                    </div>
                    <div class="flex space-x-4">
                        <MyInput v-model="data.name" name="name" :label="t('templateName')" />
                        <MyButton
                            :disabled="loading"
                            scheme="primary"
                            type="button"
                            class="mt-6 w-2/5 flex-shrink-0"
                            @click="onSubmit"
                        >
                            {{ !template ? t('createTemplate') : t('updateTemplate') }}
                        </MyButton>
                    </div>
                </div>
            </div>
        </MyForm>
    </MyModal>

    <AddFieldModal
        v-if="selectedMasterTemplate || template"
        v-model="addFieldModalOpen"
        @save="addField"
    />
</template>
