<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { DropdownOption } from '@/types/inputs'

import MySelect from '@/components/my-components/form/MySelect.vue'

const timePeriod = defineModel<number>({ required: true })
const { t } = useI18n()

const timePeriodOptions = computed<DropdownOption[]>(() => [
    { label: t('last7days'), value: 7 },
    { label: t('last14Days'), value: 14 },
    { label: t('last30Days'), value: 30 },
])
</script>

<template>
    <MySelect
        v-model="timePeriod"
        :options="timePeriodOptions"
        group-class="mt-4 mx-auto"
        :label="t('period')"
    />
</template>
