<script lang="ts" setup>
import type { CompanyProduct } from '@/types/packaging'

import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { useProductStore } from '@/stores/product-store'

import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import ProductItem from '@/components/packaging/products/ProductItem.vue'

const { t, locale } = useI18n()
const searchQuery = ref('')

const productStore = useProductStore()
const authStore = useAuthStore()

const { fetching, modalOpen } = storeToRefs(productStore)

const displayProducts = computed<CompanyProduct[]>(() =>
    productStore.nonCompanyProducts
        .filter((product) => {
            const search = searchQuery.value.toLowerCase().trim()

            if (search) {
                return (
                    product.name.toLowerCase().includes(search) ||
                    product.description?.toLowerCase().includes(search) ||
                    product.dimensions?.toLowerCase().includes(search)
                )
            }

            return product
        })
        .map((product) => ({
            ...product,
            price: 0,
            favored: false,
            order: 0,
            externalProductId: null,
        })),
)

watch(locale, () => productStore.fetchProducts())
</script>

<template>
    <MyModal v-model="modalOpen" :max-width="1200">
        <template #title>
            <ContentHeading v-text="t('allProducts')" />

            <MyButton
                class="absolute right-0 top-0"
                icon
                pill
                scheme="action"
                size="small"
                @click="modalOpen = false"
            >
                <mdi:close />
            </MyButton>
        </template>

        <LoaderWrapper :visible="fetching" />

        <article>
            <div class="my-6 items-center justify-between space-x-1.5 text-sm">
                <MyInput v-model="searchQuery" :placeholder="t('searchProducts')">
                    <template #icon>
                        <mdi:magnify />
                    </template>
                </MyInput>
            </div>
            <section class="gap-6 sm:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
                <ProductItem
                    v-for="product in displayProducts"
                    :key="product.id"
                    :product="product"
                />
            </section>
        </article>
    </MyModal>

    <MyButton
        v-if="!authStore.isReadonly.packaging"
        plain
        scheme="primary"
        size="small"
        @click="modalOpen = true"
    >
        <mdi:plus-thick class="mr-1" />
        {{ t('addProducts') }}
    </MyButton>
</template>
