import { RouteLocationRaw } from 'vue-router'

import { uuid } from '@/types/general'
import { MinimalUser, UserType } from '@/types/user'
import { ReportType } from '@/types/damage-report'
import { MinimalTransportObject, TransportObjectType } from '@/types/transport-object'
import { ReceiptType } from '@/types/pod'
import { Shipment } from '@/types/delivery-management'
import { LocationPoint } from '@/types/company'
import { EventType } from '@/types/driver-report'

export interface Dashboard {
    id: uuid
    userId: uuid
    companyId: uuid
    name: string
    slug: string
    private: boolean
    active: boolean
    widgets: DashboardWidget[]
    shareToken: string | null
}

export enum WidgetType {
    Example = 0,
    PackagingBalance = 1,
    PackagingStock = 2,
    PackagingEmployeeTransactions = 3,
    DamageReportsEmployeeReports = 4,
    DriverReportsOngoingEmployeeReports = 5,
    DamageReportsTransportObjectService = 6,
    DamageReportsIncidentCount = 7,
    DamageReportsEmployeeIncidents = 8,
    DamageReportsTransportObjectIncidents = 9,
    PodEmployeeReceipts = 10,
    DeliveryManagementOngoingTourMap = 11,
    DriverReportsEmployeeReports = 12,
    DeliveryManagementOngoingTourList = 13,
    EmployeeDriverReportsStopTime = 14,
}

export interface DashboardWidget<Type extends WidgetType = WidgetType> {
    id: uuid
    dashboardId: uuid
    type: Type
    x: number
    y: number
    width: number
    height: number
    options: Type extends keyof WidgetOptionsMap ? WidgetOptionsMap[Type] : Record<string, unknown>
}

type WidgetOptionsMap = {
    [WidgetType.PackagingBalance]: BalanceWidgetOptions
    [WidgetType.PackagingStock]: StockWidgetOptions
    [WidgetType.PackagingEmployeeTransactions]: EntityCountOptions
    [WidgetType.DamageReportsEmployeeReports]: EntityCountOptions
    [WidgetType.DamageReportsTransportObjectService]: TransportObjectOptions
    [WidgetType.DamageReportsIncidentCount]: IncidentCountWidgetOptions
    [WidgetType.DamageReportsEmployeeIncidents]: EmployeeIncidentsWidgetOptions
    [WidgetType.DamageReportsTransportObjectIncidents]: TransportObjectIncidentsWidgetOptions
    [WidgetType.PodEmployeeReceipts]: PodEmployeeReceiptsWidgetOptions
    [WidgetType.DeliveryManagementOngoingTourMap]: OngoingTourWidgetOptions
    [WidgetType.DriverReportsEmployeeReports]: DriverReportsEmployeeReportsOptions
    [WidgetType.DeliveryManagementOngoingTourList]: OngoingTourWidgetOptions
    [WidgetType.EmployeeDriverReportsStopTime]: EmployeeDriverReportsStopTimeWidgetOptions
}

export enum SortDirection {
    desc = 'desc',
    asc = 'asc',
}

interface BalanceWidgetOptions {
    selectedLocationId?: uuid
    sortDirection?: SortDirection
    products?: uuid[]
}

interface StockWidgetOptions {
    selectedLocationId?: uuid
}

export type EntityWidgetOrderBy = '-count' | 'count' | 'name'
interface EntityCountOptions {
    selectedLocationId?: uuid
    sortBy?: EntityWidgetOrderBy
    timePeriod?: number
}

interface EmployeeIncidentsWidgetOptions {
    reportType?: ReportType
    sortBy?: EntityWidgetOrderBy
    timePeriod?: number
}

interface TransportObjectIncidentsWidgetOptions extends EmployeeIncidentsWidgetOptions {
    transportObjectType?: TransportObjectType
}

interface PodEmployeeReceiptsWidgetOptions {
    receiptType?: ReceiptType
    sortBy?: EntityWidgetOrderBy
    timePeriod?: number
}

export interface StopTypeCounts {
    break: number
    resting: number
    customer: number
    other: number
    traffic: number
}

export type StopTypeKey = keyof StopTypeCounts

interface EmployeeDriverReportsStopTimeWidgetOptions {
    stopTypes?: EventType[]
    sortDirection?: SortDirection
    timePeriod?: number
}

interface TransportObjectOptions {
    sortDirection?: SortDirection
}

interface DriverReportsEmployeeReportsOptions {
    sortBy?: EntityWidgetOrderBy
    timePeriod?: number
}

export interface IncidentCountWidgetOptions {
    timePeriod?: number
}

export interface OngoingTourWidgetOptions {
    hubId?: uuid
}

export interface DashboardGridRef {
    refreshGrid: () => void
    addWidget: <T extends DashboardWidget>(widget: T) => T
}

export interface EntityRecordCountWidgetRef {
    fetchData: () => void
}

export interface EmployeeEntityCount extends MinimalUser {
    userType: UserType
    entityCount: number
}

export interface EntityCountRecord {
    id: uuid
    title: string
    subtitle?: string
    image?: string
    count: number | string
}

export interface EntityLink {
    route: RouteLocationRaw
    title: string
}

export interface OngoingTour {
    id: uuid
    name: string
    startedAt: string | null
    userId: string
    userName: string
    events: {
        id: uuid
        handledAt: string | null
        estimatedAt: string | null
        liveEstimatedAt: string | null
        delay: number | null
        shipment: Pick<Shipment, 'shipmentNumber' | 'name'>
    }[]
    transportObjects: MinimalTransportObject[]
    latestLocation: LocationPoint | null
}
