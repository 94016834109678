<script lang="ts" setup>
import { GridStack, GridStackWidget } from 'gridstack'
import { onMounted, ref, watch } from 'vue'
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import { useIntervalFn } from '@vueuse/core'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'

import { DashboardWidget } from '@/types/dashboard'
import { useDashboardStore } from '@/stores/dashboard-store'
import { emitter } from '@/utils/mitt'

import WidgetResolver from '@/components/dashboard/widgets/WidgetResolver.vue'

const dashboardStore = useDashboardStore()
const { t } = useI18n()

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    ArcElement,
)

interface Props {
    widgets: DashboardWidget[]
}

const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'change', value: GridStackWidget[]): void }>()

let grid: GridStack | null = null //Limitation of the plugin requires the grid to be a let
const gridStackElement = ref<HTMLDivElement>()
const lastUpdated = ref(dayjs())

function initializeGrid() {
    grid?.destroy(false)
    grid = GridStack.init(
        {
            float: false,
            cellHeight: '190px',
            minRow: 1,
            disableDrag: true,
            disableResize: true,
        },
        gridStackElement.value,
    )

    grid.on('change', (_, widgets) => emit('change', widgets))
}

function refreshGrid() {
    const widgets = props.widgets.map((widget) => toGridStackWidget(widget))
    // We'll need to update GridStacks internal state
    grid?.load(widgets)
}

function toGridStackWidget(widget: DashboardWidget): GridStackWidget {
    return { id: widget.id, x: widget.x, y: widget.y, w: widget.width, h: widget.height }
}

/**
 * Adds the widget to the grid and finds an empty spot for it
 * @param widget
 */
function addWidget<T extends DashboardWidget>(widget: T): T {
    const gsWidget = toGridStackWidget(widget)
    grid?.engine.findEmptyPosition(
        gsWidget,
        props.widgets.map((widget) => toGridStackWidget(widget)),
    )
    // addWidget should be able to auto place widget by setting autoPlacement
    // But it's not working.
    grid!.addWidget(gsWidget)
    widget.x = gsWidget.x ?? 0
    widget.y = gsWidget.y ?? 0
    return widget
}

watch(
    () => dashboardStore.isEditing,
    () => {
        if (dashboardStore.isEditing) {
            grid?.enable()
        } else {
            grid?.disable()
        }
    },
)

onMounted(() => initializeGrid())

useIntervalFn(
    () => {
        emitter.emit('fetchWidgetData')
        lastUpdated.value = dayjs()
    },
    1000 * 60 * 5, // Interval of 5 minutes
)

defineExpose({ refreshGrid, addWidget })
</script>

<template>
    <div class="relative">
        <div ref="gridStackElement" class="grid-stack -m-2.5">
            <div
                v-for="widget in props.widgets"
                :id="widget.id"
                :key="widget.id"
                class="grid-stack-item"
                :gs-x="widget.x"
                :gs-y="widget.y"
                :gs-w="widget.width"
                :gs-h="widget.height"
                :gs-id="widget.id"
            >
                <div
                    class="grid-stack-item-content !overflow-visible"
                    :class="{ 'cursor-move': dashboardStore.isEditing }"
                >
                    <WidgetResolver :widget="widget" />
                </div>
            </div>
        </div>
        <span
            class="absolute -bottom-14 left-0 text-sm dark:text-primary-100 text-primary-500"
            v-text="t('lastUpdatedAt', { time: lastUpdated.format('HH:mm:ss') })"
        />
    </div>
</template>
