<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, computed } from 'vue'

import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { uuid } from '@/types/general'

import BasicShareModal from '@/components/BasicShareModal.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'

export interface Props {
    reportId?: uuid
}
const props = defineProps<Props>()

const modalOpen = defineModel<boolean>({ required: true })

const authStore = useAuthStore()
const { t } = useI18n()

const attachConsignmentNotes = ref(authStore.hasLicense(LicenseType.PodPdfGeneration))

const shareEndpoint = computed(() => {
    if (!authStore.companyId) return ''
    if (!props.reportId) return

    return window.route('dr.company.reports.share', {
        company: authStore.companyId,
        report: props.reportId,
        'attach-consignment-notes': attachConsignmentNotes.value,
    })
})
</script>

<template>
    <BasicShareModal
        v-if="shareEndpoint"
        v-model="modalOpen"
        :entity="t('report')"
        :endpoint="shareEndpoint"
    >
        <template v-if="authStore.hasLicense(LicenseType.PodPdfGeneration)" #submitPrefix>
            <div class="flex space-x-2">
                <MyCheckbox
                    v-model="attachConsignmentNotes"
                    name="consignmentNotes"
                    :label="t('attachConsignmentNotes')"
                />
                <mdi:information v-tooltip="t('attachConsignmentNotesExplanation')" />
            </div>
        </template>
    </BasicShareModal>
</template>
