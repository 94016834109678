import { AlarmType } from '@/types/damage-report'
import { TransportObjectType } from '@/types/transport-object'
import { TourType } from '@/types/driver-report'
import { ChecklistFieldType } from '@/types/general'

export const transportObjectTypes: Record<TransportObjectType, string> = {
    [TransportObjectType.Vehicle]: 'vehicle',
    [TransportObjectType.Unit]: 'unit',
}

export const checklistFieldTypes: Record<ChecklistFieldType, string> = {
    [ChecklistFieldType.Number]: 'number',
    [ChecklistFieldType.Boolean]: 'boolean',
    [ChecklistFieldType.Text]: 'text',
    [ChecklistFieldType.TankLevel]: 'tankLevel',
}

export const alarmTypes: Record<AlarmType | 'null', string> = {
    null: 'none',
    [AlarmType.Below]: 'alarmWhenBelow',
    [AlarmType.Above]: 'alarmWhenAbove',
    [AlarmType.Equals]: 'alarmWhenEquals',
    [AlarmType.Filled]: 'alarmWhenFilled',
}

export const alarmTextTypes: Partial<Record<AlarmType | 'null', string>> = {
    null: 'none',
    [AlarmType.Filled]: 'alarmWhenFilled',
}

export const alarmNumberTypes: Partial<Record<AlarmType | 'null', string>> = {
    null: 'none',
    [AlarmType.Below]: 'alarmWhenBelow',
    [AlarmType.Above]: 'alarmWhenAbove',
    [AlarmType.Equals]: 'alarmWhenEquals',
}

export const alarmBooleanTypes: Record<'null' | 'true' | 'false', string> = {
    null: 'none',
    true: 'alarmWhenTrue',
    false: 'alarmWhenFalse',
}

export const tourTypes: Record<TourType, string> = {
    [TourType.Domestic]: 'domestic',
    [TourType.Export]: 'export',
    [TourType.LineHaul]: 'lineHaul',
}
