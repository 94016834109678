<script lang="ts" setup>
import axios from 'axios'
import { computed, ref, watch, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'

import { ResourceResponse, uuid } from '@/types/general'
import { addressString } from '@/utils/string'
import { useAuthStore } from '@/stores/auth-store'
import { printElement } from '@/utils/print-element'
import { Shipment, ShipmentType } from '@/types/delivery-management'
import { shipmentTypes } from '@/utils/delivery-management'
import { image } from '@/utils/assets'

import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import ConsignmentNoteSheet from '@/components/e-cmr/ConsignmentNoteSheet.vue'

export interface Props {
    id?: string
    open?: boolean
}

const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const props = withDefaults(defineProps<Props>(), { open: true })

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const authStore = useAuthStore()

const modalContent = ref<HTMLDivElement>()
const shipment = ref<Shipment | null>(null)
const loading = ref(false)

const shipmentIsPickup = computed(() => shipment.value?.type === ShipmentType.Pickup)

const deliveryAddressName = computed(() => {
    if (!shipment.value) return
    if (shipment.value.addressName) {
        return shipment.value.addressName
    }
    if (!shipmentIsPickup.value) {
        return shipment.value.receivingLocation.name
    }
    return shipment.value.sendingLocation.name
})

const deliveryAddress = computed(() => {
    if (!shipment.value) return

    if (shipment.value.address) {
        return shipment.value.address
    } else {
        return addressString(shipment.value.receivingLocation).replace(',', '<br />')
    }
})

const takenOverDate = computed(() => {
    if (!shipment.value?.consignmentNote?.takenOverAt) return ''

    return dayjs(shipment.value.consignmentNote.takenOverAt).format('DD-MM-YYYY')
})

const transpiredOrCreatedDate = computed(() => {
    const note = shipment.value?.consignmentNote
    if (!note) return ''

    const date = note.transpiredAt || note.createdAt
    return date ? dayjs(date).format('DD-MM-YYYY') : ''
})

async function fetchShipment() {
    const shipmentId = (route.params.shipmentId as uuid | undefined) || props.id
    if (!shipmentId) return

    loading.value = true
    try {
        const response = await axios.get<ResourceResponse<Shipment>>(
            window.route('ecmr.company.consignment-notes.show', {
                company: authStore.companyId,
                shipment: shipmentId,
            }),
        )
        shipment.value = response.data.data
    } finally {
        loading.value = false
    }
}

function print() {
    printElement(modalContent.value!)
}

function onClose() {
    if (props.id) {
        emit('update:open', false)
    } else {
        router.replace({ name: 'ecmr.consignment-notes', query: route.query })
    }
}

onBeforeMount(() => fetchShipment())

watch(() => props.id, fetchShipment)

watch(() => route.params.shipmentId, fetchShipment)
</script>

<template>
    <MyModal :value="props.open" light :max-width="1200" @close="onClose">
        <LoaderWrapper :visible="loading" />
        <div class="mb-6 flex justify-between">
            <ContentHeading>
                <h1 class="text-xl font-bold text-primary-400" v-text="t('consignmentNote')" />
                <h4 class="font-semibold text-xs mt-1 text-primary-500 dark:text-primary-300">
                    {{ t('type') }}:
                    {{ shipment ? t(shipmentTypes[shipment.type]) : t('notSpecified') }}
                </h4>
            </ContentHeading>

            <div v-if="authStore.companyId" class="flex space-x-2 print:hidden">
                <MyButton icon plain scheme="light" size="small" @click="print()">
                    <mdi:printer />
                </MyButton>
            </div>
        </div>

        <div ref="modalContent">
            <ConsignmentNoteSheet v-if="shipment">
                <template #sender>
                    <span v-text="shipment.sendingCompany.name" />
                    <span v-html="addressString(shipment.sendingLocation).replace(',', '<br />')" />
                </template>
                <template #receiver>
                    <span v-text="shipment.receivingCompany.name" />
                    <span
                        v-html="addressString(shipment.receivingLocation).replace(',', '<br />')"
                    />
                </template>
                <template #shipmentNumber>
                    <h3 v-text="t('no').toUpperCase() + ': ' + shipment.shipmentNumber"></h3>
                </template>
                <template #imageLogo>
                    <img
                        :alt="shipment.sendingCompany.name"
                        :src="
                            image(
                                shipment.sendingCompany.logo ??
                                    'cf6c449d-282b-49e9-838e-eb395c168700',
                                'thumbnail',
                            )
                        "
                        class="qr w-36 aspect-square self-center bg-primary-400"
                    />
                </template>
                <template #placeOfDelivery>
                    <span v-text="deliveryAddressName" />
                    <span v-html="deliveryAddress" />
                </template>
                <template #takenOverPlace>
                    <span v-text="shipment.consignmentNote?.takenOverPlace || ''" />
                    <span class="text-end">{{ takenOverDate }}</span>
                </template>
                <template #documentsAttached>
                    <span v-text="shipment.consignmentNote?.attachedDocuments || ''" />
                </template>
                <template #shipper>
                    <span
                        v-text="
                            shipment.shipperCompany?.name ??
                            t('noEntitiesForProperty', {
                                entity: t('shipper').toLowerCase(),
                                property: t('shipment').toLowerCase(),
                            })
                        "
                    />
                    <span
                        v-if="shipment.shipperCompany"
                        v-html="addressString(shipment.shipperCompany).replace(',', '<br />')"
                    />
                </template>
                <template #haulier>
                    <span v-text="shipment.haulierCompany?.name || ''" />
                    <span
                        v-if="shipment.haulierCompany"
                        v-html="addressString(shipment.haulierCompany).replace(',', '<br />')"
                    />
                </template>
                <template #shipperInstructions>
                    <span v-text="shipment.consignmentNote?.shipperInstructions || ''" />
                </template>
                <template #collis>
                    <!-- Header Row -->
                    <main
                        v-if="shipment.collis.length > 0"
                        class="grid border-black border-b grid-cols-7 font-semibold text-primary-500 dark:text-white dark:bg-dark-500 bg-primary-100 rounded-t-sm justify-items-center px-2.5"
                    >
                        <span class="py-1 text-left">
                            <span class="text-lg">6.</span> {{ t('name') }}
                        </span>

                        <span class="py-1 text-left">
                            <span class="text-lg">7.</span> {{ t('description') }}
                        </span>

                        <span class="py-1 text-left">
                            <span class="text-lg">8.</span> {{ t('lxwxh') }}
                        </span>

                        <span class="py-1 text-left">
                            <span class="text-lg">9.</span> {{ t('amount') }}
                        </span>

                        <span class="py-1 text-left">
                            <span class="text-lg">10.</span> {{ t('staticalNumber') }}
                        </span>

                        <span class="py-1 text-left">
                            <span class="text-lg">11.</span> {{ t('weight') }}
                        </span>

                        <span class="py-1 text-left">
                            <span class="text-lg">12.</span> {{ t('volume') }}
                        </span>
                    </main>

                    <!-- Empty State -->
                    <span
                        v-else
                        v-text="
                            t('noEntitiesForProperty', {
                                entity: t('colli').toLowerCase(),
                                property: t('shipment').toLowerCase(),
                            })
                        "
                    />

                    <!-- Data Rows (Aligned with the Header) -->
                    <div
                        v-for="(colli, index) in shipment.collis"
                        :key="colli.id"
                        class="grid border-black grid-cols-7 justify-center break-inside-avoid text-center hover:bg-primary-200 dark:odd:bg-dark-400 dark:even:bg-dark-500 py-2.5 px-1.5 dark:hover:bg-dark-300"
                        :class="{
                            'rounded-b-sm': index === shipment.collis?.length - 1,
                        }"
                    >
                        <span class="truncate text-sm" v-text="colli.name" />

                        <span class="text-sm truncate" v-text="colli.description" />

                        <span class="text-sm truncate">
                            {{ colli.length }}x{{ colli.width }}x{{ colli.height }}
                            {{ t('cm') }}
                        </span>

                        <span class="text-sm truncate" v-text="colli.amount" />

                        <!-- Added missing Statical Number column -->
                        <span class="text-sm truncate" v-text="colli.staticalNumber || '-'" />

                        <span class="text-sm truncate"> {{ colli.weight }} {{ t('kg') }} </span>

                        <span class="text-sm truncate"> {{ colli.volume }} {{ t('cbm') }} </span>
                    </div>
                </template>
                <template #senderInstructions>
                    <span v-text="shipment.consignmentNote?.senderInstructions || ''" />
                </template>
                <template #instructionsPayment>
                    <span v-text="shipment.consignmentNote?.paymentInstructions || ''" />
                </template>
                <template #specialAgreements>
                    <span v-text="shipment.consignmentNote?.specialAgreements || ''" />
                </template>
                <template #establishedIn>
                    <span class="" v-text="deliveryAddressName" />
                </template>
                <template #establishedOn>
                    <span v-text="transpiredOrCreatedDate" />
                </template>
                <template #senderSignature>
                    <span v-text="shipment.consignmentNote?.senderSignature || ''" />
                </template>
                <template #driverSignature>
                    <span v-text="shipment.consignmentNote?.driverSignature || ''" />
                </template>
                <template #receiverSignature>
                    <span v-text="shipment.consignmentNote?.receiverSignature || ''" />
                </template>
            </ConsignmentNoteSheet>
        </div>

        <h1
            v-if="!shipment && !loading"
            class="my-auto text-center text-4xl font-bold"
            v-text="t('shipmentNotFound')"
        />
    </MyModal>
</template>
