<script lang="ts">
interface Props {
    customerCompanyId?: uuid
}
</script>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { ref } from 'vue'
import { RouteLocationRaw, useRoute } from 'vue-router'

import { uuid } from '@/types/general'
import { emitter } from '@/utils/mitt'
import { useAuthStore } from '@/stores/auth-store'
import { MinimalShipment } from '@/types/delivery-management'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'

import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import ManageConsignmentNoteModal from '@/components/e-cmr/ManageConsignmentNoteModal.vue'
import ShipmentsTable from '@/components/delivery-management/ShipmentsTable.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import ConsignmentNoteModal from '@/components/e-cmr/ConsignmentNoteModal.vue'

const props = defineProps<Props>()
const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()
const confirm = useConfirm()
const consignmentNoteModalOpen = ref(false)
const cmrModalOpen = ref(false)
const selectedShipment = ref<MinimalShipment>()

async function deleteConsignmentNote(shipment: MinimalShipment) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('consignmentNote') }),
            t('deleteEntityDescription', { entity: t('consignmentNote') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }
    await axios.delete(
        window.route('ecmr.company.consignment-notes.destroy', {
            company: authStore.companyId,
            consignment_note: shipment.consignmentNote?.id,
        }),
    )
    emitFetchShipmentsEvent()
}
function isSenderOrShipper(shipment: MinimalShipment) {
    if (shipment.sendingCompany.id === authStore.companyId) return true
    if (shipment.shipperCompany?.id === authStore.companyId) return true
    return false
}
function updateConsignmentNote(shipment: MinimalShipment) {
    selectedShipment.value = shipment
    consignmentNoteModalOpen.value = true
}
async function consignmentNoteModalClosed() {
    consignmentNoteModalOpen.value = false
    selectedShipment.value = undefined
}
async function cmrModalClosed() {
    cmrModalOpen.value = false
    selectedShipment.value = undefined
}
function emitFetchShipmentsEvent() {
    emitter?.emit('fetchShipments')
}
function showConsignmentNote(id: string): RouteLocationRaw {
    if (route.name?.toString()?.includes('customers.show')) {
        return {
            name: 'ecmr.customers.consignment-notes.show',
            params: { ...route.params, shipmentId: id },
            query: route.query,
        }
    }
    return {
        name: 'ecmr.consignment-notes.show',
        params: { shipmentId: id },
        query: route.query,
    }
}
</script>

<template>
    <ShipmentsTable
        table-id="ecmr-shipments"
        :entity-name="'consignmentNote'"
        :fetch-endpoint="`ecmr.company.consignment-notes.index`"
        :customer-company-id="props.customerCompanyId"
    >
        <template #customActionRow="{ row }">
            <RouterLink :to="showConsignmentNote(row.id)">
                <ActionRowItem>
                    <mdi:eye />
                </ActionRowItem>
            </RouterLink>
        </template>

        <template #customActionMenu="{ row }">
            <template v-if="isSenderOrShipper(row)">
                <ActionMenuItem @click="updateConsignmentNote(row)">
                    <span class="mr-2"><mdi:pencil /></span>
                    {{ t('edit') }}
                </ActionMenuItem>
                <ActionMenuItem
                    v-if="row.sendingCompany.id === authStore.companyId"
                    @click="deleteConsignmentNote(row)"
                >
                    <span class="mr-2"><mdi:trash-can /></span>
                    {{ t('remove') }}
                </ActionMenuItem>
            </template>
        </template>
    </ShipmentsTable>

    <ManageConsignmentNoteModal
        v-model="consignmentNoteModalOpen"
        :shipment-id="selectedShipment?.id"
        @close="consignmentNoteModalClosed"
        @saved="emitFetchShipmentsEvent"
    />

    <ConsignmentNoteModal
        v-if="selectedShipment"
        :id="selectedShipment.id"
        v-model:open="cmrModalOpen"
        @close="cmrModalClosed"
    />
</template>
