import type { MinimalResource, ResourceResponse, uuid } from '@/types/general'

import { computed, ref, Ref } from 'vue'
import axios, { Method } from 'axios'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { addressString } from '@/utils/string'
import { DropdownStringOption } from '@/types/inputs'
import {
    Colli,
    ConsignmentNoteForm,
    Shipment,
    ShipmentColli,
    ShipmentType,
} from '@/types/delivery-management'
import { emitter } from '@/utils/mitt'
import { MinimalLocation } from '@/types/company'

interface DropdownLocationOption extends DropdownStringOption {
    address: string | null
}

interface FormShipmentColli {
    colli: Colli
    amount: number
}

export function useShipmentForm({
    shipmentId,
    onSave,
    isConsignmentNote = false,
}: {
    shipmentId: Ref<string | undefined>
    onSave: () => void
    isConsignmentNote?: boolean
}) {
    const companyStore = useCompanyStore()
    const authStore = useAuthStore()

    const { data, submit, errors, reset, loading } = useForm<ConsignmentNoteForm>({
        type: ShipmentType.Delivery,
        sendingCompanyId: authStore.companyId,
        sendingLocationId: null,
        receivingCompanyId: null,
        receivingLocationId: null,
        shipperCompanyId: null,
        shipperLocationId: null,
        haulierCompanyId: null,
        haulierLocationId: null,
        deliveredByUserId: null,
        deliveryNote: null,
        categoryId: null,
        plannedAt: '',
        palletExchange: false,
        handledAt: null,
        collis: [],
        shipmentNumber: null,
        name: null,
        references: null,
        soldTo: null,
        shipTo: null,
        handleableAfter: null,
        handleableBefore: null,
        senderInstructions: null,
        paymentInstructions: null,
        shipperInstructions: null,
        specialAgreements: null,
        additionalInformation: null,
        transportIdentification: null,
        attachedDocuments: null,
        takenOverAt: '',
        takenOverPlace: null,
        address: null,
    })

    const shipment = ref<Shipment>()
    const colliPickerModalOpen = ref(false)
    const initializing = ref(false)
    const receivers = ref<DropdownStringOption[]>([])
    const shippers = ref<DropdownStringOption[]>([])
    const receiverLocations = ref<DropdownLocationOption[]>([])
    const shipperLocations = ref<DropdownLocationOption[]>([])
    const hauliers = ref<DropdownStringOption[]>([])
    const haulierLocations = ref<DropdownLocationOption[]>([])
    const categories = ref<DropdownStringOption[]>([])

    function convertCollis(collis: ShipmentColli[]): FormShipmentColli[] {
        data.collis = collis.map((colli) => ({
            colli: colli,
            amount: colli.amount,
        }))
        return data.collis
    }

    function removeColli(colli: Colli) {
        data.collis = data.collis?.filter((i) => i.colli.id !== colli.id)
    }

    const companyLocations = computed<DropdownLocationOption[]>(() => {
        const locations = companyStore.locations
        return locations.map((location) => ({
            value: location.id,
            label: location.name,
            address: addressString(location),
        }))
    })

    const companyIsShipper = computed(() => {
        return data.shipperCompanyId === authStore.companyId
    })

    async function fetchShipment() {
        initializing.value = true
        const routeName = isConsignmentNote
            ? 'ecmr.company.consignment-notes.show'
            : 'dm.company.shipments.show'
        const response = await axios.get<ResourceResponse<Shipment>>(
            window.route(routeName, {
                company: authStore.companyId,
                shipment: shipmentId.value ?? '',
            }),
        )
        shipment.value = response.data.data

        data.type = shipment.value.type
        data.sendingCompanyId = shipment.value.sendingCompany.id
        data.sendingLocationId = shipment.value.sendingLocation.id
        data.receivingCompanyId = shipment.value.receivingCompany?.id
        data.receivingLocationId = shipment.value.receivingLocation?.id
        data.shipperCompanyId = shipment.value.shipperCompany?.id ?? null
        data.shipperLocationId = shipment.value.shipperLocation?.id ?? null
        data.haulierCompanyId = shipment.value.haulierCompany?.id ?? null
        data.haulierLocationId = shipment.value.haulierLocation?.id ?? null
        data.deliveredByUserId = shipment.value.deliveredByUser?.id ?? null
        data.deliveryNote = shipment.value.deliveryNote
        data.categoryId = shipment.value.category?.id ?? null
        data.palletExchange = shipment.value.palletExchange
        data.plannedAt = shipment.value.plannedAt
        data.handledAt = shipment.value.handledAt
        data.shipmentNumber = shipment.value.shipmentNumber ?? null
        data.name = shipment.value.name ?? null
        data.references = shipment.value.references ?? null
        data.soldTo = shipment.value.soldTo ?? null
        data.shipTo = shipment.value.shipTo ?? null
        data.handleableAfter = shipment.value.handleableAfter ?? null
        data.handleableBefore = shipment.value.handleableBefore ?? null
        data.senderInstructions = shipment.value.consignmentNote?.senderInstructions ?? null
        data.paymentInstructions = shipment.value.consignmentNote?.paymentInstructions ?? null
        data.shipperInstructions = shipment.value.consignmentNote?.shipperInstructions ?? null
        data.specialAgreements = shipment.value.consignmentNote?.specialAgreements ?? null
        data.additionalInformation = shipment.value.consignmentNote?.additionalInformation ?? null
        data.address = shipment.value.address ?? null
        data.transportIdentification =
            shipment.value.consignmentNote?.transportIdentification ?? null
        data.attachedDocuments = shipment.value?.consignmentNote?.attachedDocuments ?? null
        data.takenOverAt = shipment.value.consignmentNote?.takenOverAt ?? null
        data.takenOverPlace = shipment.value.consignmentNote?.takenOverPlace ?? null

        if (shipment.value.collis) convertCollis(shipment.value.collis)
        if (data.receivingCompanyId) {
            receiverLocations.value = await fetchLocations(data.receivingCompanyId)
        }
        if (data.shipperCompanyId) {
            shipperLocations.value = await fetchLocations(data.shipperCompanyId)
        }
        if (data.haulierCompanyId) {
            haulierLocations.value = await fetchLocations(data.haulierCompanyId)
        }

        initializing.value = false
    }

    async function fetchShippers() {
        const response = await axios.get<MinimalResource[]>(
            window.route('minimal.companies.customers', {
                company: authStore.companyId,
            }),
        )

        shippers.value = response.data.map((shipper) => ({
            value: shipper.id,
            label: shipper.name,
        }))
    }

    async function fetchReceivers() {
        const response = await axios.get<MinimalResource[]>(
            window.route('minimal.companies.customers', {
                company: authStore.companyId,
            }),
        )

        receivers.value = response.data.map((receiver) => ({
            value: receiver.id,
            label: receiver.name,
        }))

        receivers.value.unshift({ value: authStore.companyId, label: authStore.company.name })
    }

    async function fetchLocations(id: uuid | null): Promise<DropdownLocationOption[]> {
        if (!id) return []
        const response = await axios.get<MinimalLocation[]>(
            window.route('minimal.companies.locations', {
                company: id,
            }),
        )

        return response.data.map((location) => ({
            value: location.id,
            label: location.name,
            address: addressString(location),
        }))
    }

    async function fetchHauliers() {
        const response = await axios.get<MinimalResource[]>(
            window.route('minimal.companies.customers', {
                company: authStore.companyId,
                haulier: true,
            }),
        )

        hauliers.value = response.data.map((haulier) => ({
            value: haulier.id,
            label: haulier.name,
        }))
    }

    async function fetchCategories() {
        const response = await axios.get<MinimalResource[]>(
            window.route('minimal.companies.categories', { company: authStore.companyId }),
        )

        categories.value = response.data.map((category) => ({
            value: category.id,
            label: category.name,
        }))
    }

    async function onSubmit() {
        const method: Method = shipmentId.value ? 'PUT' : 'POST'
        const createRoute = !isConsignmentNote
            ? window.route('dm.company.shipments.store', {
                  company: authStore.companyId,
              })
            : window.route('ecmr.company.consignment-notes.store', {
                  company: authStore.companyId,
              })
        const updateRoute = !isConsignmentNote
            ? window.route('dm.company.shipments.update', {
                  company: authStore.companyId,
                  shipment: shipmentId.value ?? '',
              })
            : window.route('ecmr.company.consignment-notes.update', {
                  company: authStore.companyId,
                  consignment_note: shipmentId.value ?? '',
              })
        const response = await submit<ResourceResponse<Shipment>>(
            method,
            shipmentId.value ? updateRoute : createRoute,
        )

        if (response !== undefined) {
            emitter.emit('fetchShipments')
            onSave()
        }
    }

    async function receiverCompanyChanged() {
        if (initializing.value) return
        data.receivingLocationId = null
        if (data.receivingCompanyId === data.sendingCompanyId) {
            receiverLocations.value = companyLocations.value.filter(
                (location) => location.value != data.sendingLocationId,
            )
        } else if (data.receivingCompanyId) {
            receiverLocations.value = await fetchLocations(data.receivingCompanyId)
            data.receivingLocationId = receiverLocations.value[0]?.value || null
        } else {
            receiverLocations.value = []
        }
    }

    function senderLocationChanged() {
        if (data.receivingLocationId === data.sendingLocationId && data.receivingCompanyId) {
            data.receivingLocationId = null
        }
        if (data.receivingCompanyId === data.sendingCompanyId) {
            receiverLocations.value = companyLocations.value.filter(
                (location) => location.value != data.sendingLocationId,
            )
        }
    }

    async function shipperCompanyChanged() {
        if (initializing.value) return
        data.shipperLocationId = null
        if (data.shipperCompanyId) {
            shipperLocations.value = await fetchLocations(data.shipperCompanyId)
            data.shipperLocationId = shipperLocations.value[0]?.value || null
        } else {
            shipperLocations.value = []
        }
    }

    async function haulierCompanyChanged() {
        if (initializing.value) return
        data.haulierLocationId = null
        if (data.haulierCompanyId) {
            haulierLocations.value = await fetchLocations(data.haulierCompanyId)
            data.haulierLocationId = haulierLocations.value[0]?.value || null
        } else {
            haulierLocations.value = []
        }
    }

    function onModalOpen() {
        if (shipmentId.value) {
            fetchShipment()
        }

        if (receivers.value.length === 0) {
            fetchReceivers()
        }

        if (shippers.value.length === 0) {
            fetchShippers()
        }

        if (hauliers.value.length === 0) {
            fetchHauliers()
        }

        if (categories.value.length === 0) {
            fetchCategories()
        }

        reset()
    }
    return {
        data,
        errors,
        loading,
        shipment,
        colliPickerModalOpen,
        receivers,
        shippers,
        receiverLocations,
        shipperLocations,
        hauliers,
        haulierLocations,
        onSubmit,
        onModalOpen,
        removeColli,
        companyLocations,
        companyIsShipper,
        receiverCompanyChanged,
        senderLocationChanged,
        shipperCompanyChanged,
        haulierCompanyChanged,
        categories,
    }
}
