<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

import {
    DashboardWidget,
    EntityCountRecord,
    EntityRecordCountWidgetRef,
    SortDirection,
    WidgetType,
} from '@/types/dashboard'
import { useDashboardStore } from '@/stores/dashboard-store'
import { useAuthStore } from '@/stores/auth-store'
import { PaginatedResponse, uuid } from '@/types/general'
import { datetimeShort } from '@/utils/dates'

import EntityRecordCountWidget from '@/components/dashboard/widgets/EntityRecordCountWidget.vue'
import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'

interface Props {
    widget: DashboardWidget<WidgetType.DamageReportsTransportObjectService>
    preview?: boolean
}

interface Unit {
    id: uuid
    nickname: string
    registrationNumber: string
    service: string
}

interface ExtendedEntityCountRecord extends EntityCountRecord {
    serviceDate: string
    isDue: boolean
}

const props = defineProps<Props>()

const { t } = useI18n()
const dashboardStore = useDashboardStore()
const authStore = useAuthStore()

const recordWidget = ref<EntityRecordCountWidgetRef>()

const sortDirection = computed({
    get: () => props.widget.options.sortDirection ?? SortDirection.asc,
    set: (sortDirection) => dashboardStore.updateWidgetOptions(props.widget, { sortDirection }),
})

async function fetchServiceDates(
    rowsPerPage: number,
    currentPage: number,
): Promise<PaginatedResponse<ExtendedEntityCountRecord>> {
    const { data: response } = await axios.get<PaginatedResponse<Unit>>(
        window.route('dashboards.transport-objects-service', {
            dashboard: dashboardStore.currentDashboard!.id,
            shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
            sortDirection: sortDirection.value,
            page: currentPage,
            'per-page': rowsPerPage,
        }),
    )

    return {
        data: response.data.map((unit) => {
            return {
                id: unit.id,
                title: unit.registrationNumber,
                subtitle: unit.nickname,
                count: dayjs(unit.service).fromNow(),
                isDue: dayjs().isAfter(unit.service),
                serviceDate: unit.service,
            }
        }),
        meta: response.meta,
    }
}

watch(
    () => props.widget.options,
    () => recordWidget.value?.fetchData(),
)
</script>

<template>
    <MyWidget :widget="props.widget" :preview="props.preview">
        <EntityRecordCountWidget
            ref="recordWidget"
            :fetch-data="fetchServiceDates"
            :title="t('upcomingServices')"
            :entity-link="{ route: 'dmr.transport-objects', title: t('vehiclesAndUnits') }"
            hide-entity-image
        >
            <template #count="{ entity }">
                <div class="flex flex-col items-end ml-auto">
                    <span
                        class="text-blue-500 dark:text-blue-400 text-right font-semibold ml-2 text-sm"
                        :class="{
                            'text-red-500 dark:text-red-400': entity.isDue,
                        }"
                        v-text="entity.count"
                    />
                    <span
                        class="text-primary-400 dark:text-primary-300 font-semibold text-sm whitespace-nowrap pl-2"
                        v-text="datetimeShort(entity.serviceDate)"
                    />
                </div>
            </template>
        </EntityRecordCountWidget>
        <template #settings>
            <MyRadioButtonGroup v-model="sortDirection" class="mt-4 mx-auto" :label="t('sortBy')">
                <MyRadioButton :label="t('ascending')" :value="SortDirection.asc" />
                <MyRadioButton :label="t('descending')" :value="SortDirection.desc" />
            </MyRadioButtonGroup>
        </template>
    </MyWidget>
</template>
