import { defineStore } from 'pinia'
import axios from 'axios'

import { ResourceResponse, uuid } from '@/types/general'
import { OngoingTour } from '@/types/dashboard'

export type FetchParametersKey = string

export interface FetchParameters {
    dashboardId: uuid
    hubId?: uuid
    shareToken: string | null
}

interface OngoingTourStoreState {
    tours: Record<FetchParametersKey, OngoingTour[]>
    fetching: Record<FetchParametersKey, boolean>
    highlightedTourId: uuid | null
}

/**
 * Ongoing tours store.
 * Because it's possible to specify different parameters for each widget we can't just keep a single list of ongoing tours
 * Therefore each list of ongoing tours will be keyed by their unique parameters.
 * By doing this we can still share the result between widgets that use the same parameters.
 *
 * We don't necessarily need to worry about cleanup since parameters only change when dashboard is edited
 */
export const useOngoingTourStore = defineStore('OngoingTourStore', {
    state: (): OngoingTourStoreState => ({
        tours: {},
        fetching: {},
        highlightedTourId: null,
    }),

    actions: {
        getFetchParametersKey(parameters: FetchParameters) {
            return `${parameters.dashboardId}-${parameters.hubId}`
        },

        setHighlightedTour(tour: OngoingTour) {
            this.highlightedTourId = tour.id
        },

        resetHighlightedTour() {
            this.highlightedTourId = null
        },

        async fetch(parameters: FetchParameters) {
            const fetchParametersKey = this.getFetchParametersKey(parameters)

            // Avoid fetching if it's already in progress
            if (this.fetching[fetchParametersKey]) {
                return
            }

            this.fetching[fetchParametersKey] = true

            try {
                const response = await axios.get<ResourceResponse<OngoingTour[]>>(
                    window.route('dashboards.dm-ongoing-tour', {
                        dashboard: parameters.dashboardId,
                        shareToken: parameters.shareToken,
                        'hub-id': parameters.hubId,
                    }),
                )
                this.tours[fetchParametersKey] = response.data.data
            } finally {
                this.fetching[fetchParametersKey] = false
            }
        },
    },
})
