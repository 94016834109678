<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { DynamicScrollerRef, FocusEvent } from '@/types/general'
import { DropdownOption, DropdownOptionValue } from '@/types/inputs'
import { wrap } from '@/utils/array'

import MyErrorMessage from './MyErrorMessage.vue'
import MyHelperMessage from './MyHelperMessage.vue'

import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'

export interface Props {
    modelValue: DropdownOptionValue
    options: DropdownOption[]
    placeholder?: string
    searchable?: boolean
    multiple?: boolean
    label?: string
    background?: string
    customClass?: string
    groupClass?: string
    wrapperClass?: string
    hideChevron?: boolean
    border?: string
    inlineSearch?: boolean
    optionsClass?: string
    disabled?: boolean
    // Only used when multiple is true
    // Shows "3 items selected"
    valueAsCount?: boolean
    // Shows an option to clear the value
    clearOption?: string
    clearButton?: boolean
    tabindex?: number
    loading?: boolean
    inAction?: boolean
    name?: string
    error?: string
    helper?: string
    dropdownWidth?: number
    popperContainer?: string
}

const props = withDefaults(defineProps<Props>(), {
    searchable: false,
    multiple: false,
    valueAsCount: false,
    inlineSearch: false,
    disabled: false,
    clearButton: false,
    loading: false,
})

const emit = defineEmits<{
    (e: 'update:modelValue', value: DropdownOptionValue): void
    (e: 'change', value: DropdownOptionValue): void
    (e: 'focus'): void
}>()

const { t } = useI18n()
const selectedValues = ref<(string | number)[]>(
    wrap(hasValue(props.modelValue) ? props.modelValue : []),
)
const scroller = ref<DynamicScrollerRef>()
const inputElement = ref<HTMLInputElement>()
const selectWrapper = ref<HTMLDivElement>()
const inputValue = ref('')
const isFocused = ref(false)
const isOpen = ref(false)
const activeOptionIndex = ref(0)
const minimumDropdownWidth = ref(props.dropdownWidth ?? 200)
const visibleOptions = computed(() => {
    if (!isOpen.value) return props.options

    const search = inputValue.value.toLowerCase()
    let options = props.options.filter((option) => option.label.toLowerCase().includes(search))
    if (props.clearOption) {
        // We'll need to use a string because DynamicScroller does not allow null values
        options = [{ label: props.clearOption, value: 'null' }, ...options]
    }

    return options
})
const selectedValue = computed<string | null>(() => {
    if (selectedValues.value.length === 0) return null

    if (props.multiple && props.valueAsCount) {
        return t('itemsSelected', { count: selectedValues.value.length })
    } else if (props.multiple) {
        return selectedValues.value
            .map((value) => props.options.find((option) => option.value === value)?.label)
            .join(', ')
    }

    const option = props.options.find((option) => option.value === selectedValues.value[0])
    return option?.label || ''
})
const placeholder = computed(() => {
    return props.placeholder || t('selectValue')
})
const defaultClass = computed(() => {
    if (props.inAction) return ['border-transparent bg-transparent']

    return [
        { 'shadow-lg': isFocused.value },
        { 'bg-gray-100 dark:bg-dark-500': props.disabled },
        props.background ?? 'bg-white dark:bg-dark-400',
        props.border ?? 'border-gray-300 dark:border-transparent',
        props.wrapperClass,
        'rounded-xl border py-2 pl-3 pr-1',
    ]
})

function openDropdown() {
    isFocused.value = true
    isOpen.value = true
    minimumDropdownWidth.value = Math.max(
        selectWrapper.value?.offsetWidth || 0,
        props.dropdownWidth ?? 200,
    )
    emit('focus')
}

function closeDropdown(loseFocus = true, e?: FocusEvent) {
    // Clicking the popper background should not close the dropdown
    if (e?.relatedTarget?.classList.contains('v-popper__popper')) {
        e.stopPropagation()
        e.preventDefault()
        inputElement.value?.focus()
        return
    }

    isOpen.value = false
    if (loseFocus) {
        isFocused.value = false
    }

    inputValue.value = ''
}

function closeByEscapeKey(e: KeyboardEvent) {
    if (isOpen.value) {
        isOpen.value = false
        e.preventDefault()
        e.stopPropagation()
    }
}

function focusInput(force = false) {
    if (props.disabled) return

    if (isOpen.value && !force) {
        isOpen.value = false
    } else {
        // This function can be called by a focus event which is fired by before click
        // We wait 1ms to make sure click event is not fired by popper which can close the dropdown again
        setTimeout(() => {
            openDropdown()

            focusInputElement()
        }, 1)
    }
}

function focusInputElement() {
    setTimeout(() => {
        inputElement.value?.focus()
    }, 1)
}

function previousActiveOption() {
    if (!isOpen.value) {
        isOpen.value = true
        return
    }

    activeOptionIndex.value = Math.max(0, activeOptionIndex.value - 1)
    focusActiveOption()
}

function nextActiveOption() {
    if (!isOpen.value) {
        isOpen.value = true
        return
    }

    activeOptionIndex.value = Math.min(props.options.length - 1, activeOptionIndex.value + 1)
    focusActiveOption()
}

function focusActiveOption() {
    scroller.value?.scrollToItem(activeOptionIndex.value)
}

function spacePressed(e: KeyboardEvent) {
    if (!props.searchable) e.preventDefault()

    if (!isOpen.value) {
        isOpen.value = true
    } else if (!props.searchable && visibleOptions.value[activeOptionIndex.value]) {
        selectOption(visibleOptions.value[activeOptionIndex.value])
    }
}

function selectOption(option: DropdownOption) {
    if (props.multiple) {
        if (!hasValue(option.value)) return
        if (selectedValues.value.includes(option.value)) {
            selectedValues.value = selectedValues.value.filter((value) => value !== option.value)
        } else {
            selectedValues.value.push(option.value)
        }
    } else {
        selectedValues.value = hasValue(option.value) ? [option.value] : []
        isOpen.value = false
    }
}

function clearValue() {
    selectedValues.value = []
}

function selectActiveOption() {
    if (!isOpen.value) {
        isOpen.value = true
        return
    }

    if (visibleOptions.value[activeOptionIndex.value]) {
        selectOption(visibleOptions.value[activeOptionIndex.value])
    }
}

function hasValue(value: DropdownOptionValue): value is string | number | (string | number)[] {
    const nullValues: unknown[] = [null, undefined, 'null']

    return !nullValues.includes(value)
}

function focusNextInput() {
    // Due to popper being mounted on the root document we're tab is not able to continue in the selects path
    // It's unfortunely also impossible to simulate a tab key so we'll just focus the wrapper so that the user can tab once again to continue
    selectWrapper.value?.focus()
    //window.dispatchEvent(new KeyboardEvent('keydown', { code: 'Tab', key: 'Tab' }))
}

watch(
    selectedValues,
    (value) => {
        let emittedValue: DropdownOptionValue = value
        if (!props.multiple) {
            emittedValue = hasValue(value) && value.length > 0 ? value[0] : null
        }

        // We'll not emit if it's already the same value
        if (emittedValue !== props.modelValue) {
            emit('update:modelValue', emittedValue)
            emit('change', emittedValue)
        }

        if (!hasValue(value)) return
        if (props.multiple && isOpen.value) return
    },
    { deep: true },
)
watch(
    () => props.modelValue,
    (value) => {
        selectedValues.value = wrap(hasValue(value) ? value : [])
    },
    { deep: true },
)
watch(isOpen, async (open) => {
    if (open) {
        inputValue.value = ''
        activeOptionIndex.value = 0
    } else {
        await nextTick()
    }
})
watch(visibleOptions, () => {
    if (!props.multiple) activeOptionIndex.value = 0
})
</script>
<script lang="ts">
export default { components: { MyErrorMessage, MyHelperMessage }, inheritAttrs: false }
</script>

<template>
    <VDropdown
        :triggers="[]"
        :shown="isOpen"
        :class="props.groupClass"
        class="outline-none"
        :container="popperContainer"
        :overflow-padding="10"
        autohide
        no-auto-focus
        @apply-hide="closeDropdown(false)"
        @apply-show="focusInputElement"
    >
        <slot name="label" :label="label">
            <MyInputLabel
                v-if="props.label"
                class="pl-2"
                @click="focusInput"
                v-text="props.label"
            />
        </slot>

        <div
            ref="selectWrapper"
            class="outline-none"
            :tabindex="props.tabindex ?? (props.disabled || !$slots.default ? -1 : 0)"
            @focus="focusInput()"
            @click="focusInput()"
        >
            <slot :is-focused="isFocused" :is-open="isOpen">
                <div
                    :class="props.customClass ?? defaultClass"
                    class="relative flex w-full cursor-pointer items-center outline-none"
                    @mousedown.prevent=""
                >
                    <slot name="prefixIcon" />

                    <input
                        v-if="!props.inlineSearch"
                        ref="inputElement"
                        v-model="inputValue"
                        :placeholder="selectedValue ?? placeholder"
                        :readonly="!props.searchable || !isOpen"
                        :disabled="props.disabled"
                        v-bind="$attrs"
                        :class="{
                            'font-bold text-primary-400 placeholder:text-primary-400 dark:text-primary-50 dark:placeholder:text-primary-50':
                                props.inAction,
                            'placeholder:text-gray-400': !props.inAction,
                            'placeholder:text-gray-800 dark:placeholder:text-primary-50':
                                !!selectedValue && !isOpen,
                        }"
                        class="w-full cursor-pointer border-none bg-transparent px-0 py-0.5 text-sm outline-none placeholder:text-sm focus:ring-0"
                        @focus="openDropdown"
                        @blur="closeDropdown(true, $event as FocusEvent)"
                        @keydown.up.prevent="previousActiveOption"
                        @keydown.down.prevent="nextActiveOption"
                        @keydown.enter.prevent="selectActiveOption"
                        @keydown.esc="closeByEscapeKey"
                        @keydown.space="spacePressed"
                    />

                    <span v-else v-text="selectedValue ?? placeholder" />

                    <div
                        v-if="props.clearButton"
                        class="h-4 w-4 flex-shrink-0 rounded-full bg-primary-100 p-1 dark:bg-dark-500"
                        :class="{ 'invisible opacity-0': selectedValues.length === 0 }"
                        @click.stop="clearValue"
                    >
                        <mdi:close
                            aria-hidden="true"
                            class="h-full w-full text-primary-400 dark:text-primary-200"
                        />
                    </div>

                    <mdi:loading
                        :class="{ 'opacity-0': !props.loading, 'animate-spin': props.loading }"
                        class="absolute right-1 h-5 w-5 text-gray-400 transition-opacity duration-500"
                    />

                    <mdi:chevron-down
                        v-if="!props.hideChevron"
                        :class="[
                            props.inAction ? 'text-gray-700 dark:text-white' : 'text-gray-400',
                            { 'rotate-180': isOpen, 'opacity-0': props.loading },
                        ]"
                        aria-hidden="true"
                        class="h-5 w-5 flex-shrink-0 transition-all duration-500"
                    />
                </div>
            </slot>
        </div>

        <MyHelperMessage :helper="props.helper" />
        <MyErrorMessage :input-name="props.name" :error="props.error" :label="props.label" />

        <template #popper>
            <!-- mousedown.stop prevent modals from closing when this is clicked -->
            <div
                :class="[props.optionsClass ?? 'w-full']"
                :style="{ width: minimumDropdownWidth + 'px' }"
                class="my-1 max-h-60 space-y-1 rounded-xl p-1 text-base focus:outline-none sm:text-sm"
            >
                <div v-if="props.inlineSearch" class="relative">
                    <input
                        ref="inputElement"
                        v-model="inputValue"
                        :placeholder="selectedValue ?? placeholder"
                        class="flex w-full items-center bg-transparent py-1.5 px-1 text-sm border-none outline-none placeholder:text-sm placeholder:text-gray-400 focus:ring-0 dark:text-white"
                        @keydown.up.prevent="previousActiveOption"
                        @keydown.down.prevent="nextActiveOption"
                        @keydown.enter.prevent="selectActiveOption"
                        @keydown.esc.prevent.stop="isOpen = false"
                        @keydown.space="spacePressed"
                        @keydown.tab.prevent.stop="focusNextInput"
                    />

                    <mdi:loading
                        :class="{ 'opacity-0': !props.loading, 'animate-spin': props.loading }"
                        class="absolute top-0 bottom-0 my-auto right-1 h-5 w-5 text-gray-400 transition-opacity duration-500"
                    />
                </div>

                <DynamicScroller
                    ref="scroller"
                    :items="visibleOptions"
                    :min-item-size="36"
                    key-field="value"
                    :style="{ maxHeight: '200px' }"
                >
                    <template #default="{ item: option, index, active }">
                        <DynamicScrollerItem :item="option" :active="active" :data-index="index">
                            <div
                                class="pb-2"
                                :class="{ 'mb-0': index === visibleOptions.length - 1 }"
                            >
                                <div
                                    :class="{
                                        'bg-black/20':
                                            selectedValues.includes(option.value) &&
                                            activeOptionIndex !== index,
                                        'bg-primary-100 text-primary-900 dark:bg-dark-300 dark:text-primary-50':
                                            activeOptionIndex === index,
                                        'text-gray-900 dark:text-primary-50':
                                            activeOptionIndex !== index,
                                    }"
                                    class="option relative flex cursor-pointer select-none items-center rounded-lg py-2 px-3 hover:bg-gray-100 focus:bg-dark-300 dark:hover:bg-dark-300"
                                    @click.prevent="selectOption(option)"
                                    @mousedown.prevent=""
                                >
                                    <slot
                                        :active="activeOptionIndex === index"
                                        :option="option"
                                        :selected="selectedValues.includes(option.value)"
                                        name="option"
                                    >
                                        <span
                                            :class="
                                                activeOptionIndex === index
                                                    ? 'font-semibold'
                                                    : 'font-normal'
                                            "
                                            class="block truncate"
                                            v-text="option.label"
                                        />

                                        <span
                                            v-if="selectedValues.includes(option.value)"
                                            class="absolute inset-y-0 right-0 flex items-center pr-3 dark:text-primary-200 text-primary-600"
                                        >
                                            <mdi:check aria-hidden="true" class="h-5 w-5" />
                                        </span>
                                    </slot>
                                </div>
                            </div>
                        </DynamicScrollerItem>
                    </template>
                </DynamicScroller>

                <div
                    v-if="visibleOptions.length === 0"
                    class="py-2 px-3 dark:text-white"
                    v-text="t('noOptionsAvailable')"
                />
            </div>
        </template>
    </VDropdown>
</template>
