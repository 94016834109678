import { computed, onMounted } from 'vue'
import axios from 'axios'
import { useIntervalFn } from '@vueuse/core'

import { DashboardWidget, WidgetType, OngoingTour } from '@/types/dashboard'
import { MinimalResource } from '@/types/general'
import { FetchParameters, useOngoingTourStore } from '@/stores/ongoing-tour-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { DropdownStringOption } from '@/types/inputs'
import { useAuthStore } from '@/stores/auth-store'

type ongoingTourWidget = DashboardWidget<
    WidgetType.DeliveryManagementOngoingTourList | WidgetType.DeliveryManagementOngoingTourMap
>

/**
 * Fetches all necessary data and options for an ongoing tour widget
 */
export function useOngoingTourWidgetData(widget: ongoingTourWidget) {
    const authStore = useAuthStore()
    const dashboardStore = useDashboardStore()
    const ongoingTourStore = useOngoingTourStore()

    const hubs = useLazyLoadedList<DropdownStringOption>(fetchHubs)

    const hubId = computed({
        get: () => widget.options.hubId ?? null,
        set: (hubId) => dashboardStore.updateWidgetOptions(widget, { hubId: hubId ?? undefined }),
    })
    const fetchParameters = computed<FetchParameters>(() => {
        return {
            dashboardId: dashboardStore.currentDashboard?.id ?? '',
            shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
            hubId: hubId.value ?? undefined,
        }
    })
    const tours = computed<OngoingTour[]>(() => {
        const fetchParameterKey = ongoingTourStore.getFetchParametersKey(fetchParameters.value)

        return ongoingTourStore.tours[fetchParameterKey] ?? []
    })
    const loading = computed(() => {
        const fetchParameterKey = ongoingTourStore.getFetchParametersKey(fetchParameters.value)

        return ongoingTourStore.fetching[fetchParameterKey] ?? false
    })

    async function fetchHubs(): Promise<DropdownStringOption[]> {
        const response = await axios.get<MinimalResource[]>(
            window.route('minimal.companies.hubs', { company: authStore.companyId }),
        )

        return response.data.map((hub) => ({
            value: hub.id,
            label: hub.name,
        }))
    }

    async function fetchOngoingTours() {
        ongoingTourStore.fetch(fetchParameters.value)
    }

    // We'll update every minute here instead of following the dashboard's refresh interval.
    // This is because it uses "live" data
    useIntervalFn(fetchOngoingTours, 1_000 * 60)

    onMounted(() => fetchOngoingTours())

    return { tours, loading, hubs, hubId, fetchOngoingTours }
}
