<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import ShipmentsTable from '@/components/delivery-management/ShipmentsTable.vue'
import ManageShipmentModal from '@/components/delivery-management/ManageShipmentModal.vue'
import BasicExportModal from '@/components/import-export/BasicExportModal.vue'

const route = useRoute()
const { t } = useI18n()
const authStore = useAuthStore()
const shipmentModalOpen = ref(false)

const exportEndpoint = computed(() => {
    return window.route('dm.company.shipments.export', {
        company: authStore.companyId,
        ...route.query,
    })
})
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'dm' }" v-text="t('deliveryManagement')" />
        <Breadcrumb current v-text="t('shipments')" />

        <template #actions>
            <BasicExportModal
                v-if="authStore.hasLicense(LicenseType.IsolatedShipmentScanning)"
                :title="t('exportModel', { model: t('shipments') })"
                :endpoint="exportEndpoint"
            />

            <MyButton
                v-if="!authStore.isReadonly.deliveryManagement"
                plain
                scheme="primary"
                size="small"
                @click="shipmentModalOpen = true"
            >
                <mdi:plus-thick class="mr-1" />
                {{ t('addShipment') }}
            </MyButton>
        </template>
    </CrumbsAndActions>

    <RouterView />

    <MyPanel v-if="authStore.companyId" shadow>
        <ShipmentsTable />
    </MyPanel>

    <ManageShipmentModal v-model="shipmentModalOpen" @close="shipmentModalOpen = false" />
</template>
