<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { uuid } from '@/types/general'

import Importer from '@/components/import-export/Importer.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'

export interface Props {
    title: string
    buttonText?: string
    endpoint: string
    template?: string
    companyId?: uuid
}

const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'import-finished'): void }>()

const { t } = useI18n()

const open = ref(false)
</script>

<template>
    <MyModal v-model="open">
        <template #title>{{ props.title }}</template>

        <div class="mt-2">
            <Importer
                v-if="open"
                :endpoint="endpoint"
                :template="template"
                :company-id="props.companyId"
                @import-finished="emit('import-finished')"
            />
        </div>
    </MyModal>

    <slot>
        <MyButton scheme="action" size="small" @click="open = true">
            <mdi:export class="mr-1" />
            {{ buttonText ?? t('import') }}
        </MyButton>
    </slot>
</template>
