<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import dayjs from 'dayjs'
import axios from 'axios'

import { useDashboardStore } from '@/stores/dashboard-store'
import {
    DashboardWidget,
    EntityCountRecord,
    EntityRecordCountWidgetRef,
    WidgetType,
} from '@/types/dashboard'
import { useAuthStore } from '@/stores/auth-store'
import { DropdownOption } from '@/types/inputs'
import { PaginatedResponse } from '@/types/general'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { ReceiptType } from '@/types/pod'

import EntityRecordCountWidget from '@/components/dashboard/widgets/EntityRecordCountWidget.vue'
import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import TimePeriodOption from '@/components/dashboard/widget-settings/TimePeriodOption.vue'

interface Props {
    widget: DashboardWidget<WidgetType.PodEmployeeReceipts>
    preview?: boolean
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const dashboardStore = useDashboardStore()

const recordWidget = ref<EntityRecordCountWidgetRef>()

const sortBy = computed({
    get: () => props.widget.options.sortBy ?? '-count',
    set: (sortBy) => dashboardStore.updateWidgetOptions(props.widget, { sortBy }),
})
const timePeriod = computed({
    get: () => props.widget.options.timePeriod ?? 7,
    set: (timePeriod) => dashboardStore.updateWidgetOptions(props.widget, { timePeriod }),
})
const receiptType = computed({
    get: () => props.widget.options.receiptType ?? null,
    set: (receiptType) =>
        dashboardStore.updateWidgetOptions(props.widget, { receiptType: receiptType ?? undefined }),
})
const receiptOptions = computed<DropdownOption[]>(() => [
    { label: t('dispatch'), value: ReceiptType.Dispatch },
    { label: t('delivery'), value: ReceiptType.Delivery },
])
const sortOptions = computed<DropdownOption[]>(() => [
    { label: t('entityDescending', { entity: t('pod') }), value: '-count' },
    { label: t('entityAscending', { entity: t('pod') }), value: 'count' },
    { label: t('name'), value: 'name' },
])

async function fetchEmployeePods(
    rowsPerPage: number,
    currentPage: number,
): Promise<PaginatedResponse<EntityCountRecord>> {
    const { data: response } = await axios.get<PaginatedResponse<EntityCountRecord>>(
        window.route('dashboards.employee-pod-count', {
            dashboard: dashboardStore.currentDashboard!.id,
            shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
            page: currentPage,
            'per-page': rowsPerPage,
            sort: sortBy.value,
            'start-date': dayjs().subtract(timePeriod.value, 'days').toISOString(),
            'receipt-type': receiptType.value,
        }),
    )

    return {
        data: response.data,
        meta: response.meta,
    }
}
watch(
    () => props.widget.options,
    () => recordWidget.value?.fetchData(),
)

useMittListener('fetchWidgetData', () => recordWidget.value?.fetchData())
</script>

<template>
    <MyWidget :widget="props.widget">
        <EntityRecordCountWidget
            ref="recordWidget"
            :entity-link="{ route: 'pod', title: t('podReceipts') }"
            :title="t('employeeEntity', { entity: t('podReceipts') })"
            :fetch-data="fetchEmployeePods"
        />
        <template #settings>
            <MySelect
                v-model="receiptType"
                :options="receiptOptions"
                group-class="mt-4 mx-auto"
                :label="t('receiptType')"
                :clear-option="t('all')"
                :placeholder="t('all')"
            />
            <MySelect
                v-model="sortBy"
                :options="sortOptions"
                group-class="mt-4 mx-auto"
                :label="t('sortBy')"
            />

            <TimePeriodOption v-model="timePeriod" />
        </template>
    </MyWidget>
</template>
