<script lang="ts" setup generic="T extends EntityCountRecord">
import { useI18n } from 'vue-i18n'
import { ref, Ref } from 'vue'

import { image } from '@/utils/assets'
import { useAuthStore } from '@/stores/auth-store'
import { moduleLicenses } from '@/utils/licenses'
import { PaginatedResponse, PaginationData, PermissionType } from '@/types/general'
import { useCalculatedRowCount } from '@/hooks/use-calculated-row-count'
import { EntityCountRecord, EntityRecordCountWidgetRef, EntityLink } from '@/types/dashboard'
import { useIsPublicDashboardView } from '@/hooks/use-is-public-dashboard-view'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import PaginationFooter from '@/components/PaginationFooter.vue'

interface Props {
    fetchData: (rowsPerPage: number, currentPage: number) => Promise<PaginatedResponse<T>>
    title: string
    entityLink?: EntityLink
    hideEntityImage?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    hideEntityImage: false,
})

// Vue's automatic type for this is incorrect so we have to force it as a Ref to avoid automatic unwrapping nested refs
const fetchedData = ref<T[]>([]) as Ref<T[]>
const currentPage = ref(1)
const pagination = ref<PaginationData>()
const loading = ref(false)

const employeesElement = ref<HTMLDivElement>()
const rowsPerPage = useCalculatedRowCount(employeesElement, {
    rowHeight: 44,
    rowSpacing: 16,
    onChange: () => changePage(1),
})
const isPublicView = useIsPublicDashboardView()
const authStore = useAuthStore()
const { t } = useI18n()

async function refreshData() {
    if (!rowsPerPage.value) return
    if (loading.value) return

    loading.value = true
    const response = await props.fetchData(rowsPerPage.value, currentPage.value)
    fetchedData.value = response.data
    pagination.value = {
        from: response.meta.from,
        to: response.meta.to,
        perPage: response.meta.per_page,
        lastPage: response.meta.last_page,
        currentPage: response.meta.current_page,
        total: response.meta.total,
    }
    loading.value = false
}

async function changePage(page: number): Promise<void> {
    currentPage.value = page
    return refreshData()
}

defineExpose<EntityRecordCountWidgetRef>({ fetchData: refreshData })
</script>

<template>
    <div class="flex flex-col h-full p-6 overflow-y-auto">
        <h3
            class="relative font-bold uppercase tracking-wide text-primary-400 dark:text-primary-300 flex-shrink-0"
            v-text="props.title"
        />

        <LoaderWrapper v-if="loading" :visible="loading" />
        <section class="h-full mt-4 overflow-y-auto flex flex-col">
            <div ref="employeesElement" class="h-full space-y-4">
                <article
                    v-for="entity in fetchedData"
                    :key="'employee-' + entity.id"
                    class="flex items-center w-full h-[44px]"
                >
                    <div
                        v-if="!props.hideEntityImage"
                        class="flex h-full aspect-square items-center justify-center rounded-full bg-primary-300 mr-3"
                    >
                        <img
                            v-if="entity.image"
                            :src="image(entity.image)"
                            :alt="entity.title"
                            class="object-cover h-full rounded-full"
                        />
                        <span v-else class="text-primary-100"><mdi:account /></span>
                    </div>
                    <div class="truncate">
                        <p class="font-semibold text-primary-400" v-text="entity.title" />
                        <p
                            class="text-xs font-semibold text-gray-500 truncate"
                            v-text="entity.subtitle"
                        />
                    </div>
                    <slot name="count" :entity="entity">
                        <p
                            class="ml-auto text-center text-2xl font-bold text-primary-400 dark:text-primary-300"
                            v-text="entity.count"
                        />
                    </slot>
                </article>
            </div>

            <PaginationFooter
                v-if="!isPublicView"
                simple
                class="border-t border-primary-200 dark:border-dark-500 pt-2"
                hide-per-page
                :pagination-data="pagination"
                :refetch="(params) => changePage(params?.page ?? 1)"
                :row-count="rowsPerPage ?? 1"
            >
                <template #perPage>
                    <RouterLink
                        v-if="
                            authStore.hasLicense(moduleLicenses) &&
                            authStore.hasPermission(PermissionType.ManageCompany) &&
                            props.entityLink
                        "
                        class="text-sm font-semibold text-blue-500 underline decoration-2 underline-offset-2"
                        :to="{ name: props.entityLink.route }"
                        v-text="t('goTo', { to: props.entityLink.title })"
                    />
                </template>
            </PaginationFooter>
        </section>
    </div>
</template>
