<script lang="ts" setup>
import { ref, watch } from 'vue'

import { TransactionProduct } from '@/types/transaction'
import { image } from '@/utils/assets'

const props = defineProps<{ modelValue: TransactionProduct }>()
const delivered = ref(props.modelValue.delivered ?? 0)
const received = ref(props.modelValue.received ?? 0)

const emit = defineEmits<{ (e: 'update:modelValue', value: TransactionProduct): void }>()

function autofocus($event: FocusEvent) {
    ;($event.target as HTMLInputElement).select()
}

watch([received, delivered], () => {
    if (received.value < 0) received.value = 0
    if (delivered.value < 0) delivered.value = 0

    emit('update:modelValue', {
        ...props.modelValue,
        received: received.value,
        delivered: delivered.value,
    })
})
</script>

<template>
    <div
        class="mb-1 overflow-hidden first:rounded-t-xl last:rounded-b-xl grid grid-cols-[100px_1fr_100px_100px] items-center bg-primary-50 dark:bg-dark-500"
    >
        <div class="h-full w-[100px] bg-primary-200 dark:bg-dark-700">
            <img
                :src="image(props.modelValue.image, 'thumbnail')"
                :alt="props.modelValue.name"
                class="h-[56px]"
            />
        </div>
        <h2 class="px-2.5 text-sm font-semibold" v-text="props.modelValue.name" />
        <div
            class="flex h-full items-center justify-between p-3 text-sm transition-colors duration-700"
            :class="{ 'bg-green-500/20': delivered > 0 }"
        >
            <input
                v-model="delivered"
                autocomplete="off"
                class="m-0 mx-auto w-10 appearance-none border-none bg-transparent p-1 text-center text-sm font-semibold outline-none ring-0 transition focus:ring-0 dark:focus:bg-dark-400"
                type="number"
                min="0"
                @focus="autofocus"
            />
            <div class="flex h-full flex-col overflow-hidden rounded-lg">
                <span
                    class="grow cursor-pointer select-none hover:bg-primary-200 dark:hover:bg-dark-500"
                    @click="delivered++"
                >
                    <mdi:chevron-up />
                </span>
                <span
                    class="grow cursor-pointer select-none hover:bg-primary-200 dark:hover:bg-dark-500"
                    @click="delivered--"
                >
                    <mdi:chevron-down />
                </span>
            </div>
        </div>
        <div
            class="flex h-full items-center justify-between p-3 text-sm transition-colors duration-700"
            :class="{ 'bg-green-500/20': received > 0 }"
        >
            <input
                v-model="received"
                autocomplete="off"
                class="m-0 mx-auto w-10 appearance-none border-none bg-transparent p-1 text-center text-sm font-semibold outline-none ring-0 transition focus:ring-0 dark:focus:bg-dark-400"
                type="number"
                min="0"
                @focus="autofocus"
            />
            <div
                class="flex h-full flex-col overflow-hidden rounded-lg bg-primary-50 dark:bg-dark-400"
            >
                <span
                    class="grow cursor-pointer select-none hover:bg-primary-200 dark:hover:bg-dark-500"
                    @click="received++"
                >
                    <mdi:chevron-up />
                </span>
                <span
                    class="grow cursor-pointer select-none hover:bg-primary-200 dark:hover:bg-dark-500"
                    @click="received--"
                >
                    <mdi:chevron-down />
                </span>
            </div>
        </div>
    </div>
</template>
