import { debouncedWatch, useResizeObserver } from '@vueuse/core'
import { Ref, ref } from 'vue'

interface Options {
    rowHeight: number
    rowSpacing?: number
    headerSpacing?: number
    onChange?: () => void
}

export function useCalculatedRowCount(
    element: Ref<HTMLElement | undefined>,
    options: Options,
): Ref<number | undefined> {
    const rowsPerPage = ref<number | undefined>(undefined)
    const debounceInterval = ref(0)
    let initialLoad = true

    useResizeObserver(element, () => {
        if (element.value) {
            const rowSpacing = options?.rowSpacing ?? 0
            let height = element.value.clientHeight
            height -= options?.headerSpacing ?? 0
            rowsPerPage.value = Math.max(1, Math.floor(height / (options.rowHeight + rowSpacing)))
        }
    })

    if (options?.onChange) {
        debouncedWatch(
            rowsPerPage,
            () => {
                options.onChange?.()
                if (initialLoad) {
                    initialLoad = false
                    debounceInterval.value = 1000
                }
            },
            { debounce: debounceInterval },
        )
    }

    return rowsPerPage
}
