<script lang="ts">
interface MinimalAlarm
    extends Pick<Alarm, 'id' | 'comment' | 'ticketNumber' | 'mutedAt' | 'resolvedAt'> {}

interface Props {
    modelValue: boolean
    alarm: MinimalAlarm
    alarmAction: 'resolve' | 'mute'
}
</script>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { watch } from 'vue'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { emitter } from '@/utils/mitt'
import { Alarm } from '@/types/alarm'

import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'saved'): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()
const form = useForm({ comment: props.alarm.comment, ticketNumber: props.alarm.ticketNumber })

async function onSubmit() {
    const data: Record<string, unknown> = form.data
    if (props.alarmAction === 'resolve') {
        data['resolve'] = props.alarm.resolvedAt === null
    } else {
        data['mute'] = props.alarm.mutedAt === null
    }

    try {
        const updateStateResponse = await form.submit(
            'PUT',
            window.route('company.alarms.' + props.alarmAction, {
                company: authStore.companyId,
                alarm: props.alarm.id,
            }),
        )

        if (!updateStateResponse) return
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 409) {
            notify({ type: 'error', text: t('alarmAlreadyInDesiredState') })
        }
    }

    emit('update:modelValue', false)
    emit('saved')
    emitter.emit('fetchAlarms')
}

watch(
    () => props.modelValue,
    () =>
        form.reset({
            comment: props.alarm.comment ?? '',
            ticketNumber: props.alarm.ticketNumber ?? '',
        }),
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>
            <template v-if="props.alarmAction === 'resolve'">
                {{ !props.alarm.resolvedAt ? t('resolveAlarm') : t('unresolveAlarm') }}
            </template>
            <template v-if="props.alarmAction === 'mute'">
                {{ !props.alarm.mutedAt ? t('muteAlarm') : t('unmuteAlarm') }}
            </template>
        </template>

        <LoaderWrapper :visible="form.loading.value" />

        <MyForm class="space-y-4" @submit.prevent="onSubmit">
            <MyTextarea v-model="form.data.comment" :label="t('comment')" autofocus />
            <MyInput v-model="form.data.ticketNumber" :label="t('ticketNumber')" />

            <div class="flex justify-end space-x-3">
                <MyButton type="button" @click="emit('update:modelValue', false)">
                    {{ t('cancel') }}
                </MyButton>

                <MyButton :disabled="form.loading.value" scheme="primary">
                    <span v-if="props.alarmAction === 'resolve'">
                        {{ props.alarm.resolvedAt === null ? t('resolve') : t('unresolve') }}
                    </span>

                    <span v-else>
                        {{ props.alarm.mutedAt === null ? t('mute') : t('unmute') }}
                    </span>
                </MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
