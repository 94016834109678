<script lang="ts">
interface Props {
    customerCompanyId?: uuid
    senderLocationId?: uuid
    receiverLocationId?: uuid
    balanceLimits?: CustomerBalanceLimit[]
}
</script>

<script lang="ts" setup>
import axios from 'axios'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { useProductStore } from '@/stores/product-store'
import { PermissionType, ResourceResponse, uuid } from '@/types/general'
import { ProductBalanceRow, Product, CustomerBalanceLimit } from '@/types/packaging'
import { image } from '@/utils/assets'
import { newMoneyFormatter, numberFormatter } from '@/utils/numbers'
import { useCompanyStore } from '@/stores/company-store'
import { DropdownOption } from '@/types/inputs'

import SettleBalanceModal from '@/components/packaging/balance/SettleBalanceModal.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyButton from '@/components/my-components/MyButton.vue'

interface VisibleBalanceRow extends Record<string, unknown> {
    productId: string
    productName?: string
    productImage?: string
    balance: number
    balancePrice: number
    limitReachedAt: string | null
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const productStore = useProductStore()
const companyStore = useCompanyStore()

let moneyFormatter = newMoneyFormatter(authStore.company.options.currency || 'USD')

const loading = ref(false)
const modalOpen = ref(false)
const selectedProduct = ref<Product>()
const balanceSettlement = ref(0)
const balance = ref<ProductBalanceRow[]>([])
const locationId = ref<uuid | null>(null)

const locations = computed(() => {
    let locations: DropdownOption[] = companyStore.locations.map(({ id, name }) => ({
        label: name,
        value: id,
    }))
    if (locationId.value) {
        locations = [{ value: 'null', label: t('clearFilter') }, ...locations]
    }

    return locations
})
const locationLabel = computed(() => {
    return locationId.value
        ? locations.value.find((o) => o.value == locationId.value)?.label
        : t('location')
})
const visibleBalance = computed<VisibleBalanceRow[]>(() => {
    return balance.value.map((balance): VisibleBalanceRow => {
        const product = productStore.findProduct(balance.productId)
        const companyProduct = productStore.findCompanyProduct(balance.productId)
        const balanceLimit = props.balanceLimits?.find(
            (limit) => limit.productId === balance.productId,
        )

        return {
            ...balance,
            productName: product?.name,
            productImage: product?.image,
            balancePrice: balance.balance * (companyProduct?.price ?? 0),
            limitReachedAt: balanceLimit?.reachedAt ?? null,
        }
    })
})

async function fetch() {
    loading.value = true

    await Promise.all([fetchBalance(), productStore.fetchProducts()])

    loading.value = false
}

async function fetchBalance() {
    const params: Record<string, string | undefined> = {
        customerCompanyId: props.customerCompanyId,
        locationId: locationId.value ?? props.senderLocationId,
        customerLocationId: props.receiverLocationId,
    }
    const response = await axios.get<ResourceResponse<ProductBalanceRow[]>>(
        window.route('packaging.company.balance', { company: authStore.companyId }),
        { params: params },
    )

    balance.value = response.data.data
}

function openSettleBalanceModal(row: ProductBalanceRow) {
    selectedProduct.value = productStore.findProduct(row.productId)!
    balanceSettlement.value = row.balance
    modalOpen.value = true
}

async function settleModalClosed(balanceUpdated: boolean) {
    selectedProduct.value = undefined
    if (balanceUpdated) await fetch()
}

onBeforeMount(() => {
    fetch()
    moneyFormatter = newMoneyFormatter(authStore.company.options.currency || 'USD')
})
watch(
    () => [
        props.customerCompanyId,
        props.receiverLocationId,
        props.senderLocationId,
        locationId.value,
    ],
    fetchBalance,
)
</script>

<template>
    <div>
        <SettleBalanceModal
            v-if="props.customerCompanyId"
            :product="selectedProduct"
            @closed="settleModalClosed"
        />

        <MyTable
            :loading="loading"
            :rows="visibleBalance"
            disable-search
            disable-sorting
            disable-ordering
            disable-footer
            disable-actions
            :row-height="60"
            class="max-h-[496px] overflow-y-auto"
            :table-id="props.customerCompanyId ? 'packaging-balance' : 'location-balance'"
        >
            <template #filters>
                <MySelect
                    v-model="locationId"
                    :options="locations"
                    custom-class="uppercase font-semibold text-gray-500 text-xs"
                    hide-chevron
                    inline-search
                    v-bind="$attrs"
                >
                    <MyButton
                        :active="!!locationId"
                        plain
                        scheme="light"
                        size="small"
                        v-text="locationLabel"
                    />

                    <template #option="slotProps">
                        <slot name="option" v-bind="slotProps" />
                    </template>
                </MySelect>
            </template>

            <MyTableColumn :min-width="200" :name="t('product')" property="productId" />
            <MyTableColumn :width="130" :name="t('balance')" property="balance" />

            <template #productId="{ row }">
                <div class="flex items-center">
                    <img
                        v-if="row.productImage"
                        class="mr-3 h-7"
                        :src="image(row.productImage as string)"
                        :alt="row.productName as string"
                    />
                    {{ row.productName }}
                </div>
            </template>

            <template #balance="{ row }">
                <div class="flex items-center">
                    <mdi:alarm
                        v-if="row.limitReachedAt !== null"
                        v-tooltip="t('customerReachedBalanceLimit')"
                        class="mr-2 text-red-500"
                    />

                    <div
                        v-tooltip="{
                            content: t(
                                (row.balance as number) < 0
                                    ? 'productsOwedExplanation'
                                    : 'productsCreditExplanation',
                            ),
                            placement: 'bottom',
                        }"
                        class="truncate"
                    >
                        {{ numberFormatter.format(row.balance as number) }}
                        ({{ moneyFormatter.format(row.balancePrice as number) }})
                    </div>
                </div>
            </template>

            <template
                v-if="
                    props.customerCompanyId &&
                    !authStore.isReadonly.packaging &&
                    authStore.hasPermission(PermissionType.ResetCustomerBalance)
                "
                #actionRow="{ row }"
            >
                <ActionRowItem @click="openSettleBalanceModal(row as unknown as ProductBalanceRow)">
                    <mdi:scale-balance />
                </ActionRowItem>
            </template>
        </MyTable>
    </div>
</template>
