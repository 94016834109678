<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref } from 'vue'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { PaginatedResponse, PermissionType, SuccessResponse } from '@/types/general'
import { Alarm } from '@/types/alarm'
import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { useAlarmStore } from '@/stores/alarm-store'
import { datetime } from '@/utils/dates'
import { incidentTypes } from '@/utils/damage-report'
import {
    isCustomerBalanceLimitAlarm,
    isIncidentAlarm,
    isTireThreadAlarm,
    isDeviationCreatedAlarm,
    isTicketCreatedAlarm,
    isTicketUpdatedAlarm,
    alarmColors,
    alarmTranslations,
    isIncorrectTransportObjectAlarm,
    isChecklistFieldAlarm,
} from '@/utils/alarm'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { deviationStates } from '@/utils/delivery-management'
import { TransportObjectType } from '@/types/transport-object'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import MyFilterButton from '@/components/table/MyFilterButton.vue'
import MyTabs from '@/components/my-components/MyTabs.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import FixIncidentModal from '@/components/damage-reports/FixIncidentModal.vue'
import UpdateAlarmStateModal from '@/components/alarms/UpdateAlarmStateModal.vue'
import ActionRowDivider from '@/components/table/ActionRowDivider.vue'
import ReportModal from '@/components/damage-reports/ReportModal.vue'
import TicketModal from '@/components/delivery-management/TicketModal.vue'
import DeviationModal from '@/components/delivery-management/DeviationModal.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import UpdateAlarmTicketNumberModal from '@/components/alarms/UpdateAlarmTicketNumberModal.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const alarmStore = useAlarmStore()
const damageReportModalOpen = ref(false)
const ticketModalOpen = ref(false)
const deviationModalOpen = ref(false)
const fixIncidentModalOpen = ref(false)
const alarmStateModalOpen = ref(false)
const alarmStateTicketOpen = ref(false)
const selectedAlarm = ref<Alarm>()
const alarmAction = ref<'resolve' | 'mute'>('resolve')

interface Params extends Record<string, unknown> {
    filter: { 'license-id': string | null; state: string | null }
}

let defaultLicense: LicenseType | null = null
if (route.name?.toString().includes('dmr')) {
    defaultLicense = LicenseType.DamageReportModule
} else if (route.name?.toString().includes('dm.')) {
    defaultLicense = LicenseType.DeliveryManagementModule
}

const table = usePaginatedTable<Alarm, Params>(
    async (params, abortController) => {
        const response = await axios.get<PaginatedResponse<Alarm>>(
            window.route('company.alarms.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    { filter: { 'license-id': defaultLicense?.toString() ?? null, state: '0' } },
)

const isDamageReports = computed(
    () => table.params.value.filter['license-id'] === LicenseType.DamageReportModule.toString(),
)

const isDeliveryManagement = computed(
    () =>
        table.params.value.filter['license-id'] === LicenseType.DeliveryManagementModule.toString(),
)

const deviationId = computed(() => {
    if (!selectedAlarm.value) return

    return isIncorrectTransportObjectAlarm(selectedAlarm.value)
        ? selectedAlarm.value.description.deviationId
        : selectedAlarm.value.alarmableId
})

const tabs = computed<Record<string, string>>(() => {
    const tabs: Record<string, string> = {}

    if (authStore.hasLicense(LicenseType.PackagingModule)) {
        tabs[LicenseType.PackagingModule.toString()] = t('packaging')
    }
    if (authStore.hasLicense(LicenseType.DamageReportModule)) {
        tabs[LicenseType.DamageReportModule.toString()] = t('damageReports')
    }
    if (authStore.hasLicense(LicenseType.DeliveryManagementModule)) {
        tabs[LicenseType.DeliveryManagementModule.toString()] = t('deliveryManagement')
    }

    return tabs
})
const alarmCountsByTabKey = computed<Record<string, number>>(() => {
    const counts: Record<string, number> = {}
    for (const key of Object.keys(alarmStore.counts.byLicense)) {
        counts[key] = alarmStore.counts.byLicense[parseInt(key, 10)]
    }

    return counts
})

const transportTypes = computed(() => [
    { value: TransportObjectType.Vehicle.toString(), label: t('vehicle') },
    { value: TransportObjectType.Unit.toString(), label: t('unit') },
])

function setFilter(filterName: string, value: string | number | null) {
    table.params.value.filter[filterName] = value
    table.params.value.page = 1 // Reset to the first page whenever a filter is changed
}

function setLicense(value: string | number | null) {
    table.params.value.filter['license-id'] = value as string | null
    table.params.value.filter['state'] = '0'
    table.params.value.page = 1
    // Remove the 'transport-type' filter if the selected tab is not Damage Report
    if (value?.toString() !== LicenseType.DamageReportModule.toString()) {
        delete table.params.value.filter['transport-type']
    }
}

function setState(value: string | number | null) {
    setFilter('state', value)
}

function setTransportType(value: string | number | null) {
    setFilter('transport-type', value)
}

function getAlarmDescription(alarm: Alarm): string {
    if (isCustomerBalanceLimitAlarm(alarm)) {
        let description = t('customerNameReachedBalanceLimit', { customer: alarm.company.name })
        if (alarm.productName) {
            const forProduct = t('forProduct', { product: alarm.productName })
            // Lowercase the first letter
            description += ` ${forProduct[0].toLowerCase()}${forProduct.slice(1)}`
        }

        return description
    } else if (isIncidentAlarm(alarm)) {
        return t('newIncidentInSection', {
            incidentType: t(incidentTypes[alarm.incidentType ?? 0]),
            sectionNumber: alarm.masterTemplateSectionName,
        })
    } else if (isTireThreadAlarm(alarm)) {
        return t('tireThreadBelowThreshold', { threshold: alarm.description.tireThreadThreshold })
    } else if (isChecklistFieldAlarm(alarm)) {
        return t('deviationForChecklistField', { field: alarm.checklistFieldName })
    } else if (isIncorrectTransportObjectAlarm(alarm)) {
        return t('incorrectTransportObject')
    } else if (isDeviationCreatedAlarm(alarm)) {
        return t('deviationCreated')
    } else if (isTicketCreatedAlarm(alarm)) {
        return t('ticketCreated')
    } else if (isTicketUpdatedAlarm(alarm)) {
        if (alarm.description.initialState !== alarm.description.newState) {
            return t('ticketUpdatedState', {
                from: t(deviationStates[alarm.description.initialState ?? 0]),
                to: t(deviationStates[alarm.description.newState ?? 0]),
            })
        } else {
            return t('ticketUpdated')
        }
    }

    return ''
}

function isResolvable(alarm: Alarm): boolean {
    if (isChecklistFieldAlarm(alarm)) return true
    if (isTireThreadAlarm(alarm)) return true

    return false
}

function showDamageReportModal(alarm: Alarm) {
    selectedAlarm.value = alarm
    damageReportModalOpen.value = true
}

function showTicketModal(alarm: Alarm) {
    selectedAlarm.value = alarm
    ticketModalOpen.value = true
}

function showDeviationModal(alarm: Alarm) {
    selectedAlarm.value = alarm
    deviationModalOpen.value = true
}

function fixIncident(alarm: Alarm) {
    selectedAlarm.value = alarm
    fixIncidentModalOpen.value = true
}

function resolveAlarm(alarm: Alarm) {
    alarmAction.value = 'resolve'
    selectedAlarm.value = alarm
    alarmStateModalOpen.value = true
}

function muteAlarm(alarm: Alarm) {
    alarmAction.value = 'mute'
    selectedAlarm.value = alarm
    alarmStateModalOpen.value = true
}

async function assignUserToAlarm(alarm: Alarm) {
    const userId = alarm.assignedUser?.id

    const response = await axios.put<SuccessResponse>(
        window.route('company.alarms.assign-user', {
            company: authStore.companyId,
            alarm,
        }),
        {
            assignedUserId: userId ? null : authStore.user?.id,
        },
    )

    if (!response) return

    table.refetch()
}

function updateTicketNumber(alarm: Alarm) {
    selectedAlarm.value = alarm
    alarmStateTicketOpen.value = true
}

useMittListener('fetchAlarms', () => table.refetch())
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb current v-text="t('alarms')" />
    </CrumbsAndActions>

    <MyTabs
        :default-tab="defaultLicense?.toString()"
        :tabs="tabs"
        hide-single-tab
        shadow
        @change="setLicense($event)"
    >
        <template #tabHeader="{ tabKey, tabName }">
            {{ tabName }}

            <span
                v-if="alarmCountsByTabKey[tabKey] > 0"
                class="absolute -mt-2 -ml-0.5 inline-flex h-5 min-w-[20px] items-center justify-center rounded-full bg-red-500 px-1 text-center text-xs text-white"
                v-text="alarmCountsByTabKey[tabKey]"
            />
        </template>

        <MyTable
            :error="table.error.value"
            :get-data="table.refetch"
            :loading="table.loading.value"
            :pagination-data="table.paginationData.value"
            :rows="table.data.value"
            :table-id="`alarms-${table.params.value.filter['license-id']}`"
            :entity-name="t('alarms')"
        >
            <template #filters>
                <MyFilterButton
                    filter-name="state"
                    value="all"
                    @selected="setState"
                    v-text="t('all')"
                />
                <MyFilterButton
                    filter-name="state"
                    default
                    value="0"
                    @selected="setState"
                    v-text="t('unresolved')"
                />
                <MyFilterButton
                    filter-name="state"
                    value="1"
                    @selected="setState"
                    v-text="t('resolved')"
                />
                <MyFilterButton
                    filter-name="state"
                    value="2"
                    @selected="setState"
                    v-text="t('muted')"
                />
                <!-- Add transport-type filter only for Damage Reports -->
                <MyFilterSelect
                    v-if="isDamageReports"
                    :label="t('transportTypes')"
                    :options="transportTypes"
                    filter-name="transport-type"
                    @change="(value) => setTransportType(value)"
                />
            </template>

            <template #actionMenu="{ row }">
                <template v-if="row.licenseId === LicenseType.DamageReportModule">
                    <ActionMenuItem
                        class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                        @click="showDamageReportModal(row)"
                    >
                        <mdi:eye class="mr-2" />
                        {{ t('viewReport') }}
                    </ActionMenuItem>

                    <ActionMenuItem
                        v-if="isIncorrectTransportObjectAlarm(row)"
                        class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                        @click="showDeviationModal(row)"
                    >
                        <mdi:eye class="mr-2" />
                        {{ t('showDeviation') }}
                    </ActionMenuItem>

                    <ActionRowDivider v-if="row.resolvedAt === null" />
                </template>

                <ActionMenuItem
                    v-if="
                        isResolvable(row) &&
                        authStore.hasPermission(PermissionType.ManageCompany) &&
                        !authStore.isReadonly.damageReport
                    "
                    @click="resolveAlarm(row)"
                >
                    <div v-if="!row.resolvedAt && !authStore.isReadonly.damageReport" class="flex">
                        <mdi:bell-check class="mr-2" />
                        <span v-text="t('resolveAlarm')" />
                    </div>
                    <div v-else class="flex">
                        <mdi:bell-cancel class="mr-2" />
                        <span v-text="t('unresolveAlarm')" />
                    </div>
                </ActionMenuItem>

                <ActionMenuItem
                    v-if="
                        isIncidentAlarm(row) &&
                        row.resolvedAt === null &&
                        !authStore.isReadonly.damageReport
                    "
                    @click="fixIncident(row)"
                >
                    <mdi:tools class="mr-2" />
                    {{ t('fixIncident') }}
                </ActionMenuItem>

                <ActionMenuItem
                    v-if="isTicketUpdatedAlarm(row) || isTicketCreatedAlarm(row)"
                    @click="showTicketModal(row)"
                >
                    <mdi:file-document class="mr-2" />
                    {{ t('showTicket') }}
                </ActionMenuItem>

                <ActionMenuItem
                    v-if="isDeviationCreatedAlarm(row)"
                    @click="showDeviationModal(row)"
                >
                    <mdi:file-document class="mr-2" />
                    {{ t('showDeviation') }}
                </ActionMenuItem>

                <ActionMenuItem
                    v-if="
                        row.resolvedAt === null &&
                        authStore.hasPermission(PermissionType.ManageCompany) &&
                        !authStore.isReadonly.damageReport
                    "
                    @click="muteAlarm(row)"
                >
                    <div v-if="!row.mutedAt" class="flex">
                        <mdi:bell-off class="mr-2" />
                        <span v-text="t('muteAlarm')" />
                    </div>
                    <div v-else class="flex">
                        <mdi:bell-badge class="mr-2" />
                        <span v-text="t('unmuteAlarm')" />
                    </div>
                </ActionMenuItem>

                <template v-if="isCustomerBalanceLimitAlarm(row)">
                    <ActionRowDivider />

                    <ActionMenuItem
                        class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                        @click="
                            router.push({
                                name: 'packaging.customers.show',
                                params: { id: row.description.customerId },
                            })
                        "
                    >
                        <mdi:eye class="mr-2" />
                        {{ t('viewCustomer') }}
                    </ActionMenuItem>
                </template>
            </template>
            <template #actionRow="{ row }">
                <MyButton
                    v-tooltip="
                        row.assignedUser?.id
                            ? t('unassignExplanation', { user: row.assignedUser.name })
                            : t('assignExplanation')
                    "
                    size="small"
                    :scheme="row.assignedUser?.id ? 'secondary' : 'primary'"
                    @click="assignUserToAlarm(row)"
                >
                    {{ row.assignedUser?.id ? t('unassign') : t('assign') }}
                </MyButton>
            </template>

            <MyTableColumn :name="t('createdAt')" property="createdAt" />
            <template #createdAt="{ row }">
                <span v-text="datetime(row.createdAt)" />
            </template>
            <MyTableColumn :name="t('description')" property="description" :sortable="false" />
            <template #description="{ row }">
                <div class="flex space-x-2 items-center">
                    <div
                        v-tooltip="t(alarmTranslations[row.type])"
                        class="mr-1 h-4 w-4 flex-shrink-0 rounded-full border border-white dark:border-dark-500"
                        :class="alarmColors[row.type]"
                    />

                    <template v-if="isDeliveryManagement">
                        <div>{{ row.tags?.map((tag) => t(tag.name)).join(', ') }}</div>
                        <span v-text="'-'" />
                    </template>

                    <span class="truncate" v-text="getAlarmDescription(row)" />
                </div>
            </template>
            <MyTableColumn v-if="isDamageReports" :name="t('driver')" property="user.name" />
            <MyTableColumn v-if="isDeliveryManagement" :name="t('assignee')" property="user.name" />
            <MyTableColumn
                v-if="isDamageReports"
                :name="t('registrationNumber')"
                property="transportObject.registrationNumber"
            />
            <MyTableColumn
                v-if="isDamageReports"
                :name="t('nickname')"
                property="transportObject.nickname"
            />
            <MyTableColumn :name="t('updatedBy')" property="updatedByUser.name" />
            <MyTableColumn
                :name="t('ticketNumber')"
                property="updateTicketNumber"
                :sortable="false"
            />
            <template #updateTicketNumber="{ row }">
                <div class="group/item flex items-center">
                    <span class="w-full truncate" v-text="row.ticketNumber" />
                    <span
                        class="cursor-pointer pl-3 text-gray-600 opacity-0 transition-opacity group-hover/item:opacity-100 dark:text-primary-50"
                        @click="updateTicketNumber(row)"
                    >
                        <mdi:pencil />
                    </span>
                </div>
            </template>

            <MyTableColumn :name="t('resolvedAt')" property="resolvedAt" />
            <template #resolvedAt="{ row }">
                <span v-text="row.resolvedAt ? datetime(row.resolvedAt) : null" />
            </template>
            <MyTableColumn :width="100" :name="t('muted')" boolean-column property="mutedAt" />
            <MyTableColumn :name="t('comment')" property="comment" />
            <MyTableColumn :name="t('assignedUser')" property="assignedUser.name" />
        </MyTable>
    </MyTabs>

    <UpdateAlarmStateModal
        v-if="selectedAlarm"
        v-model="alarmStateModalOpen"
        :alarm="selectedAlarm"
        :alarm-action="alarmAction"
    />

    <UpdateAlarmTicketNumberModal
        v-if="selectedAlarm"
        v-model="alarmStateTicketOpen"
        :alarm="selectedAlarm"
    />

    <template v-if="isDamageReports">
        <ReportModal
            v-if="selectedAlarm && damageReportModalOpen"
            :id="selectedAlarm.alarmableId"
            v-model:open="damageReportModalOpen"
            @update:open="selectedAlarm = undefined"
        />

        <FixIncidentModal
            v-if="selectedAlarm && fixIncidentModalOpen && isIncidentAlarm(selectedAlarm)"
            v-model="fixIncidentModalOpen"
            :incident-id="selectedAlarm.description.incidentId"
            :report-id="selectedAlarm.alarmableId"
            :alarm="selectedAlarm"
        />
    </template>

    <DeviationModal
        v-if="selectedAlarm && deviationModalOpen"
        :id="deviationId"
        v-model:open="deviationModalOpen"
        @update:open="selectedAlarm = undefined"
    />

    <template v-if="isDeliveryManagement">
        <TicketModal
            v-if="selectedAlarm && ticketModalOpen"
            :id="selectedAlarm.alarmableId"
            v-model:open="ticketModalOpen"
            @update:open="selectedAlarm = undefined"
        />
    </template>
</template>
