<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { ref, watch } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import Datepicker from '@vuepic/vue-datepicker'
import dayjs from 'dayjs'

import { useAuthStore } from '@/stores/auth-store'
import { uuid, PaginatedResponse } from '@/types/general'
import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { MinimalHub } from '@/types/delivery-management'
import { GetDataParameters } from '@/types/table'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'
import { numberFormatter } from '@/utils/numbers'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import ManageHubModal from '@/components/delivery-management/ManageHubModal.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import UpdateShipperHubModal from '@/components/delivery-management/UpdateShipperHubModal.vue'

interface FetchHubsParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        'start-date': string
        'end-date': string
    }
}

const { t } = useI18n()
const authStore = useAuthStore()

const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    MinimalHub,
    FetchHubsParams
>(
    async (params, abortController) => {
        const response = await axios.get<PaginatedResponse<MinimalHub>>(
            window.route('dm.company.hubs.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    {
        filter: {
            'start-date': dayjs().startOf('day').toDate().toISOString(),
            'end-date': dayjs().endOf('day').toDate().toISOString(),
        },
    },
)

const hubModalOpen = ref(false)
const selectedHubId = ref<uuid>()
const haulierModalOpen = ref(false)
const date = ref<Date[]>([
    dayjs(params.value.filter['start-date']).toDate(),
    dayjs(params.value.filter['end-date']).toDate(),
])
const presetRanges = usePresetRangeList()

const earlyOrDelayedTooltip = (row: MinimalHub) => {
    const early = `${row.handledEarlyShipmentsCount}/${row.shipmentsCount}`
    const delayed = `${row.delayedShipmentsCount}/${row.shipmentsCount - row.handledEarlyShipmentsCount}`

    return `${early} ${t('isEarly')}\n${delayed} ${t('isDelayed')}`
}

const slaPercentage = (row: MinimalHub) => {
    if (!row.delayedShipmentsCount && !row.handledEarlyShipmentsCount) return '100%'
    const onTimeShipments =
        row.shipmentsCount - row.delayedShipmentsCount - row.handledEarlyShipmentsCount

    return numberFormatter.format((onTimeShipments / row.shipmentsCount) * 100) + '%'
}

function updateHub(id: string) {
    selectedHubId.value = id
    hubModalOpen.value = true
}

function hubModalClosed() {
    hubModalOpen.value = false
    selectedHubId.value = undefined
}

function updateShipperHub(id: string) {
    selectedHubId.value = id
    haulierModalOpen.value = true
}

function haulierModalClosed() {
    selectedHubId.value = undefined
    haulierModalOpen.value = false
}

function showTours(id: string): RouteLocationRaw {
    return {
        name: 'dm.tours',
        // @ts-ignore
        query: { filter: { 'hub-ids': id } },
    }
}

function showDeviations(id: string): RouteLocationRaw {
    return {
        name: 'dm.deviations',
        // @ts-ignore
        query: { filter: { 'hub-id': id } },
    }
}
function showTickets(id: string): RouteLocationRaw {
    return {
        name: 'dm.tickets',
        // @ts-ignore
        query: { filter: { 'hub-id': id } },
    }
}

function setFilter(type: string | number | null, filterName: string) {
    params.value.filter[filterName] = type
    params.value.page = 1
}

watch(date, () => {
    setFilter(dayjs(date.value[0]).startOf('day').toISOString(), 'start-date')
    setFilter(dayjs(date.value[1]).endOf('day').toISOString(), 'end-date')
})
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'dm' }" v-text="t('deliveryManagement')" />
        <Breadcrumb current v-text="t('hubs')" />

        <template #actions>
            <MyButton
                v-if="!authStore.isReadonly.deliveryManagement"
                plain
                scheme="primary"
                size="small"
                @click="hubModalOpen = true"
            >
                <mdi:plus-thick class="mr-1" />
                {{ t('addHub') }}
            </MyButton>
        </template>
    </CrumbsAndActions>

    <RouterView />

    <MyPanel v-if="authStore.companyId" shadow>
        <MyTable
            table-id="dm-hubs"
            :rows="data"
            :pagination-data="paginationData"
            :loading="loading"
            :error="error"
            :get-data="refetch"
            :entity-name="t('hubs')"
        >
            <template #filters>
                <Datepicker
                    v-model="date"
                    input-class-name="w-64"
                    range
                    :enable-time-picker="false"
                    auto-apply
                    :teleport="true"
                    :clearable="false"
                    :preset-dates="presetRanges"
                    close-on-scroll
                    position="left"
                    format="yyyy-MM-dd"
                    :transitions="false"
                />
            </template>

            <template #actionRow="{ row }">
                <RouterLink :to="showTours(row.id)">
                    <ActionRowItem v-tooltip="t('showToursForHub')">
                        <mdi:eye />
                    </ActionRowItem>
                </RouterLink>
            </template>
            <template v-if="!authStore.isReadonly.deliveryManagement" #actionMenu="{ row }">
                <ActionMenuItem
                    v-if="authStore.companyId === row.company.id"
                    @click="updateHub(row.id)"
                >
                    <span class="mr-2"><mdi:pencil /></span>
                    {{ t('edit') }}
                </ActionMenuItem>
                <ActionMenuItem
                    v-if="authStore.companyId === row.shipperCompany?.id"
                    @click="updateShipperHub(row.id)"
                >
                    <span class="mr-2"><mdi:truck-cargo-container /></span>
                    {{ t('updateShipperHub') }}
                </ActionMenuItem>
            </template>
            <MyTableColumn :name="t('name')" property="name" />
            <MyTableColumn :name="t('shipper')" property="shipperCompany.name" />
            <MyTableColumn :name="t('hauliers')" property="hauliers" :sortable="false" />
            <template #hauliers="{ row }">
                {{ row.hauliers.map((haulier) => haulier.name).join(', ') }}
            </template>

            <MyTableColumn :name="t('sla')" property="sla" :sortable="false" />
            <template #sla="{ row }">
                <div v-tooltip="earlyOrDelayedTooltip(row)">
                    <span
                        :class="{
                            'text-yellow-600 font-semibold':
                                row.delayedShipmentsCount || row.handledEarlyShipmentsCount,
                        }"
                        v-text="slaPercentage(row)"
                    />
                </div>
            </template>
            <MyTableColumn :name="t('tours')" property="toursCount" />
            <MyTableColumn :name="t('deviations')" property="deviationsCount" />
            <template #deviationsCount="{ row }">
                <div
                    v-tooltip="row.deviationsCount !== 0 ? t('hasDeviations') : t('noDeviations')"
                    class="flex items-center space-x-1"
                >
                    <RouterLink class="flex items-center space-x-1" :to="showDeviations(row.id)">
                        <div
                            v-if="row.deviationsCount !== 0"
                            class="h-3 w-3 rounded-full bg-red-500"
                        />
                        <div v-else class="h-3 w-3 rounded-full bg-green-500" />
                        <span>{{ row.deviationsCount }}</span>
                    </RouterLink>
                </div>
            </template>

            <MyTableColumn :name="t('tickets')" property="ticketsCount" />
            <template #ticketsCount="{ row }">
                <div
                    v-tooltip="row.ticketsCount !== 0 ? t('hasTickets') : t('noTickets')"
                    class="flex items-center space-x-1"
                >
                    <RouterLink class="flex items-center space-x-1" :to="showTickets(row.id)">
                        <div
                            v-if="row.ticketsCount !== 0"
                            class="h-3 w-3 rounded-full bg-red-500"
                        />
                        <div v-else class="h-3 w-3 rounded-full bg-green-500" />
                        <span>{{ row.ticketsCount }}</span>
                    </RouterLink>
                </div>
            </template>
        </MyTable>
    </MyPanel>

    <ManageHubModal
        v-if="hubModalOpen"
        v-model="hubModalOpen"
        :hub-id="selectedHubId"
        @close="hubModalClosed"
        @saved="refetch()"
    />

    <UpdateShipperHubModal
        v-if="haulierModalOpen && selectedHubId"
        v-model="haulierModalOpen"
        :hub-id="selectedHubId"
        @close="haulierModalClosed"
        @saved="refetch()"
    />
</template>
