<script lang="ts" setup>
import axios from 'axios'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import {
    DashboardWidget,
    EntityRecordCountWidgetRef,
    WidgetType,
    EntityCountRecord,
} from '@/types/dashboard'
import { PaginatedResponse } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { DropdownOption } from '@/types/inputs'
import { ReportType } from '@/types/damage-report'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { TransportObjectType } from '@/types/transport-object'

import EntityRecordCountWidget from '@/components/dashboard/widgets/EntityRecordCountWidget.vue'
import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import TimePeriodOption from '@/components/dashboard/widget-settings/TimePeriodOption.vue'

interface Props {
    widget: DashboardWidget<WidgetType.DamageReportsTransportObjectIncidents>
    preview: boolean
}

const props = defineProps<Props>()
const { t } = useI18n()
const authStore = useAuthStore()
const dashboardStore = useDashboardStore()
const recordWidget = ref<EntityRecordCountWidgetRef>()

const selectedReportType = computed({
    get: () => props.widget.options.reportType ?? null,
    set: (reportType) =>
        dashboardStore.updateWidgetOptions(props.widget, { reportType: reportType ?? undefined }),
})
const timePeriod = computed({
    get: () => props.widget.options.timePeriod ?? 7,
    set: (timePeriod) => dashboardStore.updateWidgetOptions(props.widget, { timePeriod }),
})
const sortBy = computed({
    get: () => props.widget.options.sortBy ?? '-count',
    set: (sortBy) => dashboardStore.updateWidgetOptions(props.widget, { sortBy }),
})
const selectedTransportObjectType = computed({
    get: () => props.widget.options.transportObjectType ?? null,
    set: (transportObjectType) =>
        dashboardStore.updateWidgetOptions(props.widget, {
            transportObjectType: transportObjectType ?? undefined,
        }),
})
const reportOptions = computed<DropdownOption[]>(() => [
    { label: t('pickup'), value: ReportType.Pickup },
    { label: t('dropOff'), value: ReportType.DropOff },
])
const sortOptions = computed<DropdownOption[]>(() => [
    { label: t('entityDescending', { entity: t('incidents') }), value: '-count' },
    { label: t('entityAscending', { entity: t('incidents') }), value: 'count' },
])
const transportObjectOptions = computed<DropdownOption[]>(() => [
    { label: t('onlyUnits'), value: TransportObjectType.Unit },
    { label: t('onlyVehicles'), value: TransportObjectType.Vehicle },
])

async function fetchIncidentCount(
    rowsPerPage: number,
    currentPage: number,
): Promise<PaginatedResponse<EntityCountRecord>> {
    const { data: response } = await axios.get<PaginatedResponse<EntityCountRecord>>(
        window.route('dashboards.dmr-transport-object-incidents', {
            dashboard: dashboardStore.currentDashboard!.id,
            shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
            page: currentPage,
            'report-type': selectedReportType.value,
            'transport-object-type': selectedTransportObjectType.value,
            'start-date': dayjs().subtract(timePeriod.value, 'days').toISOString(),
            'per-page': rowsPerPage,
            sort: sortBy.value,
        }),
    )
    return {
        data: response.data,
        meta: response.meta,
    }
}

watch(
    () => props.widget.options,
    () => recordWidget.value?.fetchData(),
)

useMittListener('fetchWidgetData', () => recordWidget.value?.fetchData())
</script>

<template>
    <MyWidget :widget="props.widget" :preview="props.preview">
        <EntityRecordCountWidget
            ref="recordWidget"
            :fetch-data="fetchIncidentCount"
            :title="t('vehiclesAndUnitIncidents')"
            hide-entity-image
        />
        <template #settings>
            <MySelect
                v-model="selectedReportType"
                :options="reportOptions"
                group-class="mt-4 mx-auto"
                :label="t('reportType')"
                :clear-option="t('all')"
                :placeholder="t('all')"
            />
            <MySelect
                v-model="selectedTransportObjectType"
                :options="transportObjectOptions"
                group-class="mt-4 mx-auto"
                :label="t('vehiclesOrUnits')"
                :clear-option="t('both')"
                :placeholder="t('both')"
            />
            <MySelect
                v-model="sortBy"
                :options="sortOptions"
                group-class="mt-4 mx-auto"
                :label="t('sortBy')"
            />
            <TimePeriodOption v-model="timePeriod" />
        </template>
    </MyWidget>
</template>
