<script lang="ts" setup>
import { Switch } from '@headlessui/vue'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { Customer, CustomerCompany } from '@/types/company'
import { uuid } from '@/types/general'
import { DropdownOption, DropdownStringOption } from '@/types/inputs'
import { ScheduledStatementOptions, ScheduleFrequency } from '@/types/packaging'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyEmailsInput from '@/components/my-components/form/MyEmailsInput.vue'

export interface Props {
    modelValue: boolean
    customer: Customer<CustomerCompany>
}

interface Form extends ScheduledStatementOptions {
    enabled: boolean
    customerCompanyId: uuid
}

const dayOfMonthOptions: DropdownOption[] = new Array(20)
    .fill(0)
    .map((_, index) => ({ value: index + 1, label: `${index + 1}.` }))

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'update:customer', value: Customer<CustomerCompany>): void
}>()
const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()

const form = useForm<Form>({
    enabled: false,
    customerCompanyId: props.customer.customerCompany.id,
    emails: [],
    frequency: ScheduleFrequency.Monthly,
    dayOfMonth: 1,
})

const frequencyOptions = computed<DropdownStringOption[]>(() => [
    { value: ScheduleFrequency.Monthly, label: t('everyMonth') },
    { value: ScheduleFrequency.Weekly, label: t('mondayEveryWeek') },
    { value: ScheduleFrequency.every14days, label: t('monday14days') },
])

async function save() {
    const response = await form.submit(
        'PUT',
        window.route('packaging.company.scheduled-statements.update', {
            company: authStore.companyId,
        }),
    )

    if (!response) return

    const scheduledStatement: ScheduledStatementOptions = {
        frequency: form.data.frequency,
        dayOfMonth: form.data.dayOfMonth,
        emails: form.data.emails,
    }
    // Yeah I know that this might not been the most ideal option
    // but I think it's a little overkill to refetch customer just to update this info
    emit('update:customer', {
        ...props.customer,
        moduleInfo: { ...props.customer.moduleInfo, scheduledStatement },
    })
    emit('update:modelValue', false)
}

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return

        const scheduledStatement = props.customer.moduleInfo?.scheduledStatement

        const emails = [props.customer.email ?? props.customer.customerCompany.email]
            .concat(props.customer.additionalEmails)
            .flat()

        form.reset({
            customerCompanyId: props.customer.customerCompany.id,
            enabled: !!scheduledStatement,
            frequency: scheduledStatement?.frequency ?? ScheduleFrequency.Monthly,
            dayOfMonth: scheduledStatement?.dayOfMonth ?? 1,
            emails: scheduledStatement?.emails ?? emails,
        })
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>{{ t('automaticStatements') }}</template>

        <LoaderWrapper :visible="form.loading.value" />

        <MyForm class="space-y-4" :errors="form.errors.value" @submit.prevent="save">
            <p class="leading-5" v-text="t('automaticStatementDescription')" />

            <div class="mt-2 flex items-center gap-3">
                <Switch
                    v-model="form.data.enabled"
                    :class="form.data.enabled ? 'bg-primary-500' : 'bg-gray-300'"
                    class="relative inline-flex h-[28px] w-[64px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-opacity-75"
                >
                    <span class="sr-only" v-text="t(form.data.enabled ? 'enabled' : 'disabled')" />

                    <span
                        :class="form.data.enabled ? 'translate-x-9' : 'translate-x-0'"
                        aria-hidden="true"
                        class="pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
                    />
                </Switch>

                <span v-text="t(form.data.enabled ? 'enabled' : 'disabled')" />
            </div>

            <template v-if="form.data.enabled">
                <div class="flex justify-between">
                    <MySelect
                        v-model="form.data.frequency"
                        name="frequency"
                        :label="t('frequency')"
                        :options="frequencyOptions"
                        group-class="w-full"
                    />

                    <MySelect
                        v-if="form.data.frequency === ScheduleFrequency.Monthly"
                        v-model="form.data.dayOfMonth"
                        group-class="ml-4"
                        name="dayOfMonth"
                        :label="t('dayOfMonth')"
                        :options="dayOfMonthOptions"
                    />
                </div>
                <MyEmailsInput v-model="form.data.emails" required />
            </template>

            <div class="flex justify-end">
                <MyButton :disabled="form.loading.value" scheme="primary">{{ t('save') }}</MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
