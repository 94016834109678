<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useProductStore } from '@/stores/product-store'
import { useTransactionStore } from '@/stores/transaction-store'
import { TransactionImageType } from '@/types/transaction'
import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { PackagingPreferences } from '@/types/packaging'

import ManageTransactionImageItem from './ManageTransactionImageItem.vue'
import ManageTransactionProductItem from './ManageTransactionProductItem.vue'

import MyFileSelect from '@/components/my-components/MyFileSelect.vue'
import MyFormWizardStep from '@/components/my-components/form/MyFormWizardStep.vue'

export interface Props {
    active: boolean
    imageUploadOpen: boolean
}

const { t } = useI18n()

const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'closeImageUpload'): void }>()
const productStore = useProductStore()
const transactionStore = useTransactionStore()
const authStore = useAuthStore()
const errorMessage = ref('')
const transaction = transactionStore.transaction!
const receivedImages = computed(() =>
    transaction.images.filter((image) => image.type === TransactionImageType.Received),
)
const deliveredImages = computed(() =>
    transaction.images.filter((image) => image.type === TransactionImageType.Delivered),
)
const uploadingImages = computed(
    () =>
        transaction.images.filter((image) => !image.path).length !== 0 &&
        transaction.images.length > 0,
)
const nilTradesEnabled = computed(() => {
    const license = authStore.licenses[LicenseType.PackagingModule] as
        | PackagingPreferences
        | undefined

    return license?.nilTradesEnabled === true
})

function handleImageUpload(images: File[], type: TransactionImageType) {
    const transactionImages = images.map((image) => ({ file: image, type, note: '' }))
    transactionStore.appendImages(transactionImages)
}

function submit() {
    if (uploadingImages.value) {
        errorMessage.value = t('uploadingImagesError')
        return false
    }

    const hasProducts = transaction.products.some((p) => p.received > 0 || p.delivered > 0)
    if (!hasProducts && !nilTradesEnabled.value) {
        errorMessage.value = t('manageTransactionProductsError')
        return false
    }

    errorMessage.value = ''

    return true
}

onMounted(async () => {
    if (!productStore.loaded) await productStore.fetchProducts()

    if (transactionStore.isCreatingNew) {
        transaction.products = productStore.transactionProducts()
    } else {
        const productIds = transaction.products.map((p) => p.id)
        for (const product of productStore.transactionProducts()) {
            if (!productIds.includes(product.id)) {
                transaction.products.push(product)
            }
        }
    }
})
</script>

<template>
    <MyFormWizardStep
        id="products"
        :active="props.active"
        :submit="submit"
        :title="t('products')"
        :loading="uploadingImages"
    >
        <div class="flex justify-end text-center text-xs">
            <div
                v-if="errorMessage"
                class="grow text-left font-semibold text-red-500"
                v-text="errorMessage"
            />

            <div class="w-[102px] font-semibold uppercase" v-text="t('delivered')" />
            <div class="w-[100px] font-semibold uppercase" v-text="t('received')" />
        </div>
        <div class="mt-3">
            <ManageTransactionProductItem
                v-for="(product, index) in transaction.products"
                :key="product.id"
                v-model="transaction.products[index]"
                @update:model-value="errorMessage = ''"
            />
        </div>

        <div v-if="imageUploadOpen || transaction.images.length !== 0" class="relative mt-6">
            <button
                v-if="imageUploadOpen"
                type="button"
                class="absolute top-0 right-0 flex h-6 w-6 items-center justify-center rounded-full bg-yellow-400 text-center text-sm transition-all hover:bg-yellow-500"
                @click="emit('closeImageUpload')"
            >
                <mdi:close />
            </button>

            <div class="grid grid-cols-2 gap-6">
                <div>
                    <template v-if="imageUploadOpen || deliveredImages.length !== 0">
                        <h2
                            class="my-3 text-center font-semibold uppercase"
                            v-text="t('deliveredImages')"
                        />
                        <MyFileSelect
                            v-if="imageUploadOpen"
                            multiple
                            class="h-40 rounded-xl"
                            @selected:multiple="
                                (img) => handleImageUpload(img, TransactionImageType.Delivered)
                            "
                        />
                        <div
                            v-if="deliveredImages.length !== 0"
                            class="mt-3 grid grid-cols-3 gap-3 rounded-xl"
                        >
                            <ManageTransactionImageItem
                                v-for="(image, index) in deliveredImages"
                                :key="'delivered-' + index"
                                :image="image"
                            />
                        </div>
                    </template>
                </div>
                <div>
                    <template v-if="imageUploadOpen || receivedImages.length !== 0">
                        <h2
                            class="my-3 text-center font-semibold uppercase"
                            v-text="t('receivedImages')"
                        />
                        <MyFileSelect
                            v-if="imageUploadOpen"
                            multiple
                            class="h-40 rounded-xl"
                            @selected:multiple="
                                (img) => handleImageUpload(img, TransactionImageType.Received)
                            "
                        />
                        <div
                            v-if="receivedImages.length !== 0"
                            class="mt-3 grid grid-cols-3 gap-3 rounded-xl"
                        >
                            <ManageTransactionImageItem
                                v-for="(image, index) in receivedImages"
                                :key="'received-' + index"
                                :image="image"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </MyFormWizardStep>
</template>
