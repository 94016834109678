<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
<template>
    <main class="consignment-note-sheet grid grid-cols-4 print:h-full print:bg-white">
        <!-- First Section -->
        <div class="flex flex-col col-span-3">
            <div
                class="flex flex-col border-y border-l border-black bg-primary-100 p-3 dark:bg-dark-500"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    <span class="text-lg">1.</span>
                    {{ t('sender') }}
                </span>
                <slot name="sender" />
            </div>
            <div
                class="flex flex-col bg-primary-100 border-l border-b border-black p-3 dark:bg-dark-500 flex-1"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    <span class="text-lg">2.</span>
                    {{ t('receiver') }}
                </span>
                <slot name="receiver" />
            </div>
        </div>
        <div
            class="qrParent flex flex-col col-span-1 border border-black col-start-4 row-start-1 items-center bg-primary-100 p-3 print:min-h-0 dark:bg-dark-500 h-full"
        >
            <h1
                class="text-xl font-bold uppercase text-primary-500 dark:text-primary-400"
                v-text="t('cmr')"
            />
            <h3
                class="text-lg font-semibold uppercase text-primary-400 dark:text-primary-300"
                v-text="t('shipment')"
            />
            <slot name="shipmentNumber" />
            <slot name="imageLogo" />
        </div>
        <!-- Second Section -->
        <div class="flex flex-col col-span-2 h-full">
            <div
                class="flex flex-col border-x border-black bg-primary-100 p-3 dark:bg-dark-500 h-full"
            >
                <span class="text-sm text-primary-500 dark:text-gray-400">
                    3.
                    <span
                        class="text-xs font-semibold text-primary-500 dark:text-primary-400"
                        v-text="t('placeOfDelivery')"
                    />
                </span>
                <slot name="placeOfDelivery" />
            </div>
            <div
                class="flex flex-col border-black border-t border-b border-x bg-primary-100 p-3 dark:bg-dark-500 h-full"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    <span class="text-lg">4.</span>
                    {{ t('takenOverPlace') }}
                </span>
                <slot name="takenOverPlace" />
            </div>
            <div
                class="flex flex-col border-black border-x border-b bg-primary-100 p-3 dark:bg-dark-500 h-full"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    <span class="text-lg">5.</span>
                    {{ t('documentsAttached') }}
                </span>
                <slot name="documentsAttached" />
            </div>
        </div>
        <div class="flex flex-col col-span-2 h-full">
            <div
                class="flex flex-col border-black border-r bg-primary-100 p-3 dark:bg-dark-500 h-full"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    <span class="text-lg">16.</span>
                    <!--Modify to successive carrier-->
                    {{ t('shipper') }}
                </span>
                <slot name="shipper" />
            </div>
            <div
                class="flex flex-col border-black border-y border-r bg-primary-100 p-3 dark:bg-dark-500 h-full"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    <span class="text-lg">17.</span>
                    <!--Modify to successive carrier-->
                    {{ t('haulier') }}
                </span>
                <slot name="haulier" />
            </div>
            <div
                class="flex flex-col border-black border-b border-r bg-primary-100 p-3 dark:bg-dark-500 h-full"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    <span class="text-lg">18.</span>
                    <!--Modify to carrier's observations-->
                    {{ t('shipperInstructions') }}
                </span>
                <slot name="shipperInstructions" />
            </div>
        </div>
        <!-- Third section -->
        <div
            class="colli col-span-full border-black border-b border-x px-3 py-2 bg-primary-100 dark:bg-dark-500 h-full flex flex-col"
        >
            <slot name="collis" />
        </div>
        <!-- Fourth section ADR section remove if not needed -->
        <div
            class="flex justify-between bg-primary-100 dark:bg-dark-500 col-span-4 text-sm border-black border-x w-full"
        >
            <div class="flex w-fit border-r border-black py-6 print:py-2 px-3">
                <!--                    Add v-text props for ADR  -->
                <span class="flex gap-1">{{ t('class') }}: <span></span></span>
                <span class="flex mx-2 gap-1">{{ t('number') }}: <span></span></span>
                <span class="flex mx-2 gap-1">{{ t('letter') }}: <span></span></span>
                <span class="flex gap-1">{{ t('adr') }} <span></span></span>
            </div>
        </div>
        <!-- Fifth section -->
        <div class="flex flex-col col-span-1 h-full">
            <div
                class="flex flex-col border border-black bg-primary-100 p-3 dark:bg-dark-500 h-full"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    13.
                    {{ t('senderInstructions') }}
                </span>

                <slot name="senderInstructions" />
            </div>
            <div
                class="flex flex-col border-x border-black bg-primary-100 p-3 dark:bg-dark-500 h-full"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    14.
                    {{ t('instructionsPayment') }}
                </span>

                <slot name="instructionsPayment" />
            </div>
        </div>
        <!-- Sixth section -->
        <div class="flex flex-col col-span-3 h-full">
            <div
                class="flex flex-col border-r border-t border-black bg-primary-100 p-3 dark:bg-dark-500 h-full"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    19.
                    {{ t('specialAgreements') }}
                </span>

                <slot name="specialAgreements" />
            </div>
            <table class="w-full border-black text-left bg-primary-100 dark:bg-dark-500">
                <!-- Table Header -->
                <thead>
                    <tr class=" ">
                        <th class="border-t border-black px-4 py-2 print:py-1 w-1/4">
                            <span class="text-lg">20.</span> {{ t('paidBy') }}
                        </th>
                        <th class="border border-black px-4 py-2 print:py-1 text-center">
                            {{ t('sender') }}
                        </th>
                        <th class="border border-black px-4 py-2 print:py-1 text-center">
                            {{ t('currency') }}
                        </th>
                        <th class="border border-black px-4 py-2 print:py-1 text-center">
                            {{ t('consignee') }}
                        </th>
                    </tr>
                </thead>

                <!-- Table Body -->
                <tbody>
                    <tr>
                        <td class="border-t border-black px-4 py-2 print:py-1">
                            {{ t('carriageCharges') }}
                        </td>
                        <td class="border border-black"></td>
                        <td class="border border-black"></td>
                        <td class="border border-black"></td>
                    </tr>
                    <tr>
                        <td class="border-t border-black px-4 py-2 print:py-1">
                            {{ t('balance') }}
                        </td>
                        <td class="border border-black"></td>
                        <td class="border border-black"></td>
                        <td class="border border-black"></td>
                    </tr>
                    <tr>
                        <td class="border-t border-black px-4 py-2 print:py-1">
                            {{ t('cargoInsurance') }}
                        </td>
                        <td class="border border-black"></td>
                        <td class="border border-black"></td>
                        <td class="border border-black"></td>
                    </tr>
                    <tr>
                        <td class="border-t border-r border-black px-4 py-2 print:py-1">
                            {{ t('totalPaid') }}
                        </td>
                        <td class="border-t border-r border-black"></td>
                        <td class="border-t border-r border-black"></td>
                        <td class="border-t border-r border-black"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div
            class="w-full justify-between gap-2 col-span-4 border-y border-x border-black flex bg-primary-100 dark:bg-dark-500"
        >
            <div class="flex w-full text-center p-2 border-r border-black items-center gap-2">
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    21. {{ t('establishedIn') }}:
                </span>
                <slot name="establishedIn" />
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    {{ t('on') }}:
                </span>
                <slot name="establishedOn" />
            </div>
            <div class="flex w-[55%] text-center p-2 border-black items-center gap-2">
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    22. {{ t('cashOnDelivery') }}:
                </span>
                <span class=""></span>
            </div>
        </div>
        <!--Seventh section-->
        <div class="flex col-span-full h-full break-inside-avoid border-x border-b border-black">
            <div
                class="flex flex-col w-1/2 border-black overflow-hidden dark:bg-dark-500 bg-primary-100 h-full p-6 print:py-2"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    23. {{ t('senderSignature') }}:
                </span>
                <slot name="senderSignature" />
            </div>
            <div
                class="flex flex-col w-1/2 border-x border-black overflow-hidden dark:bg-dark-500 bg-primary-100 h-full p-6 print:py-2"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    24. {{ t('driverSignature') }}:
                </span>
                <slot name="driverSignature" />
            </div>
            <div
                class="flex flex-col w-1/2 border-black overflow-hidden dark:bg-dark-500 bg-primary-100 h-full p-6 print:py-2"
            >
                <span class="text-xs font-semibold text-primary-500 dark:text-primary-400">
                    25. {{ t('receiverSignature') }}:
                </span>
                <slot name="receiverSignature" />
            </div>
        </div>
    </main>
</template>
<style>
@media print {
    .consignment-note-sheet span {
        font-size: 0.6rem;
    }
    .consignment-note-sheet .qrParent h1,
    .consignment-note-sheet .qrParent h3 {
        font-size: 1rem;
        line-height: 1.15;
    }
    .consignment-note-sheet .qr {
        width: 7rem;
    }
    .consignment-note-sheet .colli div {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }
    .consignment-note-sheet .colli span {
        font-size: 0.6rem;
    }
    .consignment-note-sheet table {
        font-size: 0.6rem;
    }
}
</style>
