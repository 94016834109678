<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

import { useAuthStore } from '@/stores/auth-store'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import ConsignmentNotesTable from '@/components/e-cmr/ConsignmentNotesTable.vue'
import ManageConsignmentNoteModal from '@/components/e-cmr/ManageConsignmentNoteModal.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const shipmentModalOpen = ref(false)
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'ecmr' }" v-text="t('e-cmr')" />
        <Breadcrumb current v-text="t('consignmentNotes')" />

        <template #actions>
            <MyButton plain scheme="primary" size="small" @click="shipmentModalOpen = true">
                <mdi:plus-thick class="mr-1" />
                {{ t('addConsignmentNote') }}
            </MyButton>
        </template>
    </CrumbsAndActions>

    <RouterView />

    <MyPanel v-if="authStore.companyId" shadow>
        <ConsignmentNotesTable />
    </MyPanel>

    <ManageConsignmentNoteModal v-model="shipmentModalOpen" @close="shipmentModalOpen = false" />
</template>
