<script setup lang="ts">
import type { DropdownOption } from '@/types/inputs'

import axios from 'axios'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import {
    DashboardWidget,
    EntityRecordCountWidgetRef,
    WidgetType,
    EntityCountRecord,
} from '@/types/dashboard'
import { PaginatedResponse } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { useMittListener } from '@/hooks/use-mitt-listener'

import EntityRecordCountWidget from '@/components/dashboard/widgets/EntityRecordCountWidget.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import TimePeriodOption from '@/components/dashboard/widget-settings/TimePeriodOption.vue'

interface Props {
    widget: DashboardWidget<WidgetType.DriverReportsEmployeeReports>
    preview?: boolean
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const dashboardStore = useDashboardStore()

const recordWidget = ref<EntityRecordCountWidgetRef>()

const sortBy = computed({
    get: () => props.widget.options.sortBy ?? '-count',
    set: (sortBy) => dashboardStore.updateWidgetOptions(props.widget, { sortBy }),
})
const timePeriod = computed({
    get: () => props.widget.options.timePeriod ?? 7,
    set: (timePeriod) => dashboardStore.updateWidgetOptions(props.widget, { timePeriod }),
})
const sortOptions = computed<DropdownOption[]>(() => [
    { label: t('entityDescending', { entity: t('reports') }), value: '-count' },
    { label: t('entityAscending', { entity: t('reports') }), value: 'count' },
    { label: t('name'), value: 'name' },
])

async function fetchReports(
    rowsPerPage: number,
    currentPage: number,
): Promise<PaginatedResponse<EntityCountRecord>> {
    const { data: response } = await axios.get<PaginatedResponse<EntityCountRecord>>(
        window.route('dashboards.employee-driver-reports', {
            dashboard: dashboardStore.currentDashboard!.id,
            shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
            page: currentPage,
            'per-page': rowsPerPage,
            sort: sortBy.value,
            'start-date': dayjs().subtract(timePeriod.value, 'days').toISOString(),
        }),
    )

    return {
        data: response.data,
        meta: response.meta,
    }
}

watch(
    () => props.widget.options,
    () => recordWidget.value?.fetchData(),
)

useMittListener('fetchWidgetData', () => recordWidget.value?.fetchData())
</script>

<template>
    <MyWidget :widget="props.widget" :preview="props.preview">
        <EntityRecordCountWidget
            ref="recordWidget"
            :title="t('driverReports')"
            :fetch-data="fetchReports"
            :entity-link="{ route: 'dr', title: t('driverReports') }"
        />

        <template #settings>
            <MySelect
                v-model="sortBy"
                :options="sortOptions"
                group-class="mt-4 mx-auto"
                :label="t('sortBy')"
            />

            <TimePeriodOption v-model="timePeriod" />
        </template>
    </MyWidget>
</template>
