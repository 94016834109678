<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Draggable from 'vuedraggable'

import { useAuthStore } from '@/stores/auth-store'
import { useProductStore } from '@/stores/product-store'
import { PermissionType } from '@/types/general'

import NoContent from '@/components/NoContent.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import AddProducts from '@/components/packaging/products/AddProducts.vue'
import ProductItem from '@/components/packaging/products/ProductItem.vue'

const productStore = useProductStore()
const authStore = useAuthStore()
const { selectedProducts, defaultProducts, modalOpen } = storeToRefs(productStore)

const { t, locale } = useI18n()
const dragging = ref(false)
const currency = computed(() => {
    const moneyFormatter = Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: authStore.company.options.currency || 'USD',
        maximumFractionDigits: 0,
    })

    // We have no way to easily get the symbol from the NumberFormat
    // so we'll have to do it like this
    return moneyFormatter.format(0).replace('0', '')
})

function saveDefaultOrder() {
    dragging.value = false

    const products = defaultProducts.value.map((product, index) => ({
        id: product.id,
        order: index + 1,
    }))

    productStore.saveDefaultOrder(products)
}

onBeforeMount(() => productStore.fetchProducts())
watch(locale, () => productStore.fetchProducts())
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'packaging' }" v-text="t('packaging')" />
        <Breadcrumb current v-text="t('products')" />

        <template #actions>
            <AddProducts
                v-if="
                    authStore.hasPermission(PermissionType.ManageCompany) &&
                    !authStore.isReadonly.packaging
                "
            />
        </template>
    </CrumbsAndActions>

    <teleport to="body">
        <LoaderWrapper :visible="productStore.fetching" />
    </teleport>

    <ContentHeading class="mt-5" v-text="t('myFavoredProducts')" />

    <section v-if="defaultProducts.length">
        <Draggable
            v-model="defaultProducts"
            :class="{ 'dragging cursor-grabbing': dragging }"
            ghost-class="drag-ghost-card"
            handle=".handle"
            class="mb-14 mt-5 grid gap-6 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6"
            item-key="id"
            @end="saveDefaultOrder"
            @start="dragging = true"
        >
            <template #item="{ element }">
                <ProductItem
                    :dragging="dragging"
                    :product="element"
                    favored
                    selected
                    show-company-form
                    :currency="currency"
                />
            </template>
        </Draggable>
    </section>
    <NoContent v-else :text="t('noFavoritedProductsSelected')" class="my-5 dark:bg-dark-500" />
    <ContentHeading v-text="t('myProducts')" />
    <section
        v-if="selectedProducts.length || defaultProducts.length"
        class="mt-5 grid gap-6 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6"
    >
        <ProductItem
            v-for="product in selectedProducts"
            :key="product.id"
            :product="product"
            selected
            show-company-form
            :currency="currency"
        />
        <div
            v-if="
                authStore.hasPermission(PermissionType.ManageCompany) &&
                !authStore.isReadonly.packaging
            "
            class="text-md flex cursor-pointer items-center justify-center min-h-[300px] rounded-xl hover:shadow-xl bg-primary-200 p-6 font-black uppercase text-primary-400 transition hover:bg-primary-400 hover:text-primary-50"
            @click="modalOpen = true"
            v-text="t('addProducts')"
        />
    </section>
    <NoContent v-else :text="t('noProductsSelected')" class="mt-5">
        <MyButton
            v-if="authStore.hasPermission(PermissionType.ManageCompany)"
            scheme="success"
            @click="modalOpen = true"
            v-text="t('addProducts')"
        />
    </NoContent>
</template>
