<script lang="ts">
interface Props {
    modelValue: boolean
    shipmentId?: uuid
}
</script>

<script setup lang="ts">
import type { uuid } from '@/types/general'

import { useI18n } from 'vue-i18n'
import { watch, toRef, computed } from 'vue'
import Datepicker from '@vuepic/vue-datepicker'

import { image } from '@/utils/assets'
import { useShipmentForm } from '@/hooks/delivery-management/use-shipment-form'
import { useAuthStore } from '@/stores/auth-store'

import ColliPickerModal from '@/components/delivery-management/ColliPickerModal.vue'
import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import ColliCard from '@/components/delivery-management/ColliCard.vue'
import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import ConsignmentNoteSheet from '@/components/e-cmr/ConsignmentNoteSheet.vue'

const { t } = useI18n()
const props = defineProps<Props>()
const authStore = useAuthStore()
const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saved'): void
}>()
const title = computed(() => {
    if (props.shipmentId) {
        return t('updateEntity', { entity: t('consignmentNote') })
    } else {
        return t('createEntity', { entity: t('consignmentNote') })
    }
})

// Use computed to dynamically update `data.address` based on `data.receivingLocationId`
const receiverAddress = computed(() => {
    if (data?.address) return data.address

    const selectedLocation = receiverLocations.value.find(
        (loc) => loc.value === data.receivingLocationId,
    )
    return selectedLocation ? selectedLocation.address : ''
})

const fetchColliEndpoint = 'ecmr.company.collis.index'
const createColliEndpoint = 'ecmr.company.collis.store'
const updateColliEndpoint = 'ecmr.company.collis.update'

const {
    data,
    errors,
    loading,
    shipment,
    colliPickerModalOpen,
    receivers,
    shippers,
    receiverLocations,
    shipperLocations,
    hauliers,
    haulierLocations,
    onSubmit,
    onModalOpen,
    removeColli,
    companyLocations,
    companyIsShipper,
    receiverCompanyChanged,
    senderLocationChanged,
    shipperCompanyChanged,
    haulierCompanyChanged,
} = useShipmentForm({
    shipmentId: toRef(() => props.shipmentId),
    onSave: () => {
        emit('close')
        emit('saved')
    },
    isConsignmentNote: true,
})

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue) onModalOpen()
    },
)
</script>
<template>
    <MyModal :value="props.modelValue" :max-width="1200" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ title }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div ref="modalContent">
                <ConsignmentNoteSheet>
                    <template #sender>
                        <h4
                            class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                            v-text="
                                !companyIsShipper
                                    ? authStore.company.name
                                    : shipment?.sendingCompany.name
                            "
                        />
                        <MySelect
                            v-if="!companyIsShipper"
                            v-model="data.sendingLocationId"
                            :options="companyLocations"
                            :label="t('sendingLocation')"
                            name="sendingLocationId"
                            searchable
                            @change="senderLocationChanged"
                        />
                        <div v-else>
                            <MyInputLabel v-text="t('sendingLocation')" />
                            <h4
                                class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                v-text="shipment?.sendingLocation.name"
                            />
                        </div>
                    </template>
                    <template #receiver>
                        <MySelect
                            v-if="!companyIsShipper"
                            v-model="data.receivingCompanyId"
                            :options="receivers"
                            searchable
                            @change="receiverCompanyChanged"
                        />
                        <div v-else>
                            <h4
                                class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                v-text="shipment?.receivingCompany.name"
                            />
                        </div>
                        <MySelect
                            v-if="!companyIsShipper"
                            v-model="data.receivingLocationId"
                            :options="receiverLocations"
                            name="receivingLocationId"
                            :label="t('receiverLocation')"
                            searchable
                        />

                        <div v-else>
                            <MyInputLabel v-text="t('receivingLocation')" />
                            <h4
                                class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                v-text="shipment?.receivingLocation.name"
                            />
                        </div>
                    </template>
                    <template #shipmentNumber>
                        <h3 class="mb-1">
                            <MyInput
                                v-model="data.shipmentNumber"
                                name="shipmentNumber"
                                type="text"
                                :label="t('shipmentNumber')"
                            />
                        </h3>
                    </template>
                    <template #imageLogo>
                        <img
                            :alt="shipment?.sendingCompany.name"
                            :src="
                                image(
                                    shipment?.sendingCompany.logo ??
                                        'cf6c449d-282b-49e9-838e-eb395c168700',
                                    'thumbnail',
                                )
                            "
                            class="qr w-36 aspect-square self-center bg-primary-400 mt-4"
                        />
                    </template>
                    <template #placeOfDelivery>
                        <MyTextarea
                            v-model="data.address"
                            name="address"
                            class="text-black dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
                            :placeholder="receiverAddress"
                        >
                        </MyTextarea>
                    </template>
                    <template #takenOverPlace>
                        <div class="!m-0 flex w-full space-x-3">
                            <MyTextarea
                                v-model="data.takenOverPlace"
                                name="takenOverPlace"
                                class="text-black dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
                            />
                        </div>
                        <div class="mt-3 flex w-1/3 flex-col space-y-4">
                            <MyInputLabel v-text="t('takenOverAt')" />
                            <Datepicker
                                v-model="data.takenOverAt"
                                class="input-field w-full rounded-xl border border-gray-300"
                                name="takenOverAt"
                                close-on-scroll
                                :enable-time-picker="false"
                                auto-apply
                                clearable
                                auto-position
                                position="left"
                                format="yyyy-MM-dd"
                                :transitions="false"
                                :placeholder="t('selectDate')"
                            />
                            <MyErrorMessage input-name="takenOverAt" :label="t('date')" />
                        </div>
                    </template>
                    <template #documentsAttached>
                        <div class="!m-0 flex w-full space-x-3">
                            <MyTextarea
                                v-model="data.attachedDocuments"
                                name="documentsAttached"
                                class="text-black dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
                            />
                        </div>
                    </template>
                    <template #shipper>
                        <MySelect
                            v-if="!companyIsShipper"
                            v-model="data.shipperCompanyId"
                            name="shipper"
                            group-class="w-full"
                            searchable
                            clear-button
                            :options="shippers"
                            @change="shipperCompanyChanged"
                        />
                        <div v-else>
                            <MyInputLabel v-text="t('shipper')" />
                            <h4
                                class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                v-text="shipment?.shipperCompany?.name"
                            />
                        </div>
                        <MySelect
                            v-if="!companyIsShipper"
                            v-model="data.shipperLocationId"
                            :options="shipperLocations"
                            :label="t('shipperLocation')"
                            searchable
                        />
                        <div v-else>
                            <MyInputLabel v-text="t('shipperLocation')" />
                            <h4
                                class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                v-text="shipment?.shipperLocation?.name"
                            />
                        </div>
                    </template>
                    <template #haulier>
                        <MySelect
                            v-if="!companyIsShipper"
                            v-model="data.haulierCompanyId"
                            name="shipper"
                            group-class="w-full"
                            searchable
                            clear-button
                            :options="hauliers"
                            @change="haulierCompanyChanged"
                        />
                        <div v-else>
                            <MyInputLabel v-text="t('haulier')" />
                            <h4
                                class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                v-text="shipment?.haulierCompany?.name"
                            />
                        </div>
                        <MySelect
                            v-if="!companyIsShipper"
                            v-model="data.haulierLocationId"
                            :options="haulierLocations"
                            :label="t('haulierLocation')"
                            searchable
                        />
                        <div v-else>
                            <MyInputLabel v-text="t('haulierLocation')" />
                            <h4
                                class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                v-text="shipment?.haulierLocation?.name"
                            />
                        </div>
                    </template>
                    <template #shipperInstructions>
                        <MyTextarea
                            v-model="data.shipperInstructions"
                            name="shipperInstructions"
                            class="text-black dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
                        />
                    </template>
                    <template #collis>
                        <div class="mt-2 flex justify-end">
                            <MyButton
                                class="h-10 w-3/12"
                                plain
                                type="button"
                                scheme="primary"
                                @click="colliPickerModalOpen = true"
                            >
                                <mdi:plus-thick class="mr-1" />
                                {{ t('addCollis') }}
                            </MyButton>
                        </div>

                        <ColliPickerModal
                            v-model="colliPickerModalOpen"
                            :existing-collis="data.collis"
                            :fetch-colli-endpoint="fetchColliEndpoint"
                            :create-colli-endpoint="createColliEndpoint"
                            :update-colli-endpoint="updateColliEndpoint"
                            @close="colliPickerModalOpen = false"
                            @save-collis="data.collis = $event"
                        />
                        <main v-if="data?.collis.length > 0"></main>

                        <span
                            v-else
                            class=""
                            v-text="
                                t('noEntitiesForProperty', {
                                    entity: t('colli').toLowerCase(),
                                    property: t('shipment').toLowerCase(),
                                })
                            "
                        />

                        <div
                            v-if="data.collis && data.collis.length > 0"
                            class="mt-4 border-black border p-2 space-y-3"
                        >
                            <ColliCard
                                v-for="entry in data.collis"
                                :key="entry.colli.id"
                                :colli="entry.colli"
                            >
                                <template #suffix>
                                    <div class="flex items-center justify-end w-[20%] ml-auto">
                                        <div class="flex flex-col items-center self-start mr-4">
                                            <span class="text-primary-300" v-text="t('amount')" />
                                            <MyInput
                                                v-model="entry.amount"
                                                class="h-9"
                                                name="amount"
                                                type="number"
                                                min="1"
                                                required
                                            />
                                        </div>

                                        <mdi:trash-can
                                            v-tooltip="t('remove')"
                                            class="h-5 w-5 cursor-pointer text-red-400 flex-shrink-0"
                                            @click="removeColli(entry.colli)"
                                        />
                                    </div>
                                </template>
                            </ColliCard>
                        </div>
                    </template>
                    <template #senderInstructions>
                        <MyTextarea
                            v-model="data.senderInstructions"
                            name="senderInstructions"
                            class="text-black dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
                        />
                    </template>
                    <template #instructionsPayment>
                        <MyTextarea
                            v-model="data.paymentInstructions"
                            name="paymentInstructions"
                            class="text-black dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
                        />
                    </template>
                    <template #specialAgreements>
                        <MyTextarea
                            v-model="data.specialAgreements"
                            name="specialAgreements"
                            class="text-black dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
                        />
                    </template>
                    <template #establishedIn>
                        <MySelect
                            v-if="!companyIsShipper"
                            v-model="data.shipperCompanyId"
                            name="shipper"
                            group-class="w-full"
                            searchable
                            clear-button
                            :options="shippers"
                            @change="shipperCompanyChanged"
                        />
                    </template>
                    <template #establishedOn>
                        <Datepicker
                            v-model="data.plannedAt"
                            class="input-field w-full rounded-xl border border-gray-300"
                            name="plannedAt"
                            close-on-scroll
                            :enable-time-picker="false"
                            auto-apply
                            clearable
                            auto-position
                            position="left"
                            format="yyyy-MM-dd"
                            :transitions="false"
                            :placeholder="t('selectDate')"
                        />
                    </template>
                    <template #senderSignature>
                        <span v-text="''" />
                    </template>
                    <template #driverSignature>
                        <span v-text="''" />
                    </template>
                    <template #receiverSignature>
                        <span v-text="''" />
                    </template>
                </ConsignmentNoteSheet>
            </div>

            <div class="mt-4 flex justify-end gap-6">
                <MyButton
                    :disabled="loading"
                    scheme="primary"
                    v-text="props.shipmentId ? t('update') : t('create')"
                />
            </div>
        </MyForm>
    </MyModal>
</template>
