<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import axios from 'axios'

import { Checklist } from '@/types/general'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'
import { useAuthStore } from '@/stores/auth-store'
import { emitter } from '@/utils/mitt'

import MyButton from '@/components/my-components/MyButton.vue'

export interface Props {
    checklist: Checklist
}

const props = withDefaults(defineProps<Props>(), {})

const { t } = useI18n()
const confirm = useConfirm()
const authStore = useAuthStore()

async function deleteChecklist(checklist: Checklist) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('checklist') }),
            t('deleteChecklistDescription'),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    await axios.delete(
        window.route('company.checklists.destroy', {
            company: authStore.companyId,
            checklist: checklist.id,
        }),
    )
    emitter.emit('fetchChecklists')
}
</script>

<template>
    <div
        class="relative h-auto p-4 min-h-36 w-64 rounded-lg bg-primary-50 shadow hover:cursor-pointer hover:opacity-70 hover:shadow-md dark:bg-dark-500"
    >
        <MyButton
            class="absolute bottom-2 right-2 hidden h-6 w-6 items-center justify-center rounded-full text-xs text-white shadow-lg shadow-primary-600 drop-shadow-lg hover:bg-primary-200 group-hover:block dark:hover:bg-dark-800"
            icon
            size="micro"
            scheme="danger"
            @click.stop="deleteChecklist(checklist)"
        >
            <mdi:trash-can class="mt-1" />
        </MyButton>
        <div class="flex justify-between mb-1.5 text-sm">
            <span
                class="text-lg justify-start break-words font-semibold leading-none uppercase text-primary-400"
                v-text="props.checklist.name"
            />
        </div>
        <div v-for="field in props.checklist.fields" :key="field.id" class="flex justify-between">
            <span class="text-xs justify-start break-words font-semibold" v-text="field.name" />
        </div>
    </div>
</template>
