<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useDashboardStore } from '@/stores/dashboard-store'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'
import { DashboardWidget, WidgetType } from '@/types/dashboard'
import { emitter } from '@/utils/mitt'

import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'

export interface Props {
    widget: DashboardWidget<WidgetType>
    preview?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'settings-opened'): void
}>()

const dashboardStore = useDashboardStore()
const { t } = useI18n()
const confirm = useConfirm()

const settingsOpen = ref(false)

async function deleteWidget() {
    emitter.emit('deleteWidget', props.widget)
}

async function deleteConfirm() {
    try {
        await confirm(t('deleteEntityTitle', { entity: t('widget') }), t('deleteWidgetPrompt'), {
            confirmText: t('confirm'),
            cancelText: t('cancel'),
            confirmButtonScheme: MyButtonScheme.Danger,
        })
        await deleteWidget()
    } catch (e) {
        if (!e) return
        throw e
    }
}

/**
 * Opens or closes the settings panel
 */
function toggleSettings() {
    settingsOpen.value = !settingsOpen.value

    if (settingsOpen.value) {
        emit('settings-opened')
    }
}

watch(
    () => dashboardStore.isEditing,
    () => {
        if (!dashboardStore.isEditing) {
            settingsOpen.value = false
        }
    },
)
</script>

<template>
    <MyPanel
        shadow
        class="overflow-hidden relative"
        :class="{
            'overflow-y-auto cursor-auto': !dashboardStore.isEditing,
            'cursor-move': dashboardStore.isEditing && settingsOpen,
        }"
        panel-class="h-full"
    >
        <MyButton
            v-if="dashboardStore.isEditing && !settingsOpen && !props.preview"
            class="absolute top-7 right-9 z-20"
            shadow
            scheme="primary"
            plain
            icon
            @click="toggleSettings"
        >
            <mdi:cog-outline class="text-lg" />
        </MyButton>

        <MyPanel
            v-if="settingsOpen"
            panel-class="absolute h-full top-0 left-0 mx-auto z-10 p-6 w-full"
        >
            <template #header>
                <div class="flex items-center pb-4 flex-wrap justify-between">
                    <h3 class="relative font-bold uppercase tracking-wide" v-text="t('settings')" />

                    <div
                        class="flex space-x-3 justify-center ml-auto"
                        :class="{ '!ml-0': props.widget.width <= 2 }"
                    >
                        <MyButton
                            icon
                            scheme="danger"
                            class="flex-shrink-0"
                            @click.prevent="deleteConfirm()"
                        >
                            <mdi:trash-can />
                        </MyButton>

                        <MyButton
                            scheme="primary"
                            plain
                            icon
                            class="flex-shrink-0"
                            @click="toggleSettings"
                        >
                            <mdi:close class="text-lg" />
                        </MyButton>
                    </div>
                </div>
            </template>

            <slot name="settings" />
        </MyPanel>
        <slot />
    </MyPanel>
</template>
