import { Marker } from '@/types/general'
import { LocationPoint } from '@/types/company'

export const markerColors = {
    default: '#EA4335',
    green: '#16a085',
    blue: '#037193',
    lightRed: '#ff3f34',
}

export function getMarkerIcon(marker: Marker): H.map.Icon {
    const color = marker.fillColor ?? markerColors.default
    let markerIcon: string
    if (marker.customSvg) {
        markerIcon = marker.customSvg
    } else if (!marker.label) {
        markerIcon = `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 1024 1024" width="24" height="24">
            <path d="M512 85.333333C347.050667 85.333333 213.333333 219.072 213.333333 384 213.333333 548.949333 512 938.666667 512 938.666667S810.666667 548.949334 810.666667 384c0-164.928-133.717333-298.666667-298.666667-298.666667zm0 448a149.333333 149.333333 0 1 1 0-298.666666 149.333333 149.333333 0 0 1 0 298.666666z" fill="${color}"/>
        </svg>`
    } else {
        markerIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="24" height="24">
            <path d="M512,85.333333 C347.050667,85.333333,213.333333,219.072,213.333333,384 C213.333333,548.949333,512,938.666667,512,938.666667 S810.666667,548.949334,810.666667,384 c0,-164.928,-133.717333,-298.666667,-298.666667,-298.666667 m0,448 Z" fill="${color}" />
            <text x="49%" y="41%" text-anchor="middle" fill="white" font-family="Arial, Helvetica, sans-serif" font-size="360" font-weight="bold" dy=".3em" stroke="black" stroke-width="10">${marker.label}</text>
        </svg>`
    }

    const iconSize = 48
    // The anchor point is based on the size property. If that changes, this should too
    // For some reason it's equal to the markers height but just a little above it
    // When the icon size is 48 the shift was measured to 5
    const iconShift = (5 / 48) * iconSize

    return new H.map.Icon(markerIcon, {
        size: { w: iconSize, h: iconSize },
        anchor: { x: iconSize / 2, y: iconSize - iconShift },
    })
}

/**
 * Converts a LocationPoint to a H.geo.IPoint
 */
export function toGeoPoint(location: LocationPoint): H.geo.IPoint {
    return { lat: location.latitude, lng: location.longitude }
}
