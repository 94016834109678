<script setup lang="ts">
import type { PaginatedResponse } from '@/types/general'
import type { StockItem } from '@/types/stock'
import type { ChartData, ChartOptions } from 'chart.js'

import axios from 'axios'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { Bar } from 'vue-chartjs'
import { useI18n } from 'vue-i18n'

import { DashboardWidget, WidgetType } from '@/types/dashboard'
import { useAuthStore } from '@/stores/auth-store'
import { useProductStore } from '@/stores/product-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { ApiPaginationData } from '@/types/general'
import { image } from '@/utils/assets'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { useIsPublicDashboardView } from '@/hooks/use-is-public-dashboard-view'

import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import CompanyLocationOption from '@/components/dashboard/widget-settings/CompanyLocationOption.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'

interface DashboardProductItem {
    id: string
    balance: number
    transit: number
    credit: number
    name: string
    image: string | undefined
    color: string
}

export interface Props {
    widget: DashboardWidget<WidgetType.PackagingStock>
    preview?: boolean
}

const props = defineProps<Props>()

const { t } = useI18n()
const productStore = useProductStore()
const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
const isPublicView = useIsPublicDashboardView()

const loading = ref(false)
const stockItems = ref<StockItem[]>([])
const pagination = ref<ApiPaginationData>()

const selectedLocationId = computed({
    get: () => props.widget.options.selectedLocationId ?? null,
    set: (id) => {
        dashboardStore.updateWidgetOptions(props.widget, { selectedLocationId: id ?? undefined })
    },
})

const hasPagination = computed(() => pagination.value && pagination.value.last_page > 1)
const products = computed<DashboardProductItem[]>(() => {
    if (!stockItems.value) return []

    return stockItems.value.map((balanceItem, index): DashboardProductItem => {
        return {
            id: balanceItem.productId,
            balance: balanceItem.balance,
            transit: balanceItem.transit,
            credit: balanceItem.credit,
            image: balanceItem.productImage,
            name: balanceItem.productName,
            color:
                productStore.colors[balanceItem.productId] ?? productStore.getProductColor(index),
        }
    })
})

const chartData = computed<ChartData<'bar'>>(() => {
    return {
        labels: products.value.map((product) => product.name),
        datasets: [
            {
                label: t('balance'),
                backgroundColor: products.value.map((product) => product.color),
                data: products.value.map((product) => product.balance),
                borderWidth: 0,
                borderRadius: 12,
                borderSkipped: false,
            },
        ],
    }
})
const chartOptions = ref<ChartOptions<'bar'>>({
    scales: {
        x: {
            stacked: true,
            grid: {
                drawOnChartArea: false,
                drawTicks: false,
            },
            ticks: {
                display: false,
            },
        },
        y: {
            grid: {
                drawOnChartArea: false,
                drawTicks: false,
            },
            ticks: {
                display: false,
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            backgroundColor: '#1a1a1a',
            xAlign: 'center',
            yAlign: 'bottom',
            padding: 10,
            caretPadding: 5,
            usePointStyle: true,
        },
    },
})

async function fetchBalance(page = 1) {
    loading.value = true

    const { data: response } = await axios.get<PaginatedResponse<StockItem>>(
        window.route('dashboards.product-stock', {
            dashboard: dashboardStore.currentDashboard!.id,
            shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
            'location-id': selectedLocationId.value,
            'per-page': 6,
            'only-default-products': true,
            page,
        }),
    )

    stockItems.value = response.data
    pagination.value = response.meta

    loading.value = false
}

function previousPage() {
    if (pagination.value && pagination.value.current_page > 1)
        fetchBalance(pagination.value.current_page - 1)
}

function nextPage() {
    if (pagination.value && pagination.value.current_page < pagination.value.last_page)
        fetchBalance(pagination.value.current_page + 1)
}

onBeforeMount(() => fetchBalance())

watch(selectedLocationId, () => fetchBalance())

useMittListener('fetchWidgetData', () => fetchBalance())
</script>

<template>
    <MyWidget :widget="props.widget" :preview="props.preview">
        <LoaderWrapper v-if="loading" :visible="loading" />
        <div class="h-full flex flex-col p-6">
            <div class="rounded-xl bg-primary-100 dark:bg-black p-6 flex flex-col">
                <Bar v-if="chartData" :data="chartData" :options="chartOptions" />
                <div class="mt-4 grid" :class="`grid-cols-${products.length}`">
                    <div
                        v-for="product in products"
                        :key="product.image"
                        class="flex items-start justify-center flex-shrink-0"
                    >
                        <img
                            v-if="product.image"
                            class="w-[60px] object-contain"
                            :src="image(product.image)"
                            :alt="product.name"
                        />
                    </div>
                </div>
            </div>
            <section class="-mx-6 my-6 grow space-y-2 overflow-y-auto px-6">
                <article
                    v-for="product in products"
                    :key="product.id"
                    class="flex items-center gap-3 rounded-xl py-1 pr-3 transition-all"
                >
                    <img
                        v-if="product.image"
                        :alt="product.name"
                        :src="image(product.image, 'thumbnail')"
                        class="max-w-[60px]"
                    />
                    <div class="grow text-sm font-semibold" v-text="product.name" />

                    <p class="font-bold" v-text="product.balance" />

                    <span
                        class="h-[10px] w-[10px] rounded-full"
                        :style="{ backgroundColor: product.color }"
                    />
                </article>
            </section>
            <div v-if="!isPublicView" class="grid grid-cols-[1fr_3fr_1fr] items-center">
                <div class="col-start-2 text-center">
                    <RouterLink
                        class="text-sm font-semibold text-blue-500 underline decoration-2 underline-offset-2"
                        :to="{ name: 'packaging.stock' }"
                        v-text="t('goTo', { to: t('stock') })"
                    />
                </div>
                <div v-if="pagination && pagination.last_page > 1" class="flex justify-end gap-1">
                    <a
                        v-if="hasPagination"
                        class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg transition-all hover:bg-primary-400 hover:text-primary-50"
                        :class="{
                            'pointer-events-none opacity-50': pagination.current_page === 1,
                        }"
                        @click.prevent="previousPage"
                    >
                        <mdi:chevron-left />
                    </a>
                    <a
                        v-if="hasPagination"
                        class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg transition-all hover:bg-primary-400 hover:text-primary-50"
                        :class="{
                            'pointer-events-none opacity-50':
                                pagination.current_page === pagination.last_page,
                        }"
                        @click.prevent="nextPage"
                    >
                        <mdi:chevron-right />
                    </a>
                </div>
            </div>
        </div>

        <template #settings>
            <CompanyLocationOption v-model="selectedLocationId" />
        </template>
    </MyWidget>
</template>
