<script lang="ts">
interface MinimalAlarm extends Pick<Alarm, 'id' | 'ticketNumber'> {}

interface Props {
    modelValue: boolean
    alarm: MinimalAlarm
}
</script>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { watch } from 'vue'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'

import useForm from '@/hooks/use-form'
import { emitter } from '@/utils/mitt'
import { Alarm } from '@/types/alarm'
import { useAuthStore } from '@/stores/auth-store'

import MyInput from '@/components/my-components/form/MyInput.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyModal from '@/components/my-components/MyModal.vue'

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()
const form = useForm({ ticketNumber: props.alarm.ticketNumber })

async function onSubmit() {
    const updatedTicketNumber = form.data.ticketNumber

    if (updatedTicketNumber === props.alarm.ticketNumber) return

    try {
        const response = await form.submit(
            'PUT',
            window.route('company.alarms.update-ticket-number', {
                company: authStore.companyId,
                alarm: props.alarm.id,
            }),
        )

        if (!response) return
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 409) {
            notify({ type: 'error', text: t('alarmAlreadyInDesiredState') })
        }
    }

    emit('update:modelValue', false)
    emitter.emit('fetchAlarms')
}

watch(
    () => props.modelValue,
    () => form.reset({ ticketNumber: props.alarm.ticketNumber }),
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title> {{ t('ticketNumber') }} </template>

        <LoaderWrapper :visible="form.loading.value" />

        <MyForm class="space-y-4" @submit.prevent="onSubmit">
            <MyInput v-model="form.data.ticketNumber" :label="t('ticketNumber')" autofocus />
            <div class="flex flex-row-reverse justify-start space-x-3">
                <MyButton :disabled="form.loading.value" scheme="primary">
                    {{ t('save') }}
                </MyButton>

                <MyButton type="button" @click="emit('update:modelValue', false)">
                    {{ t('cancel') }}
                </MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
