<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { Address } from '@/types/form'
import { vehicleTypeOptions } from '@/utils/vehicles'
import { newMoneyFormatter, numberFormatter } from '@/utils/numbers'
import { ShipmentCostCalculation } from '@/types/delivery-management'
import { ResourceResponse } from '@/types/general'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyAddressInput from '@/components/my-components/form/MyAddressInput.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'

interface Form {
    startLocation: Address | null
    endLocation: Address | null
    mileageCost: number
    vehicleType: number
    currency: string
}

const { data, errors, loading, submit } = useForm<Form>({
    startLocation: null,
    endLocation: null,
    mileageCost: 0,
    vehicleType: 0,
    currency: '',
})

const { t } = useI18n()
const authStore = useAuthStore()
const vehicleTypes = vehicleTypeOptions(t)
const moneyFormatter = newMoneyFormatter(authStore.company.options.currency || 'USD')

const startLocation = ref<string>('')
const endLocation = ref<string>('')
const costCalculation = ref<ShipmentCostCalculation | null>(null)
const error = ref(false)

const currencyCode = computed(() => {
    // We have no way to easily get the symbol from the NumberFormat
    // so we'll have to do it like this
    return moneyFormatter.format(0).replace('0', '').trim()
})

const prices = computed(() => {
    if (!costCalculation.value) return

    const kmTotal = costCalculation.value.mileageCost * costCalculation.value.totalDistanceKM

    let tollTotal = 0

    costCalculation.value.tolls.forEach((toll) => {
        tollTotal += toll?.convertedPrice?.value ?? 0
    })

    return { total: tollTotal + kmTotal, kmTotal, tollTotal }
})

const kmCalculationString = computed(() => {
    if (!costCalculation.value) return ''

    return `${formatPrice(costCalculation.value.mileageCost)} * ${numberFormatter.format(costCalculation.value.totalDistanceKM)}km`
})

function addressChanged(address: Address, locationType: 'startLocation' | 'endLocation') {
    data[locationType] = {
        address: address.address,
        city: address.city,
        zipcode: address.zipcode + '',
        country: address.country,
        latitude: address.latitude,
        longitude: address.longitude,
    }
}

function formatPrice(price: number): string {
    return moneyFormatter.format(price)
}

async function calculateShipmentCost() {
    costCalculation.value = null
    error.value = false

    try {
        const response = await submit<ResourceResponse<ShipmentCostCalculation>>(
            'PUT',
            window.route('dm.company.shipment-cost-calculation', {
                company: authStore.company.id,
            }),
        )

        if (response) {
            costCalculation.value = response.data
        }
    } catch (err) {
        console.error('Error calculating shipment cost:', err)
        error.value = true
    }
}

onMounted(async () => {
    data.mileageCost = authStore.deliveryManagementPreferences()?.mileageCost ?? 0
    data.currency = authStore.company.options.currency || 'USD'
})
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'dm' }" v-text="t('deliveryManagement')" />
        <Breadcrumb current v-text="t('shipmentCostCalculation')" />
    </CrumbsAndActions>

    <RouterView />

    <div class="flex gap-5">
        <div class="w-1/2 border-r-2 border-primary-300 pr-10">
            <MyForm :errors="errors" @submit.prevent="calculateShipmentCost">
                <div class="space-y-4">
                    <div class="flex space-x-3">
                        <MyAddressInput
                            v-model="startLocation"
                            name="startLocation"
                            :label="t('startLocation')"
                            @change="(event) => addressChanged(event, 'startLocation')"
                        />
                        <MyAddressInput
                            v-model="endLocation"
                            name="endLocation"
                            :label="t('endLocation')"
                            @change="(event) => addressChanged(event, 'endLocation')"
                        />
                    </div>
                    <div class="flex space-x-3">
                        <MyInput
                            v-model.number="data.mileageCost"
                            step="any"
                            name="mileageCost"
                            type="number"
                            :label="t('mileageCost')"
                        >
                            <span
                                class="absolute top-0 bottom-0.5 right-2 m-auto h-5"
                                v-text="`${currencyCode}/${t('km')}`"
                            />
                        </MyInput>
                        <MySelect
                            v-model="data.vehicleType"
                            :options="vehicleTypes"
                            group-class="w-full"
                            name="vehicle"
                            :label="t('vehicle')"
                        />
                    </div>
                </div>
                <div class="flex justify-center mt-6">
                    <MyButton scheme="primary" v-text="t('calculate')" />
                </div>
            </MyForm>
        </div>
        <LoaderWrapper :visible="loading" />
        <div v-if="costCalculation" class="w-1/2 px-10">
            <div class="flex justify-between items-center mb-3">
                <span>{{ kmCalculationString }}</span>
                <span>{{ formatPrice(prices!.kmTotal) }}</span>
            </div>

            <div
                v-for="toll in costCalculation.tolls"
                :key="toll.name"
                class="flex justify-between items-center mb-3"
            >
                <span class="capitalize">{{ toll.name }}</span>
                <span v-if="toll?.convertedPrice?.value">
                    {{ formatPrice(toll.convertedPrice.value) }}
                </span>
                <span v-else class="text-gray-500">{{ t('noPrice') }}</span>
            </div>

            <div class="bg-primary-300 w-full h-px mb-3"></div>

            <div class="flex justify-between items-center">
                <span>{{ t('total') }}</span>
                <span>{{ formatPrice(prices!.total) }}</span>
            </div>
        </div>
        <div v-else-if="error" class="w-1/2 h-full text-center mt-6">
            <span class="w-1/2 text-red-500">
                {{ t('noAvailableRoutes') }}
            </span>
        </div>
    </div>
</template>
