<script lang="ts" setup>
import type { PaginatedResponse, uuid } from '@/types/general'

import axios from 'axios'
import { ref, computed, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { useAuthStore } from '@/stores/auth-store'
import { PermissionType, TransportObjectTabs } from '@/types/general'
import { TransportObject, TransportObjectType } from '@/types/transport-object'
import { LicenseType } from '@/types/company'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { printElement } from '@/utils/print-element'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyTabs from '@/components/my-components/MyTabs.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyFilterButton from '@/components/table/MyFilterButton.vue'
import ManageTransportObjectModal from '@/components/ManageTransportObjectModal.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import BasicImportModal from '@/components/import-export/BasicImportModal.vue'

interface Filter {
    vehicles?: boolean
    units?: boolean
    state?: 'all' | 'active' | 'inactive'
}

interface Params extends Record<string, unknown> {
    filter: Filter
}

const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()
const confirm = useConfirm()
const selectedTransportObjectId = ref<uuid>()
const createTransportObjectModalOpen = ref(false)
const updateTransportObjectModalOpen = ref(false)
const printContent = ref<HTMLDivElement>()
const printMode = ref(false)

const currentTab = ref<TransportObjectTabs>(
    (route.query.tab as TransportObjectTabs) ?? TransportObjectTabs.Vehicles,
)

const { data, paginationData, refetch, params, loading, error } = usePaginatedTable<
    TransportObject,
    Params
>(
    async (params, abortController) => {
        const filter: Filter = { ...params.filter }
        if (currentTab.value === TransportObjectTabs.Vehicles) {
            filter.vehicles = true
        } else {
            filter.units = true
        }

        const response = await axios.get<PaginatedResponse<TransportObject>>(
            window.route('company.transport-objects.index', { company: authStore.companyId }),
            { params: { ...params, filter }, signal: abortController.signal },
        )

        return response.data
    },
    { filter: { state: 'active' } },
)

const selectedTransportObject = computed(() => {
    if (!selectedTransportObjectId.value) return undefined
    return data.value!.find(({ id }) => id === selectedTransportObjectId.value)
})

const importEndpoint = computed(() =>
    window.route('company.transport-objects.import', { company: authStore.companyId }),
)
const isNotReadonly = computed(() => !authStore.isReadonly.damageReport)

function setFilter(value: string | number | null, filterName: string) {
    params.value.filter[filterName] = value
    params.value.page = 1
}

function setTab(tab: TransportObjectTabs) {
    currentTab.value = tab
    refetch()
}

function selectTransportObject(id: string) {
    selectedTransportObjectId.value = id
}

async function print(transportObject: TransportObject) {
    selectedTransportObjectId.value = transportObject.id
    printMode.value = true
    await nextTick()
    await printElement(printContent.value!)
    selectedTransportObjectId.value = undefined
    printMode.value = false
}

function updateTransportObject(transportObject: TransportObject) {
    selectTransportObject(transportObject.id)
    updateTransportObjectModalOpen.value = true
}

async function createTransportObjectModalClosed() {
    createTransportObjectModalOpen.value = false
    selectedTransportObjectId.value = undefined
}

async function updateTransportObjectModalClosed() {
    updateTransportObjectModalOpen.value = false
    selectedTransportObjectId.value = undefined
}

function deletedTemplateDescription(transportObject: TransportObject): string {
    const type = transportObject.type === TransportObjectType.Vehicle ? t('vehicle') : t('unit')

    return t('deletedTemplateOnTransportObject', { type: type.toLowerCase() })
}

async function deleteTransportObject(transportObject: TransportObject) {
    const entity = transportObject.type === TransportObjectType.Vehicle ? t('vehicle') : t('unit')
    try {
        await confirm(
            t('deleteEntityTitle', { entity }),
            t('deleteTransportObjectDescription', { entity }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    await axios.delete(
        window.route('company.transport-objects.destroy', {
            company: authStore.companyId,
            transport_object: transportObject.id,
        }),
    )
    await refetch()
}

useMittListener('incidentFixed', () => refetch())
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb
            v-if="route.fullPath.includes('damage-reports')"
            :to="{ name: 'dmr.reports' }"
            v-text="t('reports')"
        />
        <Breadcrumb v-else :to="{ name: 'settings' }" v-text="t('settings')" />
        <Breadcrumb current v-text="t('vehiclesAndUnits')" />

        <template v-if="authStore.hasPermission(PermissionType.ManageCompany)" #actions>
            <BasicImportModal
                v-if="isNotReadonly"
                :title="t('importModel', { model: t('vehiclesAndUnits') })"
                template="transportObjects"
                :endpoint="importEndpoint"
                @import-finished="refetch()"
            />

            <MyButton
                v-if="currentTab === TransportObjectTabs.Vehicles && isNotReadonly"
                plain
                scheme="primary"
                size="small"
                @click="createTransportObjectModalOpen = true"
            >
                <mdi:plus-thick class="mr-1" />
                {{ t('addVehicle') }}
            </MyButton>

            <MyButton
                v-if="currentTab === TransportObjectTabs.Units && isNotReadonly"
                plain
                scheme="primary"
                size="small"
                @click="createTransportObjectModalOpen = true"
            >
                <mdi:plus-thick class="mr-1" />
                {{ t('addUnit') }}
            </MyButton>
        </template>
    </CrumbsAndActions>

    <RouterView />

    <MyTabs
        :tabs="{ vehicles: t('vehicles'), units: t('units') }"
        shadow
        @change="setTab($event as TransportObjectTabs)"
    >
        <MyTable
            :error="error"
            :get-data="refetch"
            :loading="loading"
            :pagination-data="paginationData"
            :rows="data"
            :table-id="currentTab === TransportObjectTabs.Vehicles ? 'vehicles' : 'units'"
            :entity-name="t(currentTab === TransportObjectTabs.Vehicles ? 'vehicles' : 'units')"
        >
            <template #filters>
                <MyFilterButton
                    filter-name="state"
                    value="all"
                    @selected="setFilter"
                    v-text="t('all')"
                />
                <MyFilterButton
                    filter-name="state"
                    value="active"
                    default
                    @selected="setFilter"
                    v-text="t('active')"
                />
                <MyFilterButton
                    filter-name="state"
                    value="inactive"
                    @selected="setFilter"
                    v-text="t('inactive')"
                />
            </template>

            <template #actionRow="{ row }">
                <RouterLink
                    :to="{
                        name: route.name?.toString().includes('dmr')
                            ? 'dmr.transport-objects.show'
                            : 'transport-objects.show',
                        params: { transportObjectId: row.id },
                        query: route.query,
                    }"
                >
                    <ActionRowItem>
                        <mdi:eye />
                    </ActionRowItem>
                </RouterLink>
            </template>
            <template
                v-if="authStore.hasPermission(PermissionType.ManageCompany)"
                #actionMenu="{ row }"
            >
                <ActionMenuItem
                    v-if="authStore.hasLicense(LicenseType.TrailerVinScanning)"
                    @click="print(row)"
                >
                    <span class="mr-2"><mdi:qrcode /></span>
                    {{ t('printQrCode') }}
                </ActionMenuItem>

                <ActionMenuItem v-if="isNotReadonly" @click="updateTransportObject(row)">
                    <span class="mr-2"><mdi:pencil /></span>
                    {{ t('edit') }}
                </ActionMenuItem>

                <ActionMenuItem v-if="isNotReadonly" @click="deleteTransportObject(row)">
                    <span class="mr-2"><mdi:trash-can /></span>
                    {{ t('remove') }}
                </ActionMenuItem>
            </template>

            <MyTableColumn :name="t('active')" :width="100" property="active" boolean-column />
            <MyTableColumn :name="t('registrationNumber')" property="registrationNumber" />
            <MyTableColumn
                v-if="authStore.hasLicense(LicenseType.TransportObjectOwnerCompany)"
                :name="t('owner')"
                property="ownerCompany.name"
            />
            <MyTableColumn :name="t('owner')" property="ownerName" hidden />
            <MyTableColumn :name="t('template')" property="templateName" />
            <template #templateName="{ row }">
                <div class="flex">
                    <span class="w-full truncate" v-text="row.templateName" />

                    <mdi:trash-can
                        v-if="row.templateDeleted"
                        v-tooltip="deletedTemplateDescription(row)"
                        class="ml-2 text-red-400"
                        style="width: 20px"
                    />
                </div>
            </template>
            <MyTableColumn :name="t('nickname')" property="nickname" hidden />
            <MyTableColumn :name="t('department')" property="department" hidden />
            <MyTableColumn :name="t('freightWeight')" property="freightWeight" hidden />
            <MyTableColumn :name="t('haulier')" property="haulierCompany.name" hidden />
            <MyTableColumn :name="t('manufacturer')" property="manufacturer" hidden />
            <MyTableColumn :name="t('model')" property="model" hidden />
            <MyTableColumn :name="t('name')" property="name" hidden />
            <MyTableColumn :name="t('note')" property="note" hidden />
            <MyTableColumn :name="t('registrationDate')" property="registrationDate" />
            <MyTableColumn :name="t('service')" property="service" hidden />
            <MyTableColumn :name="t('vin')" property="vin" hidden />
            <MyTableColumn :name="t('weight')" property="weight" hidden />
            <MyTableColumn :name="t('phoneNumber')" property="phoneNumber" hidden />
            <MyTableColumn :name="t('ownerName')" property="ownerName" />
            <MyTableColumn :name="t('mot')" property="mot" hidden />
            <MyTableColumn :name="t('tir')" property="tir" hidden />
            <MyTableColumn :name="t('length')" property="length" hidden />
            <MyTableColumn :name="t('width')" property="width" hidden />
            <MyTableColumn :name="t('height')" property="height" hidden />
            <MyTableColumn :name="t('maxEur')" property="maxEur" hidden />
            <MyTableColumn :name="t('maxLdr')" property="maxLdr" hidden />
            <MyTableColumn :name="t('maxM3')" property="maxM3" hidden />
        </MyTable>
    </MyTabs>

    <ManageTransportObjectModal
        v-model="createTransportObjectModalOpen"
        :type="
            currentTab === TransportObjectTabs.Vehicles
                ? TransportObjectType.Vehicle
                : TransportObjectType.Unit
        "
        @close="createTransportObjectModalClosed"
        @saved="refetch()"
    />

    <ManageTransportObjectModal
        v-model="updateTransportObjectModalOpen"
        :transport-object="selectedTransportObject"
        :type="selectedTransportObject?.type ?? TransportObjectType.Vehicle"
        @close="updateTransportObjectModalClosed"
        @saved="refetch()"
    />

    <div v-if="selectedTransportObject && printMode" ref="printContent" class="hidden w-full">
        <div class="flex flex-col justify-center break-words">
            <div class="w-[300px] text-center">
                <img
                    class="qrcode"
                    :data-url="selectedTransportObject.registrationNumber"
                    style="width: 300px; height: 300px"
                />
                <h3 class="-mt-5 text-2xl font-semibold">
                    {{ selectedTransportObject?.registrationNumber }}
                </h3>
            </div>
        </div>
    </div>
</template>
