<script lang="ts" setup>
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { DashboardWidget, WidgetType } from '@/types/dashboard'
import { Marker } from '@/types/general'
import { useOngoingTourStore } from '@/stores/ongoing-tour-store'
import { useOngoingTourWidgetData } from '@/hooks/delivery-management/use-ongoing-tour-widget-data'

import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MapComponent from '@/components/MapComponent.vue'

interface Props {
    widget: DashboardWidget<WidgetType.DeliveryManagementOngoingTourMap>
    preview?: boolean
}

const props = defineProps<Props>()

const { t } = useI18n()
const ongoingTourStore = useOngoingTourStore()

const ongoingTourData = useOngoingTourWidgetData(props.widget)

const markers = computed<Marker[]>(() => {
    return ongoingTourData.tours.value
        .map((tour, index) => {
            const isHighlighted = ongoingTourStore.highlightedTourId === tour.id

            return {
                location: tour.latestLocation ?? { latitude: 0, longitude: 0 },
                label: (index + 1).toString(),
                markerId: tour.id,
                customSvg: markerSvg(index + 1, isHighlighted),
            }
        })
        .filter((marker) => marker.location.longitude !== 0)
})

function markerSvg(label: number, highlighted: boolean): string {
    return `<svg viewBox="0 0 1024 1024" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <circle cx="512" cy="512" r="400" fill="white" stroke="green" stroke-width="64"/>
        <text x="50%" y="50%" font-family="Arial, Helvetica, sans-serif" font-size="360" font-weight="bold" fill="${highlighted ? '#069468' : 'black'}" text-anchor="middle" dominant-baseline="central">${label}</text>
    </svg>`
}

watch(() => props.widget.options, ongoingTourData.fetchOngoingTours)
</script>

<template>
    <MyWidget
        :widget="props.widget"
        :preview="props.preview"
        @settings-opened="ongoingTourData.hubs.fetch()"
    >
        <MapComponent class="w-full h-full" :markers="markers" animated force-satellite />

        <div
            v-if="ongoingTourData.tours.value.length > 0"
            class="absolute text-xs right-0 top-0 bg-white dark:bg-primary-300 rounded-l-lg p-3"
        >
            <div
                v-for="(tour, index) in ongoingTourData.tours.value"
                :key="tour.id"
                class="flex transition-colors"
                :class="{ 'text-green-500': ongoingTourStore.highlightedTourId === tour.id }"
                @mouseenter="ongoingTourStore.setHighlightedTour(tour)"
                @mouseleave="ongoingTourStore.resetHighlightedTour()"
            >
                <span class="font-semibold mr-1" v-text="index + 1" />
                - {{ tour.name }}
                <mdi:offline
                    v-if="tour.latestLocation === null"
                    v-tooltip="t('noTrackingData')"
                    class="ml-1"
                />
            </div>
        </div>

        <div
            v-if="ongoingTourData.tours.value.length === 0 && !ongoingTourData.loading.value"
            class="absolute right-0 top-0 flex justify-center items-center w-full h-full text-white bg-black/80 text-2xl uppercase"
            v-text="t('noOngoingTours')"
        />

        <template #settings>
            <MySelect
                v-model="ongoingTourData.hubId.value"
                :options="ongoingTourData.hubs.items.value"
                :loading="ongoingTourData.hubs.fetching.value"
                group-class="mt-4 mx-auto"
                clear-button
                :label="t('hub')"
                @focus="ongoingTourData.hubs.fetch()"
            />
        </template>
    </MyWidget>
</template>
