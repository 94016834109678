import {
    AlarmType,
    Alarm,
    CustomerBalanceLimitAlarm,
    IncidentAlarm,
    TireThreadAlarm,
    DeviationCreatedAlarm,
    TicketCreatedAlarm,
    TicketUpdatedAlarm,
    IncorrectTransportObjectAlarm,
    ChecklistFieldAlarm,
} from '@/types/alarm'

export const alarmColors: Record<AlarmType, string> = {
    [AlarmType.PackagingCustomerBalanceLimit]: 'bg-red-400',
    [AlarmType.DamageReportIncident]: 'bg-red-400',
    [AlarmType.DamageReportField]: 'bg-yellow-400',
    [AlarmType.DamageReportTireThread]: 'bg-dark-500',
    [AlarmType.DamageReportIncorrectTransportObject]: 'bg-red-400',
    [AlarmType.DeliveryDeviationCreated]: 'bg-red-400',
    [AlarmType.DeliveryTicketCreated]: 'bg-red-400',
    [AlarmType.DeliveryTicketUpdated]: 'bg-yellow-400',
    [AlarmType.DeliveryDeviationMankoColliScan]: 'bg-yellow-400',
}

export const alarmTranslations: Record<AlarmType, string> = {
    [AlarmType.PackagingCustomerBalanceLimit]: 'customerBalanceLimitAlarm',
    [AlarmType.DamageReportIncident]: 'incidentAlarm',
    [AlarmType.DamageReportField]: 'fieldAlarm',
    [AlarmType.DamageReportTireThread]: 'tireThreadAlarm',
    [AlarmType.DamageReportIncorrectTransportObject]: 'incorrectTransportObjectAlarm',
    [AlarmType.DeliveryDeviationCreated]: 'deviationAlarm',
    [AlarmType.DeliveryTicketCreated]: 'ticketCreatedAlarm',
    [AlarmType.DeliveryTicketUpdated]: 'ticketUpdatedAlarm',
    [AlarmType.DeliveryDeviationMankoColliScan]: 'mankoColliScannedAsDelivered',
}

export function isIncidentAlarm(alarm: Alarm): alarm is IncidentAlarm {
    return alarm.type === AlarmType.DamageReportIncident
}

export function isTireThreadAlarm(alarm: Alarm): alarm is TireThreadAlarm {
    return alarm.type === AlarmType.DamageReportTireThread
}

export function isChecklistFieldAlarm(alarm: Alarm): alarm is ChecklistFieldAlarm {
    return alarm.type === AlarmType.DamageReportField
}

export function isCustomerBalanceLimitAlarm(alarm: Alarm): alarm is CustomerBalanceLimitAlarm {
    return alarm.type === AlarmType.PackagingCustomerBalanceLimit
}

export function isDeviationCreatedAlarm(alarm: Alarm): alarm is DeviationCreatedAlarm {
    return alarm.type === AlarmType.DeliveryDeviationCreated
}

export function isTicketCreatedAlarm(alarm: Alarm): alarm is TicketCreatedAlarm {
    return alarm.type === AlarmType.DeliveryTicketCreated
}

export function isTicketUpdatedAlarm(alarm: Alarm): alarm is TicketUpdatedAlarm {
    return alarm.type === AlarmType.DeliveryTicketUpdated
}

export function isIncorrectTransportObjectAlarm(
    alarm: Alarm,
): alarm is IncorrectTransportObjectAlarm {
    return alarm.type === AlarmType.DamageReportIncorrectTransportObject
}
