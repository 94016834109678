<script lang="ts">
export interface Props {
    checklists: LinkedChecklist[]
}
</script>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { image as imageHelper } from '@/utils/assets'
import { LinkedChecklist } from '@/types/general'

import ChecklistFieldValue from '@/components/ChecklistFieldValue.vue'
import MyImageViewer from '@/components/my-components/MyImageViewer.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'

const { t } = useI18n()

const props = withDefaults(defineProps<Props>(), {})
</script>

<template>
    <div v-for="checklist in props.checklists" :key="checklist.id">
        <span
            class="flex flex-shrink-0 items-center font-semibold text-sm uppercase text-primary-400"
            v-text="checklist.name"
        />

        <MyPanel v-if="checklist.fields.length > 0" panel-class="w-full overflow-auto" bordered>
            <table class="table-fixed w-full">
                <thead>
                    <tr
                        class="border-b border-primary-200 bg-primary-100 text-left text-xs dark:border-dark-600 dark:bg-dark-500"
                    >
                        <th class="w-2/5 p-2.5 font-semibold" v-text="t('name')" />
                        <th
                            class="w-2/4 border-x border-primary-200 p-2.5 font-semibold"
                            v-text="t('value')"
                        />

                        <th class="w-1/4 p-2.5 font-semibold" v-text="t('images')" />
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="field in checklist.fields"
                        :key="field.id"
                        class="break-inside-avoid border-t border-primary-200 first:border-t-0 odd:bg-primary-100 dark:odd:bg-dark-400"
                    >
                        <td
                            v-truncate-tooltip="field.name"
                            class="truncate border-r border-primary-200 p-2.5 text-sm"
                            v-text="field.name"
                        />

                        <ChecklistFieldValue
                            :field="field"
                            :value="field.value"
                            :not-possible-to-check="field.notPossibleToCheck"
                        />

                        <td class="p-2.5">
                            <MyImageViewer>
                                <img
                                    v-for="image in field.images"
                                    :key="image.id"
                                    class="my-1 w-10 self-center rounded hover:cursor-pointer"
                                    :src="imageHelper(image as unknown as string, 'thumbnail')"
                                    :data-src="imageHelper(image as unknown as string, 'public')"
                                />
                            </MyImageViewer>
                        </td>
                    </tr>
                </tbody>
            </table>
        </MyPanel>
        <span v-else class="text-center" v-text="t('noFieldsAdded')" />
    </div>
</template>
