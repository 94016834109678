<script lang="ts" setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { AlarmType } from '@/types/damage-report'
import {
    checklistFieldTypes,
    alarmBooleanTypes,
    alarmTextTypes,
    alarmNumberTypes,
} from '@/utils/type-translations'
import { ChecklistField, ChecklistFieldType, ElementInputEvent } from '@/types/general'
import { DropdownOption } from '@/types/inputs'

import TranslationModal from '@/components/TranslationModal.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyButton from '@/components/my-components/MyButton.vue'

export interface Props {
    draggable: boolean
}

const emit = defineEmits<{ (e: 'remove', value: ChecklistField): void }>()
const field = defineModel<ChecklistField>({ required: true })
const props = defineProps<Props>()

const { t } = useI18n()

const alarmType = ref<string | null | AlarmType>(field.value.alarmType)
const alarmValue = ref<number>(field.value.alarmValue || 0)
const fieldTranslationModalOpen = ref(false)

const hasAlarmInput = computed(() => {
    return (
        field.value.alarmType !== null &&
        field.value.type !== ChecklistFieldType.Boolean &&
        field.value.type !== ChecklistFieldType.Text
    )
})

const visibleAlarmTypes = computed(() => {
    let alarmTypes: Partial<Record<string | number | 'null', string>> = {}
    if (field.value.type === ChecklistFieldType.Text) {
        alarmTypes = alarmTextTypes
    } else if (field.value.type === ChecklistFieldType.Boolean) {
        alarmTypes = alarmBooleanTypes
    } else {
        alarmTypes = alarmNumberTypes
    }
    return Object.keys(alarmTypes).map((key) => {
        return {
            label: t(alarmTypes[key] as unknown as AlarmType),
            value: Number.isNaN(parseFloat(key)) ? key : parseFloat(key),
        }
    })
})
const tankLevelOptions = computed<DropdownOption[]>(() => {
    if (field.value.type !== ChecklistFieldType.TankLevel) return []

    return [
        { label: '0', value: 0 },
        { label: '1/4', value: 0.25 },
        { label: '2/4', value: 0.5 },
        { label: '3/4', value: 0.75 },
        { label: '1', value: 1 },
    ]
})

function removeField() {
    emit('remove', { ...field.value })
}

function alarmTypeChanged() {
    let alarmTypeValue = alarmType.value as AlarmType | null
    let alarmValue = field.value.alarmValue

    if (alarmTypeValue === undefined) {
        alarmTypeValue = null
    }

    if (field.value.type === ChecklistFieldType.Boolean && alarmTypeValue !== null) {
        alarmTypeValue = AlarmType.Equals
        alarmValue = alarmType.value === 'true' ? 1 : 0
    }

    field.value.alarmType = alarmTypeValue
    field.value.alarmValue = alarmValue
}

function alarmValueChanged(value: string | number) {
    field.value.alarmValue = parseFloat(value + '')
}

onBeforeMount(() => {
    if (field.value.type === ChecklistFieldType.Boolean && field.value.alarmType) {
        alarmType.value = field.value.alarmValue === 1 ? 'true' : 'false'
    }
})
</script>

<template>
    <div
        class="flex h-auto w-[238px] justify-center rounded-lg bg-primary-50 shadow dark:bg-dark-500"
    >
        <TranslationModal v-model="field.translations" v-model:open="fieldTranslationModalOpen" />

        <div class="flex w-full flex-col space-y-6 p-5">
            <div class="flex flex-col">
                <div class="flex items-center">
                    <mdi:drag
                        v-if="props.draggable"
                        class="handle mr-1 cursor-grab text-xl text-dark-700 transition dark:text-white"
                    />

                    <span
                        class="w-full font-semibold uppercase text-primary-400"
                        v-text="field.translations.en"
                    />

                    <MyButton
                        v-tooltip="t('translateField')"
                        plain
                        size="micro"
                        type="button"
                        class="!h-4 !p-0 hover:bg-primary-200"
                        @click="fieldTranslationModalOpen = true"
                    >
                        <mdi:translate class="inline-block h-4 text-blue-400" />
                    </MyButton>
                </div>
                <span
                    class="text-xs font-medium text-primary-300"
                    v-text="t(checklistFieldTypes[field.type])"
                />
            </div>

            <div class="flex flex-col space-y-1.5">
                <MyCheckbox
                    v-model="field.required"
                    class="flex flex-row-reverse justify-between"
                    name="required"
                    :label="t('required')"
                />
                <MyCheckbox
                    v-model="field.imageRequired"
                    class="flex flex-row-reverse justify-between"
                    name="imageRequired"
                    :label="t('imageRequired')"
                />
                <div class="flex space-x-2 items-center">
                    <span class="text-sm" v-text="t('hideNotPossibleToCheck')" />
                    <mdi:information
                        v-tooltip="t('hideNotPossibleToCheckExplanation')"
                        class="min-h-4 min-w-4"
                    />
                    <MyCheckbox
                        v-model="field.hideNotPossibleToCheck"
                        name="hideNotPossibleToCheck"
                    />
                </div>
            </div>

            <div class="flex flex-col space-y-1">
                <div>
                    <MySelect
                        v-model="alarmType"
                        name="type"
                        :wrapper-class="
                            hasAlarmInput ? 'rounded-b-none dark:border-b dark:border-b-white' : ''
                        "
                        :label="t('alarm')"
                        :options="visibleAlarmTypes"
                        :placeholder="t('none')"
                        @change="alarmTypeChanged"
                    />
                    <MyInput
                        v-if="hasAlarmInput && field.type == ChecklistFieldType.Number"
                        :value="field.alarmValue"
                        class="rounded-t-none border-t-0"
                        name="value"
                        type="number"
                        @input="alarmValueChanged(($event as ElementInputEvent).target.value)"
                    />
                    <MySelect
                        v-if="hasAlarmInput && field.type == ChecklistFieldType.TankLevel"
                        v-model="alarmValue"
                        :options="tankLevelOptions"
                        wrapper-class="rounded-t-none dark:border-t dark:border-t-white"
                        @change="alarmValueChanged(($event || 0) as number)"
                    />
                </div>
                <MyButton
                    class="inline-flex pt-1 text-xs font-semibold text-red-600"
                    type="button"
                    plain
                    @click="removeField()"
                    v-text="t('remove')"
                />
            </div>
        </div>
    </div>
</template>
