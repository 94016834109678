<script lang="ts">
export interface Props {
    transportObject: ExtendedTransportObject
    template: Template
    reportId?: uuid
    sectionNotes?: ReportNote[]
    showFixedByDefault?: boolean
    printImage?: MasterTemplateImage
    specificIncidentId?: uuid
    alarms?: ReportAlarm[]
}

export interface VisibleSection {
    incidents: ReportIncident[]
    note: string | null
}
</script>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import {
    ReportAlarm,
    MasterTemplateImage,
    MasterTemplateSection,
    ReportNote,
    Template,
} from '@/types/damage-report'
import { getIncidentTypeColor, incidentTypes } from '@/utils/damage-report'
import { localeDate } from '@/utils/dates'
import { image as imageHelper } from '@/utils/assets'
import { uuid } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { ExtendedTransportObject, IncidentType, ReportIncident } from '@/types/transport-object'

import MyImageViewer from '@/components/my-components/MyImageViewer.vue'
import FixIncidentModal from '@/components/damage-reports/FixIncidentModal.vue'
import ActionRow from '@/components/table/internal/ActionRow.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import MasterTemplateImageView from '@/components/damage-reports/MasterTemplateImageView.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import TemplateSectionView from '@/components/damage-reports/TemplateSectionView.vue'
import UpdateAlarmStateModal from '@/components/alarms/UpdateAlarmStateModal.vue'

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()

const fixIncidentModalOpen = ref(false)
const selectedIncidentType = ref<IncidentType>()
const selectedSection = ref<MasterTemplateSection>()
const selectedImage = ref(props.printImage ?? props.template.masterTemplate?.images![0])
const selectedIncidentId = ref<uuid>()
const showFixedIncidents = ref(props.showFixedByDefault ?? false)
const showPreviousIncidents = ref(!props.reportId)
const alarmAction = ref<'resolve' | 'mute'>('resolve')
const alarmStateModalOpen = ref(false)
const selectedAlarm = ref<ReportAlarm>()

const alarmByIncident = computed(() => {
    const alarms: Record<uuid, ReportAlarm> = {}
    for (const alarm of props.alarms ?? []) {
        if (!alarm.description.incidentId) continue
        alarms[alarm.description.incidentId] = alarm
    }

    return alarms
})
const images = computed(() => props.template.masterTemplate?.images ?? [])
const imageBySection = computed(() => {
    const sections: Record<uuid, uuid> = {}
    for (const image of images.value) {
        for (const section of image.sections) {
            sections[section.id] = image.id
        }
    }

    return sections
})
const incidentTypeCountsPerImage = computed(() => {
    const incidents: Record<uuid, Partial<Record<IncidentType, number>>> = {}

    for (const incident of props.transportObject.incidents ?? []) {
        if (!showPreviousIncidents.value && incident.reportId !== props.reportId) continue
        if (!showFixedIncidents.value && incident.fixedAt) continue

        const imageId = imageBySection.value[incident.masterTemplateSectionId]
        incidents[imageId] ??= {}
        incidents[imageId][incident.type] ??= 0
        incidents[imageId]![incident.type]! += 1
    }

    return incidents
})
const visibleIncidents = computed(() => {
    const selectedSectionsIds = selectedImage.value?.sections.map((section) => section.id) ?? []
    const incidents = props.transportObject.incidents ?? []

    return incidents.filter((incident) => {
        if (!showPreviousIncidents.value && incident.reportId !== props.reportId) return false

        if (props.specificIncidentId && incident.id !== props.specificIncidentId) return false

        if (
            selectedIncidentType.value !== undefined &&
            incident.type !== selectedIncidentType.value
        ) {
            return false
        }

        if (
            selectedSection.value !== undefined &&
            incident.masterTemplateSectionId !== selectedSection.value?.id
        ) {
            return false
        }

        if (!selectedSectionsIds.includes(incident.masterTemplateSectionId)) return false

        if (!showFixedIncidents.value && incident.fixedAt) return false

        return true
    })
})

const visibleSections = computed(() => {
    const sections: Record<uuid, VisibleSection> = {}

    for (const note of props.sectionNotes ?? []) {
        if (selectedSection.value && selectedSection.value.id !== note.masterTemplateSectionId) {
            continue
        }

        sections[note.masterTemplateSectionId] = {
            incidents: [],
            note: note.value,
        }
    }

    for (const incident of visibleIncidents.value) {
        sections[incident.masterTemplateSectionId] ??= {
            incidents: [],
            note: null,
        }
        sections[incident.masterTemplateSectionId].incidents.push(incident)
    }

    return sections
})

const hasIncidents = computed(() => {
    return visibleIncidents.value?.length > 0
})

function selectImage(image: MasterTemplateImage) {
    selectedImage.value = image
    selectedIncidentType.value = undefined
}

function selectSection(section: MasterTemplateSection | undefined) {
    if (props.specificIncidentId) return
    if (selectedSection.value === section) {
        selectedSection.value = undefined
        return
    }
    selectedSection.value = section
}

function setIncidentType(type: string | undefined) {
    selectedIncidentType.value = parseInt(type as string)
    if (Number.isNaN(selectedIncidentType.value)) {
        selectedIncidentType.value = undefined
    }
}

function incidentTooltip(incident: ReportIncident): string {
    let content = t('fixedByExplanation', { name: incident.fixedByUser?.name })
    if (incident.fixedNote) {
        content += `\n${t('note')}: ${incident.fixedNote}`
    }

    return content
}

function fixIncident(incidentId: uuid) {
    fixIncidentModalOpen.value = true
    selectedIncidentId.value = incidentId
    selectedAlarm.value = alarmByIncident.value[incidentId]
}

function muteAlarm(alarm: ReportAlarm) {
    alarmAction.value = 'mute'
    alarmStateModalOpen.value = true
    selectedAlarm.value = alarm
}

onBeforeMount(() => {
    if (props.specificIncidentId) {
        const incidents = props.transportObject.incidents ?? []
        const incident = incidents.find((incident) => incident.id === props.specificIncidentId)
        const imageId = imageBySection.value[incident?.masterTemplateSectionId ?? '']
        if (imageId) {
            const image = images.value.find((image) => image.id === imageId)
            selectedImage.value = image ?? selectedImage.value
        }
    }
})
</script>

<template>
    <div class="w-full">
        <h3
            v-if="!props.specificIncidentId"
            class="mb-6 text-lg font-semibold uppercase text-primary-400 print:hidden"
            v-text="t('incidents')"
        />

        <div>
            <div
                v-if="!props.specificIncidentId"
                class="mt-2 flex justify-start space-x-2 print:hidden"
            >
                <MyButton
                    plain
                    type="button"
                    class="h-6 !rounded-lg bg-primary-300 !text-xs text-white"
                    @click="setIncidentType(undefined)"
                >
                    {{ t('all') }}
                </MyButton>
                <MyButton
                    v-for="(translation, incidentType) in incidentTypes"
                    :key="incidentType"
                    plain
                    type="button"
                    :class="[
                        getIncidentTypeColor(incidentType as unknown as IncidentType),
                        incidentType == selectedIncidentType
                            ? 'opacity-60 shadow shadow-black ring-1 ring-gray-600'
                            : '',
                    ]"
                    class="h-6 !rounded-lg !text-xs text-white"
                    @click="setIncidentType(incidentType as unknown as string)"
                >
                    {{ t(translation) }}
                </MyButton>
            </div>

            <div v-if="!props.specificIncidentId" class="my-4 flex space-x-2 print:hidden">
                <MyCheckbox v-model="showFixedIncidents" :label="t('showFixedIncidents')" />

                <MyCheckbox
                    v-if="props.reportId"
                    v-model="showPreviousIncidents"
                    :label="t('showPreviousIncidents')"
                />
            </div>

            <TemplateSectionView
                v-if="selectedImage"
                v-model="selectedImage.sections"
                class="mt-1 mb-3"
                :filter="selectedIncidentType"
                :incidents="visibleIncidents"
                :template-image="selectedImage"
                :company-logo="props.transportObject.companyLogo ?? null"
                @section-clicked="selectSection"
            />

            <div
                v-if="images.length > 1 && !props.printImage && !props.specificIncidentId"
                class="flex space-x-2 overflow-x-auto"
            >
                <div
                    v-for="image in images"
                    :key="image.id"
                    class="relative cursor-pointer rounded-lg p-2"
                    :class="{
                        'bg-primary-200 dark:bg-primary-400': image.id === selectedImage?.id,
                        'bg-gray-200 dark:bg-gray-700': image.id !== selectedImage?.id,
                    }"
                    @click="selectImage(image)"
                >
                    <MasterTemplateImageView
                        class="w-36 rounded hover:cursor-pointer"
                        :image="image"
                        :company-logo="props.transportObject.companyLogo ?? null"
                    />

                    <div class="mt-2 text-center" v-text="image.name" />

                    <div
                        class="absolute right-1 top-1 flex items-start justify-center space-x-0.5 text-white"
                    >
                        <div
                            v-for="(amount, incidentType) in incidentTypeCountsPerImage[image.id]"
                            :key="incidentType"
                            class="mb-1 rounded-lg py-1 px-2 text-center text-[10px] leading-snug"
                            :class="getIncidentTypeColor(incidentType)"
                            v-text="amount"
                        />
                    </div>
                </div>
            </div>

            <div v-if="selectedSection" class="mt-3 flex flex-col items-center justify-center">
                <span class="text-sm font-semibold" v-text="t('selected')" />
                <div class="flex items-center space-x-1 text-lg uppercase text-primary-400">
                    <span
                        class="font-semibold"
                        v-text="t('section') + ' ' + selectedSection.name"
                    />
                    <mdi:close class="hover:cursor-pointer" @click="selectSection(undefined)" />
                </div>
            </div>

            <hr v-if="selectedSection" class="mb-5 mt-1 h-[1.5px] bg-dark-200" />

            <div v-for="section in selectedImage?.sections" :key="section.id" class="mt-3">
                <template v-if="visibleSections[section.id]">
                    <div
                        v-if="!selectedSection"
                        class="flex items-center justify-center space-x-1 text-lg uppercase text-primary-400"
                    >
                        <span class="font-semibold" v-text="t('section') + ' ' + section.name" />
                    </div>

                    <span
                        v-if="visibleSections[section.id].note"
                        v-text="t('note') + ': ' + visibleSections[section.id].note"
                    />

                    <div
                        v-for="incident in visibleSections[section.id].incidents"
                        :key="incident.id"
                        class="mb-3"
                    >
                        <div
                            class="flex justify-between rounded-xl bg-primary-200 p-4 dark:bg-dark-500"
                        >
                            <div>
                                <div class="flex items-center space-x-2">
                                    <div
                                        class="h-4 w-4 rounded-full"
                                        :class="getIncidentTypeColor(incident?.type)"
                                    />

                                    <span
                                        class="text-sm font-semibold uppercase text-primary-400"
                                        v-text="localeDate(incident.createdAt)"
                                    />

                                    <div
                                        v-if="incident.fixedAt"
                                        v-tooltip="incidentTooltip(incident)"
                                        class="self-center rounded-lg bg-green-600 py-0.5 px-2 text-xs font-semibold uppercase text-white"
                                        v-text="t('fixed')"
                                    />
                                </div>

                                <div class="mt-1.5 flex w-1/2 space-x-2 overflow-x-auto">
                                    <MyImageViewer class="flex">
                                        <img
                                            v-for="image in incident.images"
                                            :key="image"
                                            class="w-24 rounded hover:cursor-pointer"
                                            :src="imageHelper(image, 'thumbnail')"
                                            :data-src="imageHelper(image, 'public')"
                                        />
                                    </MyImageViewer>
                                </div>
                            </div>

                            <div class="flex w-1/2 flex-col items-end text-right">
                                <ActionRow
                                    v-if="
                                        authStore.companyId == props.transportObject.companyId &&
                                        incident.fixedAt === null &&
                                        !props.specificIncidentId
                                    "
                                    class="mb-2"
                                    menu-items-class="z-20 right-0 w-[200px]"
                                >
                                    <template #button>
                                        <MyButton scheme="primary" plain size="small">
                                            <mdi:dots-vertical />
                                        </MyButton>
                                    </template>

                                    <ActionMenuItem @click="fixIncident(incident.id)">
                                        <div class="flex">
                                            <mdi:hammer-wrench class="mr-2" />
                                            <span v-text="t('fixIncident')" />
                                        </div>
                                    </ActionMenuItem>
                                    <ActionMenuItem
                                        v-if="
                                            alarmByIncident[incident.id] &&
                                            alarmByIncident[incident.id].resolvedAt === null
                                        "
                                        @click="muteAlarm(alarmByIncident[incident.id])"
                                    >
                                        <div
                                            v-if="!alarmByIncident[incident.id]?.mutedAt"
                                            class="flex"
                                        >
                                            <mdi:bell-off class="mr-2" />
                                            <span v-text="t('muteAlarm')" />
                                        </div>
                                        <div v-else class="flex">
                                            <mdi:bell-badge class="mr-2" />
                                            <span v-text="t('unmuteAlarm')" />
                                        </div>
                                    </ActionMenuItem>
                                </ActionRow>

                                <span
                                    class="text-xs font-semibold uppercase text-primary-400"
                                    v-text="incident.user?.name"
                                />

                                <span
                                    v-if="incident.note"
                                    class="whitespace-pre-wrap text-xs"
                                    v-text="incident.note"
                                />
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div
                v-if="!hasIncidents"
                class="mt-3 flex items-center justify-center rounded-xl bg-primary-200 p-4 dark:bg-primary-400"
            >
                {{ t('noIncidentsFound') }}
            </div>
        </div>

        <UpdateAlarmStateModal
            v-if="selectedAlarm"
            v-model="alarmStateModalOpen"
            :alarm="selectedAlarm"
            :alarm-action="alarmAction"
        />

        <FixIncidentModal
            v-if="selectedIncidentId"
            v-model="fixIncidentModalOpen"
            :incident-id="selectedIncidentId"
            :alarm="selectedAlarm"
        />
    </div>
</template>
