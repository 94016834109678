<script lang="ts" setup>
import type { DropdownOption } from '@/types/inputs'

import axios from 'axios'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import { RouterLink } from 'vue-router'

import {
    DashboardWidget,
    WidgetType,
    SortDirection,
    StopTypeCounts,
    StopTypeKey,
} from '@/types/dashboard'
import {
    PaginatedResponse,
    PaginationData,
    PermissionType,
    ResourceResponse,
    uuid,
} from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { EventType } from '@/types/driver-report'
import { useIsPublicDashboardView } from '@/hooks/use-is-public-dashboard-view'
import { useCalculatedRowCount } from '@/hooks/use-calculated-row-count'
import { moduleLicenses } from '@/utils/licenses'
import { stopTypeColors } from '@/utils/driver-report'
import { numberFormatter } from '@/utils/numbers'

import MySelect from '@/components/my-components/form/MySelect.vue'
import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import PaginationFooter from '@/components/PaginationFooter.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import PageNavigationLink from '@/components/layout/navigation/PageNavigationLink.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'
import TimePeriodOption from '@/components/dashboard/widget-settings/TimePeriodOption.vue'

interface Props {
    widget: DashboardWidget<WidgetType.EmployeeDriverReportsStopTime>
    preview?: boolean
}

interface AverageStopEmployeeReports {
    id: uuid
    image: string
    name: string
    total: number
    stops: StopTypeCounts
}

const stopTypes: Record<EventType, StopTypeKey> = {
    [EventType.Break]: 'break',
    [EventType.Resting]: 'resting',
    [EventType.Customer]: 'customer',
    [EventType.Other]: 'other',
    [EventType.Traffic]: 'traffic',
    // Unused but because Except<> is not working we gotta add these
    [EventType.ReportStart]: 'traffic',
    [EventType.ReportEnd]: 'traffic',
    [EventType.StartTour]: 'traffic',
}

interface EventTypeDropdownOption extends DropdownOption {
    label: string
    value: EventType
    stopTypeKey: StopTypeKey
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const dashboardStore = useDashboardStore()
const isPublicView = useIsPublicDashboardView()

const averageElement = ref<HTMLElement>()
const loading = ref(false)
const currentPage = ref(1)
const employees = ref<AverageStopEmployeeReports[]>()
const companyAverage = ref<number>(0)
const pagination = ref<PaginationData>()
const sortedBy = ref<StopTypeKey>()

const sortDirection = computed({
    get: () => props.widget.options.sortDirection ?? SortDirection.desc,
    set: (sortDirection) => dashboardStore.updateWidgetOptions(props.widget, { sortDirection }),
})
const timePeriod = computed({
    get: () => props.widget.options.timePeriod ?? 7,
    set: (timePeriod) => dashboardStore.updateWidgetOptions(props.widget, { timePeriod }),
})
const selectedStopTypes = computed({
    get: () => props.widget.options.stopTypes ?? null,
    set: (stopType) =>
        dashboardStore.updateWidgetOptions(props.widget, { stopTypes: stopType ?? undefined }),
})
const shownStopTypes = computed((): EventTypeDropdownOption[] => {
    if (!selectedStopTypes.value || selectedStopTypes.value.length === 0) {
        return stopTypeOptions.value
    }

    return selectedStopTypes.value.map((stop): EventTypeDropdownOption => {
        const stopTypeKey = stopTypes[stop]
        return {
            label: stopTypeKey,
            value: stop,
            stopTypeKey,
        }
    })
})

const stopTypeOptions = computed<EventTypeDropdownOption[]>(() => [
    { label: t('break'), value: EventType.Break, stopTypeKey: 'break' },
    { label: t('resting'), value: EventType.Resting, stopTypeKey: 'resting' },
    { label: t('traffic'), value: EventType.Traffic, stopTypeKey: 'traffic' },
    { label: t('customer'), value: EventType.Customer, stopTypeKey: 'customer' },
    { label: t('other'), value: EventType.Other, stopTypeKey: 'other' },
])
const rowsPerPage = useCalculatedRowCount(averageElement, {
    rowHeight: 47,
    headerSpacing: 56,
    onChange: () => {
        currentPage.value = 1
        fetchEmployees()
    },
})

async function fetchEmployees(page?: number) {
    if (!rowsPerPage.value) return

    if (page) {
        currentPage.value = page
    }

    const params: Record<string, string | string[] | EventType[] | number | boolean | null> = {
        dashboard: dashboardStore.currentDashboard!.id,
        shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
        page: currentPage.value,
        'per-page': rowsPerPage.value,
        'sort-direction': sortDirection.value,
        'start-date': dayjs().subtract(timePeriod.value, 'days').toISOString(),
        'selected-stop-types': selectedStopTypes.value,
    }

    if (sortedBy.value) {
        params['sort-by'] = sortedBy.value
    }

    const { data: response } = await axios.get<PaginatedResponse<AverageStopEmployeeReports>>(
        window.route('dashboards.dr-employee-stop-time', params),
    )

    employees.value = response.data
    pagination.value = {
        from: response.meta.from,
        to: response.meta.to,
        perPage: response.meta.per_page,
        lastPage: response.meta.last_page,
        currentPage: response.meta.current_page,
        total: response.meta.total,
    }
}
async function fetchCompanyAverage() {
    const params: Record<string, string | EventType[] | null> = {
        dashboard: dashboardStore.currentDashboard!.id,
        shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
        'start-date': dayjs().subtract(timePeriod.value, 'days').toISOString(),
        'selected-stop-types': selectedStopTypes.value,
    }

    const { data: response } = await axios.get<ResourceResponse<number>>(
        window.route('dashboards.dr-company-stop-time', params),
    )
    companyAverage.value = response.data
}
function refreshCompanyData(page?: number) {
    fetchEmployees(page)
    fetchCompanyAverage()
}

function timeSuffix(time: number): string {
    const timeString = numberFormatter.format(time >= 60 ? time / 60 : time)
    if (time >= 60) return t('entityHours', { time: timeString }, time / 60)
    return t('entityMinutes', { time: timeString }, time)
}
async function handleSort(stopTypeKey?: StopTypeKey) {
    if (sortedBy.value === stopTypeKey && sortDirection.value === SortDirection.desc) {
        sortDirection.value = SortDirection.asc
    } else {
        sortDirection.value = SortDirection.desc
    }
    sortedBy.value = stopTypeKey
}
async function changePage(page: number): Promise<void> {
    currentPage.value = page
}

onMounted(() => fetchCompanyAverage())

watch(
    () => currentPage.value,
    () => fetchEmployees(),
)
watch(
    () => props.widget.options,
    async () => refreshCompanyData(1),
    { deep: true },
)

useMittListener('fetchWidgetData', () => refreshCompanyData)
</script>

<template>
    <MyWidget :widget="props.widget" :preview="props.preview">
        <div class="border-b-2 border-primary-100 dark:border-dark-600 h-full flex flex-col">
            <div ref="averageElement" class="overflow-y-auto flex-1 w-full">
                <LoaderWrapper v-if="loading" :visible="loading" />
                <table class="w-full min-w-full table-fixed">
                    <thead>
                        <tr class="border-collapse">
                            <td
                                class="text-slate-400 dark:text-primary-100 sticky left-0 w-60 bg-primary-50 px-3 py-3 text-start font-semibold text-primary-400 shadow-lg drop-shadow-lg dark:bg-dark-500 dark:shadow-black"
                            />

                            <td
                                v-if="selectedStopTypes?.length !== 1"
                                class="h-30 w-40 rounded-t-xl py-4 dark:border-dark-600"
                            >
                                <div
                                    class="mt-2 flex items-center justify-center text-center text-xs uppercase space-x-2 cursor-pointer"
                                    @click="handleSort()"
                                >
                                    {{ t('total') }}

                                    <mdi:chevron-down
                                        v-if="sortedBy === undefined"
                                        class="transition-transform ml-2 text-xs"
                                        :class="{ 'rotate-180': sortDirection === 'asc' }"
                                    />
                                </div>
                            </td>
                            <td
                                v-for="stop in shownStopTypes"
                                :key="stop.label"
                                class="text-slate-400 dark:text-slate-200 h-30 w-40 rounded-t-xl py-4 font-medium odd:bg-black/5 dark:border-dark-600 dark:odd:bg-black/20"
                            >
                                <div
                                    class="flex h-full cursor-pointer flex-col items-center justify-between"
                                    @click="handleSort(stop.stopTypeKey)"
                                >
                                    <div
                                        class="mt-2 flex items-center justify-between text-center text-xs uppercase space-x-2"
                                    >
                                        <span
                                            v-if="stop.value !== null"
                                            class="rounded-full size-3 dark:ring-dark-400 dark:ring shadow dark:shadow-none"
                                            :class="stopTypeColors[stop.value as EventType]"
                                        />
                                        <span>
                                            {{ t(stop.label.toLowerCase()) }}
                                        </span>
                                        <mdi:chevron-down
                                            v-if="sortedBy === stop.label.toLowerCase()"
                                            class="float-right transition-transform"
                                            :class="{ 'rotate-180': sortDirection === 'asc' }"
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="row in employees"
                            :key="row.id"
                            class="group odd:bg-primary-100 hover:bg-primary-300 dark:odd:bg-dark-400 dark:hover:bg-dark-700 h-[47px]"
                        >
                            <td
                                v-truncate-tooltip
                                class="sticky left-0 truncate bg-primary-50 px-3 shadow-lg drop-shadow-lg group-odd:bg-primary-100 group-hover:bg-primary-300 dark:bg-dark-500 dark:group-odd:bg-dark-400 dark:group-hover:bg-dark-700"
                            >
                                <PageNavigationLink
                                    no-background
                                    no-padding
                                    regular-link
                                    class="font-semibold text-primary-400"
                                    :to="{
                                        name: 'dr.reports',
                                        query: { tab: 'employees', query: row.name },
                                    }"
                                >
                                    {{ row.name }}
                                </PageNavigationLink>
                            </td>

                            <td v-if="selectedStopTypes?.length !== 1" class="px-3 text-center">
                                {{ timeSuffix(row.total).toLowerCase() }}
                            </td>

                            <td
                                v-for="eventType in shownStopTypes"
                                :key="eventType.label"
                                class="px-3 text-center font-semibold odd:bg-black/5 dark:odd:bg-black/20"
                            >
                                {{ timeSuffix(row.stops[stopTypes[eventType.value]]) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <PaginationFooter
                v-if="!isPublicView"
                simple
                class="border-t border-primary-200 dark:border-dark-500 px-6 pb-6"
                hide-per-page
                :pagination-data="pagination"
                :refetch="(params) => changePage(params?.page ?? 1)"
                :row-count="rowsPerPage ?? 1"
            >
                <template #perPage>
                    <RouterLink
                        v-if="
                            authStore.hasLicense(moduleLicenses) &&
                            authStore.hasPermission(PermissionType.ManageCompany)
                        "
                        class="text-sm font-semibold text-blue-500 underline decoration-2 underline-offset-2"
                        :to="{ name: 'settings.employees' }"
                        v-text="t('goTo', { to: t('employees') })"
                    />
                    {{ t('totalCompanyAverage') }}
                    {{ timeSuffix(companyAverage) }}
                </template>
            </PaginationFooter>
        </div>

        <template #settings>
            <MySelect
                v-model="selectedStopTypes"
                :options="stopTypeOptions"
                group-class="mt-4  mx-auto"
                :label="t('stopTypes')"
                clear-button
                :placeholder="t('all')"
                multiple
            />

            <MyRadioButtonGroup v-model="sortDirection" class="mt-4 mx-auto" :label="t('sortBy')">
                <MyRadioButton :label="t('ascending')" :value="SortDirection.asc" />
                <MyRadioButton :label="t('descending')" :value="SortDirection.desc" />
            </MyRadioButtonGroup>

            <TimePeriodOption v-model="timePeriod" />
        </template>
    </MyWidget>
</template>
