<script lang="ts" setup>
import { computed, ref, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

import { LanguageCode, languagesCodes } from '@/utils/language-codes'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'

import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

const translations = defineModel<{ en: string } & Partial<Record<LanguageCode, string>>>({
    required: true,
})
const modalOpen = defineModel<boolean>('open', { required: true })

const confirm = useConfirm()
const selectValue = ref<LanguageCode | null>(null)

const { t } = useI18n()

const languages = computed(() => {
    const existingLanguages = Object.keys(translations.value)
    return Object.keys(languagesCodes)
        .filter((key) => !existingLanguages.includes(key as LanguageCode))
        .map((key) => {
            return {
                label: languagesCodes[key as unknown as LanguageCode],
                value: key,
            }
        })
})

async function addLanguage() {
    if (!selectValue.value) {
        return
    }

    translations.value[selectValue.value] = ''
    await nextTick()
    selectValue.value = null
}

async function removeLanguage(countryCode: LanguageCode) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('translation') }),
            t('deleteTranslationDescription'),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    const limitedCountryCode = countryCode as LanguageCode
    if (limitedCountryCode in translations.value) {
        delete translations.value[limitedCountryCode]
    }
}

function closeModal() {
    modalOpen.value = false
}
</script>

<template>
    <MyModal :value="modalOpen" back-button @back-button-clicked="closeModal" @close="closeModal">
        <template #title>{{ t('translate') }}</template>

        <MyForm class="space-y-4" @submit.prevent="closeModal()">
            <div
                v-for="(translation, countryCode) in translations"
                :key="countryCode"
                class="flex flex-row-reverse items-center"
            >
                <MyButton
                    v-if="countryCode.toString() !== 'en'"
                    class="self-end"
                    plain
                    icon
                    type="button"
                    @click="removeLanguage(countryCode)"
                >
                    <mdi:close />
                </MyButton>

                <MyInput
                    v-model="translations[countryCode]"
                    name="name"
                    autofocus
                    :label="languagesCodes[countryCode] + ' (' + countryCode + ')'"
                    :placeholder="t('translation')"
                    required
                />
            </div>

            <MySelect
                v-model="selectValue"
                name="type"
                searchable
                :label="t('translationLanguage')"
                :options="languages"
                :placeholder="t('addLanguage')"
                clear-button
                @change="addLanguage()"
            />

            <div class="flex justify-end">
                <MyButton scheme="primary">{{ t('save') }}</MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
