<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { debouncedWatch } from '@vueuse/core'
import { computed, nextTick, onBeforeMount, ref } from 'vue'
import axios from 'axios'

import { FieldPreference } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import {
    DeliveryDescriptionMatch,
    DeliveryManagementPreferences,
    TourRoutingMode,
} from '@/types/delivery-management'
import { newMoneyFormatter } from '@/utils/numbers'

import Loader from '@/components/loaders/Loader.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MyButton from '@/components/my-components/MyButton.vue'

let watcherStopped = true

const authStore = useAuthStore()
const { t } = useI18n()

const preferences = ref<DeliveryManagementPreferences>({
    shipmentPodCreation: false,
    colliScan: FieldPreference.Optional,
    images: FieldPreference.Optional,
    note: FieldPreference.Optional,
    transaction: FieldPreference.Optional,
    signature: FieldPreference.Optional,
    unsuccessful: FieldPreference.Optional,
    deviation: FieldPreference.Optional,
    hideReturnShipmentColliScan: false,
    serviceTimePerStop: 0,
    bulkScanningThreshold: 0,
    shipmentDelayAlertThreshold: 0,
    tourDriverConfirmation: false,
    multipleDriversOnTour: false,
    deliveryDescriptionMatch: DeliveryDescriptionMatch.Location,
    tourRoutingMode: TourRoutingMode.Shortest,
    consolidateEvents: false,
    mileageCost: null,
})
const saving = ref(false)
const bulkScanningEnabled = ref(false)
const mileageCost = ref<number | null>(0)

const hasRoutingLicenses = computed(() =>
    authStore.hasLicense([
        LicenseType.DeliveryManagementNavigation,
        LicenseType.DeliveryManagementTourETA,
        LicenseType.DeliveryManagementTourOptimization,
    ]),
)

const currencyCode = computed(() => {
    const moneyFormatter = newMoneyFormatter(authStore.company.options.currency || 'USD')

    // We have no way to easily get the symbol from the NumberFormat
    // so we'll have to do it like this
    return moneyFormatter.format(0).replace('0', '').trim()
})

function saveMileageCost() {
    preferences.value.mileageCost = mileageCost.value
}

async function savePreferences() {
    if (watcherStopped) return

    saving.value = true
    await axios.put(
        window.route('dm.company.preferences.update', { company: authStore.companyId }),
        preferences.value,
    )
    authStore.licenses[LicenseType.DeliveryManagementModule] = {
        ...authStore.licenses[LicenseType.DeliveryManagementModule],
        ...preferences.value,
    }
    saving.value = false
}

function toggleBulkScanningThreshold() {
    if (bulkScanningEnabled.value) {
        preferences.value.bulkScanningThreshold = 50
    } else {
        preferences.value.bulkScanningThreshold = 0
    }
}

function setBulkScanningThreshold(value: string | number | null) {
    preferences.value.bulkScanningThreshold = Math.max(parseInt(value + '', 10), 0)
}

debouncedWatch(() => preferences, savePreferences, { debounce: 250, deep: true })

onBeforeMount(async () => {
    const prefs: Partial<DeliveryManagementPreferences> =
        authStore.licenses[LicenseType.DeliveryManagementModule] ?? {}

    preferences.value = { ...preferences.value, ...prefs }
    bulkScanningEnabled.value = preferences.value.bulkScanningThreshold > 0
    mileageCost.value = preferences.value.mileageCost || null
    await nextTick()

    setTimeout(() => {
        watcherStopped = false
    }, 400)
})
</script>

<template>
    <MyPanel panel-class="w-96" padded shadow>
        <Loader
            class="absolute right-6 top-6 opacity-0 transition-opacity duration-300"
            :class="{ 'opacity-100': saving }"
        />

        <div class="flex flex-col space-y-4">
            <MyRadioButtonGroup
                v-model="preferences.shipmentPodCreation"
                class="relative"
                :label="t('podCreation')"
            >
                <MyRadioButton :label="t('enabled')" :value="true" />
                <MyRadioButton :label="t('disabled')" :value="false" />

                <template #label>
                    {{ t('podCreation') }}
                    <mdi:information
                        v-tooltip="t('podCreationExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.multipleDriversOnTour"
                class="relative"
                :label="t('multipleDriversOnTour')"
            >
                <MyRadioButton :label="t('enabled')" :value="true" />
                <MyRadioButton :label="t('disabled')" :value="false" />

                <template #label>
                    {{ t('multipleDriversOnTour') }}
                    <mdi:information
                        v-tooltip="t('multipleDriversOnTourExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.consolidateEvents"
                class="relative"
                :label="t('consolidateShipments')"
            >
                <MyRadioButton :label="t('enabled')" :value="true" />
                <MyRadioButton :label="t('disabled')" :value="false" />

                <template #label>
                    {{ t('consolidateShipments') }}
                    <mdi:information
                        v-tooltip="t('consolidateShipmentsExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.colliScan" :label="t('colliScan')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.hideReturnShipmentColliScan"
                class="relative"
                :label="t('returnShipmentColliScan')"
            >
                <MyRadioButton :label="t('visible')" :value="false" />
                <MyRadioButton :label="t('hidden')" :value="true" />

                <template #label>
                    {{ t('returnShipmentColliScan') }}
                    <mdi:information
                        v-tooltip="t('returnShipmentColliScanExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.images" :label="t('images')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.note" :label="t('note')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.transaction"
                :label="t('packagingTransaction')"
            >
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.signature" :label="t('signature')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.unsuccessful"
                class="relative"
                :label="t('unsuccessful')"
            >
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />

                <template #label>
                    {{ t('unsuccessfulShipment') }}
                    <mdi:information
                        v-tooltip="t('unsuccessfulExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.deviation"
                class="relative"
                :label="t('deviation')"
            >
                <MyRadioButton :label="t('visible')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />

                <template #label>
                    {{ t('deviations') }}
                    <mdi:information
                        v-tooltip="t('deviationsExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <template
                v-if="
                    authStore.hasLicense(LicenseType.DeliveryManagementTourOptimization) ||
                    authStore.hasLicense(LicenseType.DeliveryManagementTourETA)
                "
            >
                <MyInput
                    v-model="preferences.serviceTimePerStop"
                    name="serviceTimePerStop"
                    type="number"
                    :label="t('serviceTimePerStop')"
                />
                <MyInput
                    v-model="preferences.shipmentDelayAlertThreshold"
                    name="shipmentDelayAlertThreshold"
                    type="number"
                    :label="t('shipmentDelayAlertThreshold')"
                >
                    <template #label="{ id, label }">
                        <div class="flex justify-between">
                            <MyInputLabel :for="id" v-text="label" />
                            <mdi:information
                                v-tooltip="t('shipmentDelayAlertThresholdDescription')"
                                class="h-4 w-4"
                            />
                        </div>
                    </template>
                </MyInput>
            </template>

            <div>
                <div class="flex items-center space-x-2">
                    <MyCheckbox
                        v-model="bulkScanningEnabled"
                        :label="t('bulkScanningThreshold')"
                        @change="toggleBulkScanningThreshold"
                    />
                    <mdi:information v-tooltip="t('bulkScanningThresholdExplanation')" />
                </div>
                <MyInput
                    v-if="bulkScanningEnabled"
                    :value="preferences.bulkScanningThreshold"
                    name="bulkScanningThreshold"
                    type="number"
                    :min="1"
                    :label="t('threshold')"
                    @update:model-value="setBulkScanningThreshold"
                />
            </div>

            <div class="flex items-center space-x-2">
                <MyCheckbox
                    v-model="preferences.tourDriverConfirmation"
                    :label="t('tourDriverConfirmation')"
                />
                <mdi:information v-tooltip="t('tourDriverConfirmationExplanation')" />
            </div>

            <MyRadioButtonGroup
                v-if="authStore.hasLicense(LicenseType.ShipmentDeliveryDescriptions)"
                v-model="preferences.deliveryDescriptionMatch"
                class="relative"
            >
                <MyRadioButton
                    :label="t('byLocation')"
                    :value="DeliveryDescriptionMatch.Location"
                />
                <MyRadioButton
                    :label="t('byShipmentName')"
                    :value="DeliveryDescriptionMatch.ShipmentName"
                />

                <template #label>
                    {{ t('deliveryDescriptionMatch') }}
                    <mdi:information
                        v-tooltip="t('deliveryDescriptionMatchExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-if="hasRoutingLicenses"
                v-model="preferences.tourRoutingMode"
                class="relative"
            >
                <MyRadioButton :label="t('shortest')" :value="TourRoutingMode.Shortest" />
                <MyRadioButton :label="t('fastest')" :value="TourRoutingMode.Fastest" />

                <template #label>
                    {{ t('routingMode') }}
                    <mdi:information
                        v-tooltip="t('routingModeExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <div v-if="authStore.hasLicense(LicenseType.ShipmentCostCalculation)" class="mt-3 mb-6">
                <hr class="mb-2" />

                <div class="flex items-center space-x-3 align-middle relative">
                    <MyInput
                        v-model.number="mileageCost"
                        :label="t('mileageCost')"
                        :placeholder="t('mileageCost')"
                        type="number"
                        name="mileageCost"
                    >
                        <template #label="{ label }">
                            <MyInputLabel>
                                <div class="font-semibold">
                                    {{ label }}
                                </div>
                                <mdi:information
                                    v-tooltip="t('mileageCostExplanation')"
                                    class="absolute top-1 right-0"
                                />
                            </MyInputLabel>
                        </template>
                        <template v-if="currencyCode" #button>
                            <span
                                class="absolute top-0 bottom-0.5 right-2 m-auto h-5"
                                v-text="`${currencyCode}/${t('km')}`"
                            />
                        </template>
                    </MyInput>
                    <MyButton
                        scheme="primary"
                        class="mt-auto flex-shrink-0"
                        @click="saveMileageCost"
                        v-text="t('save')"
                    />
                </div>
            </div>
        </div>
    </MyPanel>
</template>
