<script lang="ts" setup>
import axios from 'axios'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import NotFoundComponent from '@/pages/NotFoundComponent.vue'
import { useAuthStore } from '@/stores/auth-store'
import { uuid, ResourceResponse } from '@/types/general'
import { Statement, StatementProduct } from '@/types/packaging'
import { datetime, localeDate } from '@/utils/dates'
import { DropdownStringOption } from '@/types/inputs'
import { Location } from '@/types/company'
import { useBalanceOwedHelpers } from '@/hooks/packaging/use-balance-owed-description'

import BasicExportModal from '@/components/import-export/BasicExportModal.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import ShareStatementModal from '@/components/packaging/statement/ShareStatementModal.vue'
import StatementCompanyCard from '@/components/packaging/statement/StatementCompanyCard.vue'
import StatementProductCard from '@/components/packaging/statement/StatementProductCard.vue'
import TransactionsTable from '@/components/packaging/transactions/TransactionsTable.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

interface Filters {
    companyLocationId: uuid | null
    receiverLocationId: uuid | null
}

interface TotalBalance extends Omit<StatementProduct, 'id' | 'name' | 'image'> {
    primo: number
    period: number
}

const totalBalanceObject: TotalBalance = {
    primoDelivered: 0,
    primoReceived: 0,
    periodDelivered: 0,
    periodReceived: 0,
    primo: 0,
    period: 0,
}

const route = useRoute()
const { t, locale } = useI18n()
const authStore = useAuthStore()

const fetching = ref(false)
const shareModalOpen = ref(false)
const filtersOpen = ref(false)
const statement = ref<Statement>()
const filters = ref<Filters>({
    companyLocationId: null,
    receiverLocationId: null,
})

const statementId = computed(() => (route.params.statementId || '') as uuid)
const isOwner = computed(() => statement.value?.company.id === authStore.companyId)
const visibleCompanyLocations = computed<Location[]>(() => {
    if ((statement.value?.companyLocationIds ?? []).length > 0) {
        return statement.value?.companyLocations ?? []
    }

    if (filters.value.companyLocationId) {
        const location = statement.value?.companyLocations.find(
            (l) => l.id === filters.value.companyLocationId,
        )
        if (location) return [location]
    }

    return []
})
const visibleReceiverLocations = computed<Location[]>(() => {
    if ((statement.value?.receiverLocationIds ?? []).length > 0) {
        return statement.value?.receiverLocations ?? []
    }

    if (filters.value.receiverLocationId) {
        const location = statement.value?.receiverLocations.find(
            (l) => l.id === filters.value.receiverLocationId,
        )
        if (location) return [location]
    }

    return []
})
const companyLocations = computed<DropdownStringOption[]>(() => {
    const locations = statement.value?.companyLocations ?? []

    return locations.map((location) => ({ value: location.id, label: location.name }))
})
const customerLocations = computed<DropdownStringOption[]>(() => {
    const locations = statement.value?.receiverLocations ?? []

    return locations.map((location) => ({ value: location.id, label: location.name }))
})
const totalBalance = computed<TotalBalance>(() => {
    const products = statement.value?.products || []

    const totalBalance = products.reduce(
        (total, product) => {
            total.primoDelivered += product.primoDelivered
            total.primoReceived += product.primoReceived
            total.periodDelivered += product.periodDelivered
            total.periodReceived += product.periodReceived

            return total
        },
        { ...totalBalanceObject },
    )

    totalBalance.primo = totalBalance.primoDelivered - totalBalance.primoReceived
    totalBalance.period = totalBalance.periodDelivered - totalBalance.periodReceived

    return totalBalance
})

const companyName = computed(() => statement.value?.company.name ?? '')
const receiverName = computed(() => statement.value?.receiverCompany.name ?? '')
const { balanceOwedDescription, getBalanceColor } = useBalanceOwedHelpers(companyName, receiverName)

const transactionsEndpoint = computed(() =>
    window.route('packaging.statements.transactions.index', { statement: statementId.value }),
)
const exportEndpoint = computed(() =>
    window.route('packaging.statements.transactions.export', { statement: statementId.value }),
)

const accumulatedBalance = computed(() => totalBalance.value.primo + totalBalance.value.period)

async function fetchStatement() {
    if (!statementId.value) return

    fetching.value = true

    try {
        const response = await axios.get<ResourceResponse<Statement>>(
            window.route('packaging.statements.show', {
                statement: statementId.value,
                'company-location-id': filters.value.companyLocationId || '',
                'receiver-location-id': filters.value.receiverLocationId || '',
            }),
        )

        statement.value = response.data.data
    } catch (_) {
        //
    } finally {
        fetching.value = false
    }
}

onBeforeMount(() => fetchStatement())
watch(statementId, () => fetchStatement())
watch(filters, () => fetchStatement(), { deep: true })
watch(locale, () => fetchStatement())
</script>

<template>
    <LoaderWrapper :visible="fetching" />

    <div v-if="statement" class="max-w-screen-2xl 2xl:mx-auto">
        <ShareStatementModal
            v-if="statement"
            v-model="shareModalOpen"
            :statement-id="statement.id"
            :customer-company-id="statement.receiverCompany.id"
            @shared="fetchStatement"
        />

        <div class="flex flex-col text-xs">
            <div class="flex items-center font-semibold">
                <mdi:information class="h-5 w-5 mr-1.5" />
                {{ t('balanceExplanation') }}
            </div>

            <div class="flex space-x-1">
                <p class="mt-1.5 font-bold text-center" :class="getBalanceColor(1)">+1</p>

                <p class="mt-1.5 text-center mb-1 dark:text-primary-200">
                    =
                    {{ balanceOwedDescription(1) }}
                </p>
            </div>

            <div class="flex space-x-1 text-center">
                <p class="font-bold text-center" :class="getBalanceColor(-1)">-1</p>

                <p class="text-center mb-3 dark:text-primary-200">
                    =
                    {{ balanceOwedDescription(-1) }}
                </p>
            </div>
        </div>

        <section class="grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-[450px_1fr_450px]">
            <StatementCompanyCard
                :company="statement.company"
                :locations="visibleCompanyLocations"
                :selected-location-id="filters.companyLocationId"
                @location-selected="filters.companyLocationId = $event"
            />

            <MyPanel class="flex flex-col" padded bordered background="bg-transparent">
                <h3
                    class="text-md font-semibold uppercase dark:text-primary-200 lg:text-center"
                    v-text="t('period')"
                />

                <div
                    class="flex gap-2.5 text-xl lg:col-span-2 lg:justify-center dark:text-primary-200"
                >
                    <p class="font-bold">{{ localeDate(statement.startDate) }}</p>
                    <span class="font-bold dark:text-primary-300">-</span>
                    <p class="font-bold">{{ localeDate(statement.endDate) }}</p>
                </div>

                <h3
                    class="text-md mt-3 font-semibold uppercase dark:text-primary-200 lg:text-center"
                    v-text="t('balance')"
                />

                <div class="flex justify-between mt-4">
                    <div class="flex flex-col">
                        <p class="text-sm">
                            {{ t('statusBefore') }} <br />
                            ({{ localeDate(statement.startDate) }})
                        </p>

                        <p
                            v-tooltip="balanceOwedDescription(totalBalance.primo)"
                            class="font-bold mt-1.5 w-fit mr-auto"
                            :class="getBalanceColor(totalBalance.primo)"
                            v-text="totalBalance.primo"
                        />
                    </div>

                    <div class="flex flex-col justify-between text-center">
                        <p class="text-sm">{{ t('statusDuring') }}</p>

                        <p
                            v-tooltip="balanceOwedDescription(totalBalance.period)"
                            class="font-bold text-center"
                            :class="getBalanceColor(totalBalance.period)"
                            v-text="totalBalance.period"
                        />
                    </div>

                    <div class="text-right">
                        <p class="text-sm">
                            {{ t('statusAfter') }} <br />
                            ({{ localeDate(statement.endDate) }})
                        </p>

                        <p
                            v-tooltip="balanceOwedDescription(accumulatedBalance)"
                            class="mt-1.5 font-bold w-fit ml-auto"
                            :class="getBalanceColor(accumulatedBalance)"
                            v-text="accumulatedBalance"
                        />
                    </div>
                </div>

                <div class="flex justify-between items-end mt-6">
                    <div class="flex justify-center gap-3 text-xs uppercase text-primary-400">
                        <p
                            class="block rounded-lg ml-1 bg-white px-4 py-3 font-semibold dark:bg-black"
                        >
                            {{ t('numberOfReceipts') }}
                            <span
                                class="font-bold text-primary-500 dark:text-primary-400 ml-1"
                                v-text="statement.transactionCount"
                            />
                        </p>
                    </div>

                    <MyButton
                        class="bg-primary-50 text-dark-700 hover:bg-primary-200 active:bg-primary-50 dark:text-primary-50 dark:bg-dark-500 dark:hover:bg-dark-400 dark:active:bg-dark-600"
                        size="small"
                        @click="filtersOpen = !filtersOpen"
                    >
                        <mdi:filter-outline class="mr-2" />
                        {{ t('filter') }}
                    </MyButton>
                </div>

                <div v-if="filtersOpen" class="mt-auto pt-2">
                    <div class="flex flex-col mx-auto w-8/12 space-y-3">
                        <MySelect
                            v-if="statement.statementCompanyLocationFilterEnabled"
                            v-model="filters.companyLocationId"
                            :options="companyLocations"
                            :placeholder="t('allLocations')"
                            label="Company location"
                            clear-button
                        />

                        <MySelect
                            v-model="filters.receiverLocationId"
                            :options="customerLocations"
                            label="Customer location"
                            :placeholder="t('allLocations')"
                            clear-button
                        />
                    </div>
                </div>

                <MyButton
                    v-if="isOwner"
                    v-tooltip="t('shareEntity', { entity: t('statement') })"
                    class="absolute right-6 top-4"
                    icon
                    plain
                    scheme="light"
                    size="small"
                    @click="shareModalOpen = true"
                >
                    <mdi:share />
                </MyButton>
            </MyPanel>

            <StatementCompanyCard
                :company="statement.receiverCompany"
                :locations="visibleReceiverLocations"
                :selected-location-id="filters.receiverLocationId"
                @location-selected="filters.receiverLocationId = $event"
            />
        </section>

        <section class="my-10 grid lg:grid-cols-4 grid-cols-3 gap-6">
            <StatementProductCard
                :primo="totalBalance.primo"
                :delivered="totalBalance.periodDelivered"
                :received="totalBalance.periodReceived"
                :company-name="companyName"
                :receiver-name="receiverName"
            />

            <StatementProductCard
                v-for="product in statement.products"
                :key="product.id"
                :primo="product.primoDelivered - product.primoReceived"
                :delivered="product.periodDelivered"
                :received="product.periodReceived"
                :product-image="product.image"
                :product-name="product.name"
                :company-name="companyName"
                :receiver-name="receiverName"
            />
        </section>

        <div class="mb-3 flex items-end justify-between">
            <h3 class="text-md font-semibold" v-text="t('transactions')" />

            <BasicExportModal
                :title="t('exportModel', { model: t('transactions') })"
                :endpoint="exportEndpoint"
                :button-text="t('exportModel', { model: t('transactions') })"
            />
        </div>

        <MyPanel shadow>
            <TransactionsTable
                :endpoint="transactionsEndpoint"
                hide-actions
                hide-date-range
                inline-view-modal
                hide-location-filter
                :location-id="filters.companyLocationId || undefined"
                :compared-location-id="filters.receiverLocationId || undefined"
            />
        </MyPanel>

        <div v-if="isOwner" class="mt-5">
            <div
                class="text-xs my-3"
                v-text="t('createdByUser', { user: statement.user?.name || t('system') })"
            />

            <h3 class="text-md font-semibold" v-text="t('sharedWith')" />

            <div
                v-for="dispatch in statement.dispatches"
                :key="dispatch.id"
                v-html="
                    t('statementDispatch', {
                        emails: `<b class='font-semibold'>${dispatch.emails}</b>`,
                        user: `<b class='font-semibold'>${dispatch.user?.name ?? t('system')}</b>`,
                        date: datetime(dispatch.createdAt),
                    })
                "
            />

            <span v-if="statement.dispatches.length === 0" v-text="t('statementNotShared')" />
        </div>
    </div>

    <NotFoundComponent v-if="!fetching && !statement" />
</template>
